import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import NewsBox from "./news";
import { useContainerContext } from "../../contexts/container";
import { collection, getDocs } from "firebase/firestore";
import db from "../../firebase-config";

export default function LatestNews() {
	const { setTitle } = useContainerContext();
	const [latestNews, setLatestNews] = useState([]);

	const navigate = useNavigate();
	const location = useLocation();

	const getLatestNews = async () => {
		const ref = collection(db, "news");
		await getDocs(ref)
			.then((res) => {
				if (res.empty) setLatestNews([]);
				else setLatestNews(res.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
			})
			.catch(() => setLatestNews([]));
	};

	useEffect(() => {
		setTitle("Latest News");
		getLatestNews();
	}, []);

	// useEffect(() => {
	// 	const params = new URL(window.location.href).searchParams;
	// 	const page = params.get("page") ?? 0;
	// }, [location]);

	return (
		<motion.div
			initial={{ opacity: 0, y: 6 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.3 }}
		>
			<div className="w-full px-2 duration-300">
				<div className="w-full md:w-11/12 xl:w-10/12 mx-auto py-8 space-y-8 duration-300">
					{latestNews.length > 0 ? (
						<div className="grid gap-10 duration-300">
							<div className="flex items-center justify-between gap-10">
								<div className="flex items-center gap-4">
									<div
										role="link"
										onClick={() => navigate(-1)}
										className="hover:scale-110 duration-200 text-xl cursor-pointer"
									>
										<Icon icon="solar:arrow-left-line-duotone" />
									</div>

									<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-3xl duration-300">
										Latest news
									</h1>
								</div>
							</div>

							{/* Grids | News | Contents */}
							<div className="w-full grid grid-cols-1 xsm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-10 duration-300">
								{latestNews.map((news, index) => (
									<NewsBox
										key={index}
										news={news}
										prefix={location.pathname}
										delay={0.1 * index}
									/>
								))}
							</div>
						</div>
					) : (
						<div className="rounded-sm bg-red-50 flex items-center justify-center"></div>
					)}
				</div>
			</div>
		</motion.div>
	);
}
