import { motion } from "framer-motion";
import { propertyStatus, propertyTypes } from "..";
import React from "react";

function FormInput({ ...rest }) {
	return (
		<input
			{...rest}
			className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
		/>
	);
}

export function TypeSelector({ selected, setParams }) {
	return (
		<div className="flex h-11 rounded-full border p-1 tracking-tighter *:duration-300 font-medium *:h-full *:rounded-full *:cursor-pointer *:flex *:items-center *:justify-center text-sm">
			<div
				className={selected === 0 ? "bg-orange-600 text-white px-8" : "px-4 hover:text-orange-600"}
				onClick={() => {
					setParams({
						type: "sale",
					});
				}}
				title="Add property for sale"
			>
				<span>Sale</span>
			</div>

			<div
				className={selected === 1 ? "bg-orange-600 text-white px-8" : "px-4 hover:text-orange-600"}
				onClick={() => {
					setParams({
						type: "rent",
					});
				}}
				title="Add property for rent"
			>
				<span>Rent</span>
			</div>

			<div
				className={selected === 2 ? "bg-orange-600 text-white px-8" : "px-4 hover:text-orange-600"}
				onClick={() => {
					setParams({
						type: "land",
					});
				}}
				title="Add land for sale"
			>
				<span>Land</span>
			</div>
		</div>
	);
}

export function Title({ title = null, setProperty }) {
	return (
		<div className="w-full grid gap-2 justify-items-start !pt-0">
			<motion.label
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3 }}
				htmlFor="title"
				className="font-medium text-sm"
			>
				<span>Title</span>
			</motion.label>

			<motion.div
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3 }}
				className="w-full h-11"
			>
				<FormInput
					type="text"
					id="title"
					required
					autoComplete="off"
					placeholder="Add property title"
					defaultValue={title ?? ""}
					onKeyUp={(e) => {
						const val = e.currentTarget.value;
						setProperty((p) => ({
							...p,
							title: val.length > 0 ? val : "",
						}));
					}}
				/>
			</motion.div>
		</div>
	);
}

export function SubTitle({ title = null, setProperty }) {
	return (
		<div className="w-full grid gap-2 justify-items-start">
			<motion.label
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3 }}
				htmlFor="subTitle"
				className="font-medium text-sm"
			>
				<span>Sub Title or Short Description</span>
			</motion.label>

			<motion.div
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3 }}
				className="w-full h-11"
			>
				<FormInput
					type="text"
					id="subTitle"
					autoComplete="off"
					defaultValue={title ?? ""}
					placeholder="Property sub title or describe in short"
					onKeyUp={(e) => {
						const val = e.currentTarget.value;
						setProperty((p) => ({
							...p,
							subTitle: val.length > 0 ? val : "",
						}));
					}}
				/>
			</motion.div>
		</div>
	);
}

const statusTypes = ["ACTIVE", "SOLD", "DELETED", "OFF_MARKET", "UNDER_OFFER"];
export function Status({ status = "ACTIVE", setProperty }) {
	return (
		<div className="place-self-start grid gap-2 justify-items-start">
			<motion.label
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3 }}
				htmlFor="status"
				className="font-medium text-sm"
			>
				<span>Status</span>
			</motion.label>

			<motion.div
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3 }}
				className="w-full h-11"
			>
				<select
					id="status"
					required
					className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
					defaultValue={status ?? ""}
					onChange={(e) => {
						const val = e.currentTarget.value;
						setProperty((p) => ({
							...p,
							status: val,
						}));
					}}
				>
					<option value="" key={"-1"} disabled>
						Choose status
					</option>
					{statusTypes.map((s, i) => {
						let option = s.replace("_", " ").toLowerCase();
						option = option.charAt(0).toUpperCase() + option.slice(1, option.length);
						return (
							<option value={s} key={i}>
								{option}
							</option>
						);
					})}
				</select>
			</motion.div>
		</div>
	);
}

export function Baths({ tubs = 0, setProperty }) {
	return (
		<div className="w-full grid gap-2 justify-items-start">
			<motion.label
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3, ease: "easeInOut" }}
				htmlFor="baths"
				className="font-medium text-sm"
			>
				<span>Baths</span>
			</motion.label>

			<motion.div
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3, ease: "easeOut" }}
				className="w-full h-11"
			>
				<FormInput
					type="number"
					id="baths"
					min={0}
					max={10000}
					autoComplete="off"
					required
					placeholder="Total bathrooms"
					defaultValue={tubs > 0 ? tubs : ""}
					onKeyUp={(e) => {
						const val = e.currentTarget.valueAsNumber;
						setProperty((p) => ({
							...p,
							tubs: val ?? 0,
						}));
					}}
				/>
			</motion.div>
		</div>
	);
}

export function Parkings({ parkings = 0, setProperty }) {
	return (
		<div className="w-full grid gap-2 justify-items-start">
			<motion.label
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3, ease: "easeInOut" }}
				htmlFor="cars"
				className="font-medium text-sm"
			>
				<span>Car Parkings</span>
			</motion.label>

			<motion.div
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3, ease: "easeOut" }}
				className="w-full h-11"
			>
				<FormInput
					type="number"
					id="cars"
					min={0}
					max={10000}
					autoComplete="off"
					required
					placeholder="Total number of car parking"
					defaultValue={parkings > 0 ? parkings : ""}
					onKeyUp={(e) => {
						const val = e.currentTarget.valueAsNumber;
						setProperty((p) => ({
							...p,
							parkings: val ?? 0,
						}));
					}}
				/>
			</motion.div>
		</div>
	);
}

export function Beds({ beds = 0, setProperty }) {
	return (
		<div className="w-full grid gap-2 justify-items-start">
			<motion.label
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3, ease: "easeInOut" }}
				htmlFor="beds"
				className="font-medium text-sm"
			>
				<span>Bed Rooms</span>
			</motion.label>

			<motion.div
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3, ease: "easeOut" }}
				className="w-full h-11"
			>
				<FormInput
					type="number"
					id="beds"
					min={0}
					max={10000}
					autoComplete="off"
					required
					placeholder="Total bedrooms"
					defaultValue={beds > 0 ? beds : ""}
					onKeyUp={(e) => {
						const val = e.currentTarget.valueAsNumber;
						setProperty((p) => ({
							...p,
							beds: val ?? 0,
						}));
					}}
				/>
			</motion.div>
		</div>
	);
}

export function PropertyType({ type = null, setProperty }) {
	return (
		<div className="w-full grid gap-2 justify-items-start">
			<motion.label
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3, ease: "easeInOut" }}
				htmlFor="ptype"
				className="font-medium text-sm"
			>
				<span>Property Type</span>
			</motion.label>

			<motion.div
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3, ease: "easeInOut" }}
				className="w-full h-11"
			>
				<select
					id="ptype"
					required
					autoComplete="off"
					className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
					defaultValue={type ?? ""}
					onChange={(e) => {
						const val = e.currentTarget.value;
						setProperty((p) => ({
							...p,
							ptype: val,
						}));
					}}
				>
					<option value="" key={"-1"} disabled>
						Select property type
					</option>
					{propertyTypes.map((t, i) => (
						<option value={t} key={i}>
							{t}
						</option>
					))}
				</select>
			</motion.div>
		</div>
	);
}

export function PropertyStatus({ status = null, setProperty }) {
	return (
		<div className="w-full grid gap-2 justify-items-start">
			<motion.label
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3, ease: "easeInOut" }}
				htmlFor="pstatus"
				className="font-medium text-sm"
			>
				<span>Property Status</span>
			</motion.label>

			<motion.div
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3, ease: "easeInOut" }}
				className="w-full h-11"
			>
				<select
					id="pstatus"
					required
					autoComplete="off"
					className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
					defaultValue={status ?? ""}
					onChange={(e) => {
						const val = e.currentTarget.value;
						setProperty((p) => ({
							...p,
							pstatus: val,
						}));
					}}
				>
					<option value="" key={"-1"} disabled>
						Select property status
					</option>
					{propertyStatus.map((s, i) => (
						<option value={s} key={i}>
							{s}
						</option>
					))}
				</select>
			</motion.div>
		</div>
	);
}

export function Area({ area = null, setProperty }) {
	return (
		<div className="w-full grid gap-2 justify-items-start">
			<motion.label
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3 }}
				htmlFor="area"
				className="font-medium text-sm"
			>
				<span>Area of Property</span>
			</motion.label>

			<motion.div
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3 }}
				className="w-full h-11"
			>
				<FormInput
					type="text"
					id="area"
					required
					autoComplete="off"
					defaultValue={area ?? ""}
					placeholder="Total area of the property in square feet or meters"
					onKeyUp={(e) => {
						const val = e.currentTarget.value;
						setProperty((p) => ({
							...p,
							area: val,
						}));
					}}
				/>
			</motion.div>
		</div>
	);
}

export function RentalPerWeek({ rental = null, setProperty }) {
	return (
		<div className="w-full grid gap-2 justify-items-start">
			<motion.label
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3, ease: "easeInOut" }}
				htmlFor="rental_per_week"
				className="font-medium text-sm"
			>
				<span>Rental Per Week</span>
			</motion.label>

			<motion.div
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3, ease: "easeInOut" }}
				className="w-full h-11"
			>
				<FormInput
					type="text"
					id="rental_per_week"
					required
					autoComplete="off"
					placeholder="Rental per week"
					defaultValue={rental ?? ""}
					onKeyUp={(e) => {
						const val = e.currentTarget.value;
						setProperty((p) => ({
							...p,
							rental_per_week: val,
						}));
					}}
				/>
			</motion.div>
		</div>
	);
}

export function SecurityBond({ bond = null, setProperty }) {
	return (
		<div className="w-full grid gap-2 justify-items-start">
			<motion.label
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3, ease: "easeInOut" }}
				htmlFor="security_bond"
				className="font-medium text-sm"
			>
				<span>Security Bond</span>
			</motion.label>

			<motion.div
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3, ease: "easeInOut" }}
				className="w-full h-11"
			>
				<FormInput
					type="text"
					id="security_bond"
					required
					autoComplete="off"
					placeholder="Security bond"
					defaultValue={bond ?? ""}
					onKeyUp={(e) => {
						const val = e.currentTarget.value;
						setProperty((p) => ({
							...p,
							security_bond: val,
						}));
					}}
				/>
			</motion.div>
		</div>
	);
}

export const Description = React.forwardRef((props, ref) => (
	<div className="w-full grid gap-2 justify-items-start">
		<motion.label
			initial={{ opacity: 0, y: -20 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.3 }}
			htmlFor="desc"
			className="font-medium text-sm"
		>
			<span>Description</span>
		</motion.label>

		<motion.div
			initial={{ opacity: 0, y: -20 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.3 }}
			className="w-full h-auto overflow-auto"
		>
			<div
				id="desc"
				ref={ref}
				contentEditable
				className="w-full h-full resize-y text-sm font-medium rounded-sm tracking-tighter outline-none py-2 px-4 border-2 focus-within:border-orange-500 focus:border-orange-500 bg-white placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
				dangerouslySetInnerHTML={{ __html: props.desc ?? "" }}
			></div>
		</motion.div>
	</div>
));

export function Price({ price = null, type = 1, setProperty }) {
	return (
		<div className="w-full grid gap-2 justify-items-start">
			<motion.label
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3, ease: "easeInOut" }}
				htmlFor="price"
				className="font-medium text-sm"
			>
				<span>Price</span>
			</motion.label>

			<motion.div
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3, ease: "easeOut" }}
				className="w-full h-11"
			>
				<FormInput
					type="text"
					id="price"
					autoComplete="off"
					required
					defaultValue={price ?? ""}
					placeholder={type === 0 ? "Sale price" : type === 1 ? "Rental Price" : "Price for a land"}
					onKeyUp={(e) => {
						const val = e.currentTarget.value;
						setProperty((p) => ({
							...p,
							price: val ?? "",
						}));
					}}
				/>
			</motion.div>
		</div>
	);
}
