import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { screens } from "../../../../utils";
import { useContainerContext } from "../../../../contexts/container";
import Review from "./review";
import { Icon } from "@iconify/react";
import { collection, getDoc, getDocs, limit, orderBy, query } from "firebase/firestore";
import db from "../../../../firebase-config";

export default function OurAgentReviews() {
	const { width } = useContainerContext();
	const [swiperKey, setSwiperKey] = useState(0);

	const [loaded, setLoaded] = useState(false);

	const swiperRef = useRef();
	const prevBtnRef = useRef();
	const nextBtnRef = useRef();

	const [reviews, setReviews] = useState([]);

	const getReviews = async () => {
		const ref = collection(db, "reviews");
		const q = query(ref, orderBy("createdAt", "desc"), limit(10));
		const res = await getDocs(q);

		if (res.empty) {
			setReviews([]);
		} else {
			res.docs.map(async (d) => {
				const data = d.data();
				const agent = { ...d.data(), id: d.id };
				await getDoc(data.agent).then((res) => {
					if (res.exists()) {
						setReviews((p) => [...p, { ...agent, agent: { ...res.data() } }]);
					} else {
						setReviews((p) => [...p, { ...agent, agent: {} }]);
					}
				});
			});
		}
	};

	useEffect(() => {
		if (loaded) {
			getReviews();
		} else return;
	}, [loaded]);

	useEffect(() => {
		setSwiperKey(1);
		setLoaded(true);

		return () => {
			setReviews([]);
			setSwiperKey(0);
			setLoaded(false);
		};
	}, []);

	return reviews.length > 0 ? (
		<div className="w-full px-2 py-6 xs:py-10 lxs:py-14 md:py-20 duration-300" id="agentReviews">
			<div className="w-full md:w-11/12 xl:w-10/12 mx-auto space-y-6 xs:space-y-10 lxs:space-y-14 md:space-y-20 grid duration-300">
				<div className="text-center">
					<h1 className="text-2xl sm:text-3xl mlg:text-4xl font-extrabold uppercase duration-300">
						Our Agent Reviews
					</h1>
				</div>

				{/* Swiper | Reviews | Contents */}
				<div className="relative w-full flex items-center gap-4 overflow-hidden">
					<div
						className="previous-slide-btn w-9 h-9 min-h-9 min-w-9 rounded-full border hover:border-gray-300 bg-slate-50 hover:bg-slate-100 text-gray-600 hover:text-black flex items-center justify-center cursor-pointer duration-300"
						aria-label="Previous"
						role="button"
						ref={prevBtnRef}
					>
						<Icon icon="solar:arrow-left-outline" />
					</div>

					<div className="relative w-full h-auto overflow-hidden">
						<Swiper
							key={swiperKey}
							ref={swiperRef}
							slidesPerView={
								width > screens.xl ? 3 : width > screens.md ? 2 : width > screens.xsm ? 2 : 1
							}
							slidesPerGroup={1}
							spaceBetween={20}
							keyboard={{
								enabled: true,
							}}
							navigation={{
								prevEl: prevBtnRef.current,
								nextEl: nextBtnRef.current,
							}}
							autoplay={{
								delay: 5000,
								disableOnInteraction: false,
							}}
							grabCursor={true}
							modules={[Navigation]}
							className="bg-transparent"
						>
							{reviews.map((review, index) => (
								<SwiperSlide
									key={index}
									className="overflow-hidden bg-transparent rounded hover:bg-white"
								>
									<motion.div
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										transition={{ duration: 0.3, delay: 0.1 * index }}
										className="relative w-full h-auto group/review"
									>
										<Review review={review} />
									</motion.div>
								</SwiperSlide>
							))}
						</Swiper>
					</div>

					<div
						className="next-slide-btn w-9 h-9 min-h-9 min-w-9 rounded-full border hover:border-gray-300 bg-slate-50 hover:bg-slate-100 text-gray-600 hover:text-black flex items-center justify-center cursor-pointer duration-300"
						aria-label="Next"
						role="button"
						ref={nextBtnRef}
					>
						<Icon icon="solar:arrow-right-outline" />
					</div>
				</div>
			</div>
		</div>
	) : null;
}
