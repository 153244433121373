import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDashboardContext } from "../../../dashboardContext";
import { collection, deleteDoc, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { Icon } from "@iconify/react";
import db from "../../../../firebase-config";
import moment from "moment";
import ViewRentalRequest from "./view";
import { deleteRequest } from "./actions";

export default function RentalAppraisals() {
	const { setTitle, setUpdating } = useDashboardContext();
	const [appraisals, setAppraisals] = useState(null);

	const { pathname } = useLocation();
	const collectionRef = collection(db, "rental-appraisals");

	const getAppraisals = async () => {
		try {
			const q = query(collectionRef, orderBy("sentAt", "desc"), limit(100));
			const docsSnap = await getDocs(q);
			if (docsSnap.empty) {
				setAppraisals([]);
			} else {
				setAppraisals(docsSnap.docs.map((doc) => ({ ...doc.data() })));
			}
		} catch (error) {
			setAppraisals({});
		}
	};

	useEffect(() => {
		getAppraisals();
		setTitle("Rental Appraisals");
	}, []);

	const [selectedItems, setSelectedItems] = useState([]);
	const handleCheckboxChange = (event) => {
		const { value, checked } = event.target;
		setSelectedItems((prev) => {
			if (checked) {
				return [...prev, value];
			} else {
				return prev.filter((item) => item !== value);
			}
		});
	};

	const deleteSelected = async () => {
		const confirmed = window.confirm("Are you sure to delete all the selected items?");
		if (confirmed) {
			setUpdating(true);
			try {
				const q = query(collectionRef, where("id", "in", selectedItems));
				await getDocs(q)
					.then(async (res) => {
						await Promise.all(
							res.docs.map((doc) => {
								return deleteDoc(doc.ref);
							})
						);

						setUpdating(false);
						getAppraisals();
						setSelectedItems([]);
					})
					.catch((error) => {
						console.log(error);
						setUpdating(false);
					});
			} catch (error) {
				setUpdating(false);
				console.log(error);
			}
		}
	};

	return appraisals ? (
		<div className="relative h-screen w-full py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 flex items-center flex-col duration-300">
			{/* Absolute button to delete selected */}
			{selectedItems.length > 0 && (
				<div className="absolute bottom-4 z-50">
					<div
						role="button"
						onClick={deleteSelected}
						className="rounded-full flex items-center justify-center gap-2 px-6 h-10 bg-gradient-to-br from-rose-400 to-red-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
					>
						<span>Delete selected</span>
						<Icon icon="solar:trash-bin-minimalistic-bold-duotone" fontSize={16} />
					</div>
				</div>
			)}

			{/* Top App Bar */}
			<div className="w-full flex items-center justify-between gap-10 mb-10">
				<div className="flex flex-col items-start">
					<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
						Rental Appraisals
					</h1>

					{appraisals.length > 0 && (
						<div className="text-sm tracking-tight text-gray-600">
							Total <span className="font-semibold">{appraisals.length}</span> appraisals found.
						</div>
					)}
				</div>
			</div>

			{appraisals.length > 0 ? (
				<div className="w-full h-auto overflow-auto">
					<table className="w-full h-full min-w-max overflow-auto border-collapse">
						<thead className="w-full text-sm tracking-tighter border-b">
							<tr className="text-left *:px-4">
								<th>
									<input
										type="checkbox"
										value={""}
										id={"appraisalSelectAll"}
										onChange={(event) => {
											const { checked } = event.target;
											if (checked) {
												setSelectedItems(
													appraisals.map((a) => {
														return a.id;
													})
												);
											} else {
												setSelectedItems([]);
											}
										}}
										checked={selectedItems.length === appraisals.length}
									/>
								</th>
								<th className="py-2">SN</th>
								<th>Full Name</th>
								<th>Email Address</th>
								<th>Mobile</th>
								<th>Preferred Contact Method</th>
								<th>Property Address</th>
								<th>Total No. of Bed Rooms</th>
								<th>Total No. of Bath Tubs</th>
								<th>Total No. of Parkings</th>
								<th>Property Description</th>
								<th>Appraisal Received At</th>
								<th>Actions</th>
							</tr>
						</thead>

						<tbody>
							{appraisals.map((appraisal, index) => (
								<tr
									key={index}
									className={
										"text-left text-xsm hover:bg-gray-100 even:bg-gray-50 *:px-4 " +
										(appraisal.viewed ? "" : "bg-green-50")
									}
								>
									<td>
										<input
											type="checkbox"
											value={appraisal.id}
											id={"appraisalSelected" + index}
											name={"appraisal" + index}
											onChange={handleCheckboxChange}
											checked={selectedItems.includes(appraisal.id)}
										/>
									</td>

									<td align="middle">
										<div
											className={
												"w-6 h-6 rounded-full flex items-center justify-center " +
												(appraisal.viewed ? "" : "bg-green-500 text-white")
											}
										>
											<span className="font-medium text-sm">{index + 1}</span>
										</div>
									</td>

									<td className="py-4 font-semibold">
										<a href={pathname + "/" + appraisal.id} className="hover:text-blue-600">
											{appraisal.fullname ?? ""}
										</a>
									</td>
									<td className="font-semibold">
										<div className="min-w-max">
											{appraisal.contactBy ? (
												<a
													href={"mailTo:" + (appraisal.email ?? "")}
													className="hover:text-blue-600"
												>
													{appraisal.email ?? ""}
												</a>
											) : (
												appraisal.email ?? ""
											)}
										</div>
									</td>
									<td className="font-medium">
										<div className="min-w-max">
											{appraisal.contactBy ? (
												appraisal.mobile ?? ""
											) : (
												<a
													href={"tel:" + (appraisal.mobile ?? "")}
													className="hover:text-green-600"
												>
													{appraisal.mobile ?? ""}
												</a>
											)}
										</div>
									</td>
									<td className="font-semibold">
										{appraisal.contactBy ? (
											<span className="text-blue-600">Email</span>
										) : (
											<span className="text-green-600">Mobile</span>
										)}
									</td>
									<td className="font-semibold">
										<div className="line-clamp-1 text-ellipsis max-w-72">
											{appraisal.address ?? ""}
										</div>
									</td>
									<td className="font-semibold text-gray-700">{appraisal.beds ?? 0}</td>
									<td className="font-semibold text-gray-700">{appraisal.baths ?? 0}</td>
									<td className="font-semibold text-gray-700">{appraisal.parkings ?? 0}</td>
									<td>
										<div className="line-clamp-1 text-ellipsis max-w-96 font-medium">
											{appraisal.desc ?? ""}
										</div>
									</td>
									<td>
										<div className="min-w-max font-medium">
											{moment(appraisal.sentAt.seconds * 1000).format("MMM DD, hh:mm a")}
										</div>
									</td>
									<td>
										<div className="flex items-center gap-4 text-gray-800 *:duration-300 *:text-2xl">
											<div
												role="button"
												onClick={() => deleteRequest(appraisal.id)}
												className="hover:text-red-600 cursor-pointer"
												title="Delete appraisal"
											>
												<Icon icon="solar:trash-bin-minimalistic-bold-duotone" />
											</div>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : (
				<div className="w-full flex-grow p-20 rounded-xl bg-red-50 flex items-center justify-center">
					<div className="grid text-center justify-items-center gap-4">
						<div className="text-8xl text-red-500">
							<Icon icon="solar:users-group-rounded-bold-duotone" />
						</div>

						<div className="font-medium text-lg tracking-tighter">
							<span>Couldn't found any appraisals.</span>
						</div>
					</div>
				</div>
			)}
		</div>
	) : (
		<div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
			<div className="grid text-center justify-items-center gap-4">
				<div className="text-6xl text-indigo-500 animate-spin">
					<Icon icon="solar:black-hole-line-duotone" />
				</div>

				<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
					Loading appraisals...
				</h1>
			</div>
		</div>
	);
}

export { ViewRentalRequest };
