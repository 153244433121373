import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import { useLocation } from "react-router-dom";
import { useDashboardContext } from "../../dashboardContext";
import { doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import db from "../../../firebase-config";

export default function TermsAndConditions() {
	const { pathname } = useLocation();
	const { setTitle, setUpdating } = useDashboardContext();

	const [editingMode, setEditingMode] = useState(false);

	const [content, setContent] = useState(null);

	const ref = doc(db, "contents", "terms-and-conditions");

	const getContent = async () => {
		try {
			const docSnap = await getDoc(ref);
			if (docSnap.exists()) {
				setContent(docSnap.data());
			} else {
				setContent({});
			}
		} catch (error) {
			setContent({});
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setUpdating(true);

		await getEditorData()
			.then(async (res) => {
				await setDoc(ref, { content: res, updatedAt: Timestamp.now() }, { merge: true })
					.then(() => {
						setUpdating(false);
					})
					.catch((error) => {
						console.error(error);
						setUpdating(false);
					});
			})
			.catch((error) => {
				console.error(error);
				setUpdating(false);
			});
	};

	const editorRef = useRef(null);
	const loadScript = (url, callback) => {
		const existingScript = document.getElementById("ckeditor-script");
		if (!existingScript) {
			const script = document.createElement("script");
			script.src = url;
			script.id = "ckeditor-script";
			document.body.appendChild(script);
			script.onload = () => {
				if (callback) callback();
			};
		} else if (existingScript && callback) {
			callback();
		}
	};

	useEffect(() => {
		setTitle("Terms and Conditions");
		getContent();

		return () => {
			if (window.CKEDITOR) {
				for (let instance in window.CKEDITOR.instances) {
					window.CKEDITOR.instances[instance].destroy(true);
				}
				const script = document.getElementById("ckeditor-script");
				if (script) {
					document.body.removeChild(script);
				}
			}
		};
	}, []);

	useEffect(() => {
		if (content) {
			loadScript("/lib/ckeditor/ckeditor.js", initCKEditor);
		}
	}, [content]);

	const initCKEditor = () => {
		if (editorRef.current && window.CKEDITOR) {
			window.CKEDITOR.replace(editorRef.current, { height: 400 });
		}
	};

	const getEditorData = () => {
		return new Promise((resolve, reject) => {
			try {
				const content = window.CKEDITOR.instances["content"].getData();
				resolve(content);
			} catch (error) {
				reject(error);
			}
		});
	};

	return (
		<div className="py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 space-y-10 duration-300">
			{/* Top App Bar */}
			<div className="flex items-center justify-between gap-10">
				<div className="flex flex-col items-start">
					<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
						Manage Terms and Conditions Contents
					</h1>

					<div className="text-sm tracking-tight text-gray-600">
						Enable edit mode to start editing contents
					</div>
				</div>

				<div
					className={
						"relative w-8 h-5 cursor-pointer rounded-full duration-300 flex items-center " +
						(editingMode ? "bg-blue-600" : "bg-gray-800")
					}
					onClick={() => setEditingMode(!editingMode)}
					title={editingMode ? "Disable editing mode" : "Enable to start editing"}
				>
					<div
						className={
							"absolute w-4 h-4 rounded-full bg-white duration-300 " +
							(editingMode ? "left-[45%]" : "left-[5%]")
						}
					></div>
				</div>
			</div>

			{/* Form */}
			<form
				action={pathname ?? "#"}
				method="POST"
				className="grid gap-12 tracking-tighter"
				onSubmit={handleSubmit}
			>
				<div className="w-full grid gap-2 justify-items-start">
					<motion.label
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						htmlFor="content"
						className="font-medium text-sm"
					>
						<span>Contents</span>
					</motion.label>

					<motion.div
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						className="w-full h-auto"
					>
						<div
							id="content"
							ref={editorRef}
							contentEditable
							className="w-full h-full resize-y text-sm font-medium rounded-sm tracking-tighter outline-none py-2 px-4 border-2 focus-within:border-orange-500 focus:border-orange-500 bg-white placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
							dangerouslySetInnerHTML={{ __html: content?.content ?? "" }}
							aria-placeholder="Write terms and conditions..."
						></div>
					</motion.div>
				</div>

				<div className="place-self-start flex items-center gap-6 flex-wrap">
					{editingMode ? (
						<button
							type="submit"
							className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-orange-400 to-orange-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
						>
							<span>Update Content</span>
							<Icon icon="solar:round-arrow-right-up-bold" fontSize={16} />
						</button>
					) : null}
				</div>
			</form>
		</div>
	);
}
