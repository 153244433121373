import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import { useDashboardContext } from "../../dashboardContext";
import { setDoc, doc, Timestamp, getDoc, updateDoc } from "firebase/firestore";
import db from "../../../firebase-config";
import { hashString } from "../../libs";
import { useAuth } from "../../hooks/AuthProvider";

const REDIRECT = "/admin-dashboard/users";

const FormInput = ({ ...rest }) => {
	return (
		<input
			{...rest}
			className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
		/>
	);
};

export default function EditAuthUser() {
	const { user, setTitle, setUpdating } = useDashboardContext();
	const { logOut } = useAuth();

	const { id } = useParams();

	const [loaded, setLoaded] = useState(false);
	const [oldEmail, setOldEmail] = useState(null);
	const [data, setData] = useState({});

	const navigate = useNavigate();
	const { pathname } = useLocation();

	const handleSubmit = async (event) => {
		event.preventDefault();
		setUpdating(true);

		try {
			const authenticatedUser = user.email === oldEmail;
			const ref = doc(db, "users", data.email);
			await hashString(data.password)
				.then(async (pwd) => {
					await updateDoc(ref, { ...data, password: pwd, updatedAt: Timestamp.now() })
						.then(() => {
							setUpdating(false);
							if (authenticatedUser) {
								logOut();
							} else {
								window.location.href = REDIRECT;
							}
						})
						.catch(() => {
							throw new Error("Failed to update user");
						});
				})
				.catch(() => {
					throw new Error("Failed");
				});
		} catch (error) {
			setUpdating(false);
		}
	};

	const getData = async () => {
		const ref = doc(db, "users", id);
		await getDoc(ref).then((user) => {
			if (user.exists()) {
				setData({ id: user.id, ...user.data() });
				setOldEmail(user.data().email);
			}
		});
	};

	useEffect(() => {
		if (loaded) {
			setTitle("Update User");
			getData();
		} else return;
	}, [loaded]);

	useEffect(() => {
		setLoaded(true);

		return () => {
			setLoaded(false);
		};
	}, []);

	return (
		<div className="py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 space-y-10 duration-300">
			{/* Top App Bar */}
			<div className="flex items-center justify-between gap-10">
				<div className="flex items-center gap-4">
					<div
						role="link"
						onClick={() => navigate(-1)}
						className="hover:scale-110 duration-200 text-xl cursor-pointer"
					>
						<Icon icon="solar:arrow-left-line-duotone" />
					</div>

					<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
						Update User
					</h1>
				</div>
			</div>

			{/* Bottom Form */}
			<form
				action={pathname ?? "#"}
				method="POST"
				className="grid gap-12 tracking-tighter"
				onSubmit={handleSubmit}
			>
				<div className="w-full grid gap-2 justify-items-start">
					<motion.label
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						htmlFor="username"
						className="font-medium text-sm"
					>
						<span>Username</span>
					</motion.label>

					<motion.div
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						className="w-full h-11"
					>
						<FormInput
							type="text"
							required
							id="username"
							pattern="[a-zA-Z ]+"
							autoComplete="name"
							defaultValue={data?.username ?? ""}
							placeholder="Write your full name"
							onKeyUp={(e) => {
								const val = e.currentTarget.value;
								setData((d) => ({
									...d,
									username: val.length > 0 ? val : "",
								}));
							}}
						/>
					</motion.div>
				</div>

				<div className="w-full grid gap-2 justify-items-start">
					<motion.label
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						htmlFor="email"
						className="font-medium text-sm"
					>
						<span>Email address</span>
					</motion.label>

					<motion.div
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						className="w-full h-11"
					>
						<FormInput
							type="email"
							id="email"
							readOnly
							disabled
							placeholder="Your email address"
							defaultValue={data?.email ?? ""}
						/>
					</motion.div>
				</div>

				<div className="w-full grid gap-2 justify-items-start">
					<motion.label
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						htmlFor="password"
						className="font-medium text-sm"
					>
						<span>Update Your Password</span>
					</motion.label>

					<motion.div
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						className="w-full h-11"
					>
						<FormInput
							type="password"
							required
							id="password"
							minLength={8}
							maxLength={50}
							autoComplete="new-password"
							placeholder="Enter new password"
							onKeyUp={(e) => {
								const val = e.currentTarget.value;
								setData((d) => ({
									...d,
									password: val.length > 0 ? val : "",
								}));
							}}
						/>
					</motion.div>
				</div>

				<div className="place-self-end flex items-center gap-6 flex-wrap">
					{/* Go back */}
					<button
						type="button"
						onClick={() => navigate(-1)}
						className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-gray-300 to-gray-400 hover:bg-gradient-to-tr text-xs text-gray-700 font-semibold hover:text-black duration-300"
					>
						<Icon icon="solar:arrow-left-outline" fontSize={16} />
						<span>Go Back</span>
					</button>

					<button
						type="submit"
						className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-orange-400 to-orange-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
					>
						<span>Update User</span>
						<Icon icon="solar:add-square-bold" fontSize={16} />
					</button>
				</div>
			</form>
		</div>
	);
}
