import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import RecentAddedProperties from "./sections/recentAddedProperties";
import { getInsights, getRecentNews, getRecentProperties } from "./utils/db";
import { Loader } from "../common";
import { RecentAddedNews } from "./sections";
import { useDashboardContext } from "../../dashboardContext";

export default function Dashboard() {
	const { user } = useDashboardContext();

	const [properties, setProperties] = useState(null);
	const [news, setNews] = useState(null);

	const [insights, setInsights] = useState({
		properties: 0,
		teams: 0,
		news: 0,
		messages: 0,
	});

	const { pathname } = useLocation();

	const setDatas = async () => {
		const __properties = await getRecentProperties();
		const __news = await getRecentNews();
		const __insights = await getInsights();

		setProperties(__properties);
		setNews(__news);
		setInsights(__insights);
	};

	useEffect(() => {
		setDatas();
	}, []);

	return (
		<div className="w-full h-full overflow-auto">
			<div className="py-2 xsm:py-6 px-6 sm:px-8 md:px-12 lg:px-16 space-y-10 duration-300">
				<div className="flex items-center justify-between gap-10">
					<div className="inline-flex items-center gap-2 font-semibold tracking-tighter text-2xl duration-300">
						<h1>Welcome 👋, </h1>
						<h1 className="text-orange-600">{user.username ?? ""}</h1>
					</div>
				</div>

				{/* Recent Added Properties */}
				{properties ? (
					properties.length > 0 ? (
						<RecentAddedProperties
							properties={properties}
							path={pathname + "/all-properties"}
						/>
					) : null
				) : (
					<Loader />
				)}

				{/* Recent Added News */}
				{news ? (
					news.length > 0 ? (
						<RecentAddedNews recentNews={news} path={pathname + "/all-news"} />
					) : null
				) : (
					<Loader />
				)}

				<div className="grid gap-4">
					<div className="flex items-center justify-between gap-10">
						<div className="text-lg tracking-tighter font-semibold">
							<span>Insights</span>
						</div>
					</div>

					<div className="flex flex-wrap gap-10 tracking-tighter duration-300">
						<a href={pathname + "/all-properties"}>
							<div className="rounded-3xl px-14 py-6 bg-gray-100 hover:bg-orange-500 flex flex-col items-start gap-4 duration-300">
								<div className="text-base font-semibold">
									<span>Properties</span>
								</div>

								<div className="text-4xl font-bold">
									<span>{insights.properties}</span>
								</div>
							</div>
						</a>

						<a href={pathname + "/our-teams"}>
							<div className="rounded-3xl px-14 p-6 bg-gray-100 hover:bg-orange-500 flex flex-col items-start gap-4 duration-300">
								<div className="text-base font-semibold">
									<span>Total Teams</span>
								</div>

								<div className="text-4xl font-bold">
									<span>{insights.teams}</span>
								</div>
							</div>
						</a>

						<a href={pathname + "/all-news"}>
							<div className="rounded-3xl px-14 p-6 bg-gray-100 hover:bg-orange-500 flex flex-col items-start gap-4 duration-300">
								<div className="text-base font-semibold">
									<span>All News</span>
								</div>

								<div className="text-4xl font-bold">
									<span>{insights.news}</span>
								</div>
							</div>
						</a>

						<a href={pathname + "/messages"}>
							<div className="rounded-3xl px-14 p-6 bg-gray-100 hover:bg-orange-500 flex flex-col items-start gap-4 duration-300">
								<div className="text-base font-semibold">
									<span>Messages</span>
								</div>

								<div className="text-4xl font-bold">
									<span>{insights.messages}</span>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
