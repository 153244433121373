import React, { Fragment, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
	AboutUs,
	LatestNews,
	Featured,
	FinancialCalculator,
	GetInTouch,
	MeetOurTeam,
	OurAgentReviews,
	WhatWeDo,
} from "./sections";
import { CalculatePropertyWorth } from "./utils";

export default function HomePage() {
	const bannerContainer = useRef(null);
	const videoRef = useRef(null);

	const location = useLocation();

	useEffect(() => {
		if (location.hash) {
			const element = document.getElementById(location.hash.slice(1));
			if (element) {
				setTimeout(() => {
					element.scrollIntoView({ behavior: "smooth" });
				}, 300);
			}
		} else {
			window.scrollTo(0, 0);
		}
	}, [location]);

	return (
		<Fragment>
			<div className="grid">
				<div ref={bannerContainer} className="relative w-full max-h-full h-[80vh] duration-200 overflow-hidden">
					<video
						loop
						muted
						autoPlay
						ref={videoRef}
						src={"/static/videos/loop-realestate-video.mp4"}
						className="w-full h-full object-cover"
					/>

					<div className="absolute z-50 inset-0 flex items-center justify-center">
						<div className="absolute z-10 inset-0 bg-black/40 pointer-events-none"></div>

						<div className="relative z-20 grid gap-4 justify-items-center">
							<CalculatePropertyWorth />
						</div>
					</div>
				</div>

				<WhatWeDo />

				<Featured />

				<LatestNews />

				<FinancialCalculator />

				<MeetOurTeam />

				<AboutUs />

				<OurAgentReviews />

				<GetInTouch />
			</div>
		</Fragment>
	);
}
