import React, { createContext, useEffect, useState } from "react";
import { useContext } from "react";
import { getCookie, getLocalStorage } from "./libs";
import { useAuth } from "./hooks/AuthProvider";
import { collection, getDocs, query, where } from "firebase/firestore";
import db from "../firebase-config";

export const DashboardContext = createContext();

export default function DashboardContextProvider({ children }) {
	const { logOut } = useAuth();
	const [updating, setUpdating] = useState(false);
	const [title, setTitle] = useState("Dashboard");

	// In next update we will implement custom confirm box.
	// const [showConfirmBox, setShowConfirmBox] = useState(false);
	// const [confirmBox, setConfirmBox] = useState({
	// 	title: "",
	// 	message: "",
	// });

	const [user, setUser] = useState(null);

	const getAuthUser = () => {
		getCookie("token").then((res) => {
			if (res && res !== "") {
				getLocalStorage("authUser")
					.then(async (email) => {
						const ref = collection(db, "users");
						const q = query(ref, where("email", "==", email));
						const querySnapshot = await getDocs(q);
						if (querySnapshot.empty) {
							logOut();
						} else {
							setUser(querySnapshot.docs[0].data());
						}
					})
					.catch(() => logOut);
			} else {
				logOut();
			}
		});
	};

	useEffect(() => {
		getAuthUser();
	}, []);

	return (
		<DashboardContext.Provider value={{ user, updating, setUpdating, title, setTitle }}>
			{user ? children : null}
		</DashboardContext.Provider>
	);
}

export const useDashboardContext = () => {
	return useContext(DashboardContext);
};
