import React from "react";
import { IoStar } from "react-icons/io5";

export default function Review({ review }) {
	return (
		<div className="relative w-full h-auto group/review">
			<div className="w-full h-auto rounded border hover:border-gray-300 overflow-hidden bg-white hover:bg-indigo-50/20 duration-300">
				<div className="relative w-full aspect-[2/1] flex items-center">
					<div className="w-full h-full border-b overflow-hidden">
						<img
							src={review.image.url ?? "/static/images/placeholder.png"}
							alt={"Property"}
							className="w-full aspect-[2/1] object-cover transition-all group-hover/review:scale-105 duration-300"
						/>
					</div>

					<div className="absolute z-10 left-4 bottom-0 translate-y-1/2 w-14 h-14 flex items-center justify-center rounded-full overflow-hidden border bg-gray-50">
						<img
							src={review?.agent?.image ?? "/static/images/default.png"}
							alt={review.username ?? "agent"}
							className="w-full full object-cover"
						/>
					</div>
				</div>

				{/* Content */}
				<div className="p-4 grid gap-5 justify-items-start">
					<div className="flex gap-4">
						<div className="w-14"></div>

						<div className="flex-grow grid justify-items-start text-left">
							<div className="text-lg font-bold line-clamp-3 text-ellipsis tracking-tight text-pretty group-hover/review:text-indigo-600 duration-200">
								{review.username ?? "Unknown"}
							</div>

							<div className="mt-2 flex items-center gap-2 text-sm text-indigo-600">
								{Array.from({ length: review.stars }, () => Array("1").fill("1")).map((_, i) => (
									<IoStar key={i} />
								))}
							</div>
						</div>
					</div>

					<div className="line-clamp-3 text-ellipsis text-pretty text-left tracking-tight text-sm text-gray-700">
						{review.desc ?? ""}
					</div>
				</div>
			</div>
		</div>
	);
}
