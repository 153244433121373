import React, { Fragment, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import { useContainerContext } from "../contexts/container";
import { doc, getDoc } from "firebase/firestore";
import db from "../firebase-config";

export default function Header({ hidden = false }) {
	const headerRef = useRef(null);

	const { setHeaderHeight } = useContainerContext();
	const [showMenu, setShowMenu] = useState(false);

	const [dropdownMenu, setDropdownMenu] = useState({
		buying: false,
		selling: false,
		renting: false,
		whoAreWe: false,
		contact: false,
	});

	const [links, setLinks] = useState({});
	const getLinks = async () => {
		try {
			const docRef = doc(db, "contents", "links");
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				setLinks({ ...docSnap.data() });
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		getLinks();
		setTimeout(() => {
			setHeaderHeight(document.getElementById("headerBarContainer").offsetHeight);
		}, 100);
	}, []);

	return (
		<Fragment>
			<div className="hidden sm:block">
				<header
					ref={headerRef}
					id="headerBarContainer"
					className="relative z-999 left-0 top-0 w-full bg-white tracking-tight border-b"
				>
					<div className="w-full md:w-11/12 xl:w-10/12 mx-auto duration-300">
						<div className="flex items-center justify-center gap-10 py-4 duration-300">
							<div className="w-24 md:w-32 h-auto duration-300">
								<img
									src={process.env.REACT_APP_APP_LOGO}
									alt="logo"
									className="w-full h-auto object-cover"
								/>
							</div>
						</div>

						{/* Navbar */}
						<div className="w-full flex justify-center">
							<div
								role="list"
								className="relative flex items-center *:duration-300 text-sm smd:text-base font-semibold text-black"
							>
								<a
									href="/"
									className="px-4 py-2 hover:text-white bg-gradient-to-br hover:from-blue-600 hover:to-blue-400 flex items-center gap-1 duration-300"
								>
									<span className="relative">Home</span>
								</a>

								<div className="relative h-auto group/link cursor-default duration-300">
									<div className="px-4 py-2 group-hover/link:text-white bg-gradient-to-br group-hover/link:from-blue-600 group-hover/link:to-blue-400 flex items-center gap-1 duration-300">
										<span className="relative">Buying</span>
									</div>

									<div className="absolute z-20 min-w-fit text-sm bg-white duration-300 ease-linear overflow-hidden invisible opacity-0 group-hover/link:visible group-hover/link:opacity-100">
										<div className="relative min-w-max h-auto grid *:px-4 *:py-2 hover:*:bg-slate-100 hover:*:text-indigo-700 hover:*:font-semibold *:duration-300">
											<a href="/properties-for-sale">
												<span>Properties for sale</span>
											</a>
											<a href="/land-for-sale">
												<span>Land for sale</span>
											</a>
											<a href={links?.mortage ?? ""} target="_blank" rel="noreferrer">
												<span>Mortage calculator</span>
											</a>
											<a href={links?.stamp_duty ?? ""} target="_blank" rel="noreferrer">
												<span>Stamp duty calculator</span>
											</a>
										</div>
									</div>
								</div>

								<div className="relative h-auto group/link cursor-default duration-300">
									<div className="px-4 py-2 group-hover/link:text-white bg-gradient-to-br group-hover/link:from-blue-600 group-hover/link:to-blue-400 flex items-center gap-1 duration-300">
										<span className="relative">Selling</span>
									</div>

									<div className="absolute z-20 min-w-fit text-sm bg-white duration-300 ease-linear overflow-hidden invisible opacity-0 group-hover/link:visible group-hover/link:opacity-100">
										<div className="relative min-w-max h-auto grid *:px-4 *:py-2 hover:*:bg-slate-100 hover:*:text-indigo-700 hover:*:font-semibold *:duration-300">
											<a href="/sold-properties">
												<span>Sold properties</span>
											</a>
											<a href="/sell-property">
												<span>Selling with Krish</span>
											</a>
											<a href={"/testimonials"}>
												<span>Testimonials</span>
											</a>
										</div>
									</div>
								</div>

								<div className="relative h-auto group/link cursor-default duration-300">
									<div className="px-4 py-2 group-hover/link:text-white bg-gradient-to-br group-hover/link:from-blue-600 group-hover/link:to-blue-400 flex items-center gap-1 duration-300">
										<span className="relative">Renting</span>
									</div>

									<div className="absolute z-20 min-w-fit text-sm bg-white duration-300 ease-linear overflow-hidden invisible opacity-0 group-hover/link:visible group-hover/link:opacity-100">
										<div className="relative min-w-max h-auto grid *:px-4 *:py-2 hover:*:bg-slate-100 hover:*:text-indigo-700 hover:*:font-semibold *:duration-300">
											<a href="/properties-for-rent">
												<span>Properties for rent</span>
											</a>
											<a href="/rental-appraisal">
												<span>Rental appraisal</span>
											</a>
										</div>
									</div>
								</div>

								<div className="relative h-auto group/link cursor-default duration-300">
									<a href="/#aboutUs">
										<div className="px-4 py-2 group-hover/link:text-white bg-gradient-to-br group-hover/link:from-blue-600 group-hover/link:to-blue-400 flex items-center gap-1 duration-300">
											<span className="relative">About us</span>
										</div>
									</a>

									<div className="absolute z-20 min-w-fit text-sm bg-white duration-300 ease-linear overflow-hidden invisible opacity-0 group-hover/link:visible group-hover/link:opacity-100">
										<div className="relative min-w-max h-auto grid *:px-4 *:py-2 hover:*:bg-slate-100 hover:*:text-indigo-700 hover:*:font-semibold *:duration-300">
											<a href="/our-team">
												<span>Meet our team</span>
											</a>
											<a href="/#whatWeDo">
												<span>What we do</span>
											</a>
											<a href="/latest-news">
												<span>Latest News</span>
											</a>
										</div>
									</div>
								</div>

								<div className="relative h-auto group/link cursor-default duration-300">
									<a
										href="/contact-us"
										className="px-4 py-2 group-hover/link:text-white bg-gradient-to-br group-hover/link:from-blue-600 group-hover/link:to-blue-400 flex items-center gap-1 duration-300"
									>
										<span className="relative">Careers</span>
									</a>
								</div>

								<div className="relative h-auto group/link cursor-default duration-300">
									<div className="px-4 py-2 group-hover/link:text-white bg-gradient-to-br group-hover/link:from-blue-600 group-hover/link:to-blue-400 flex items-center gap-1 duration-300">
										<span className="relative">Contact</span>
									</div>

									<div className="absolute z-20 min-w-fit text-sm bg-white duration-300 ease-linear overflow-hidden invisible opacity-0 group-hover/link:visible group-hover/link:opacity-100">
										<div className="relative min-w-max h-auto grid *:px-4 *:py-2 hover:*:bg-slate-100 hover:*:text-indigo-700 hover:*:font-semibold *:duration-300">
											<a href="/contact-us">
												<span>Contact us</span>
											</a>
											<a href="/get-a-free-appraisal">
												<span>Get a free appraisal</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>

				{/* Sticky header */}
				<div
					className={
						"fixed z-999 left-0 top-0 w-full bg-white border-b duration-300 tracking-tight " +
						(hidden ? "translate-y-0 opacity-100" : "-translate-y-full opacity-0")
					}
				>
					<div className="w-full md:w-11/12 xl:w-10/12 mx-auto duration-300">
						<div className="w-full flex items-center justify-between gap-10 duration-300">
							<a href={"/"}>
								<div className="w-16 h-auto">
									<img
										src={process.env.REACT_APP_APP_LOGO}
										alt="logo"
										className="w-full h-auto object-cover"
									/>
								</div>
							</a>

							{/* Navbar */}
							<div className="w-full flex justify-center">
								<div
									role="list"
									className="relative flex items-center *:duration-300 text-sm mlg:text-base lg:text-lg font-semibold text-black"
								>
									<a
										href="/"
										className="px-4 py-5 hover:text-blue-600 flex items-center gap-1 duration-300"
									>
										<span className="relative">Home</span>
									</a>

									<div className="relative h-auto group/link cursor-default duration-300">
										<div className="px-4 py-5 group-hover/link:text-blue-600 flex items-center gap-1 duration-300">
											<span className="relative">Buying</span>
										</div>

										<div className="absolute z-20 shadow-xl min-w-fit text-sm bg-white duration-300 ease-linear overflow-hidden invisible opacity-0 group-hover/link:visible group-hover/link:opacity-100">
											<div className="relative min-w-max h-auto grid *:px-4 *:py-2 hover:*:bg-slate-100 hover:*:text-indigo-700 hover:*:font-semibold *:duration-300">
												<a href="/properties-for-sale">
													<span>Properties for sale</span>
												</a>
												<a href="/land-for-sale">
													<span>Land for sale</span>
												</a>
												<a href={links?.mortage ?? ""} target="_blank" rel="noreferrer">
													<span>Mortage calculator</span>
												</a>
												<a href={links?.stamp_duty ?? ""} target="_blank" rel="noreferrer">
													<span>Stamp duty calculator</span>
												</a>
											</div>
										</div>
									</div>

									<div className="relative h-auto group/link cursor-default duration-300">
										<div className="px-4 py-5 group-hover/link:text-blue-600 flex items-center duration-300">
											<span className="relative">Selling</span>
										</div>

										<div className="absolute z-20 shadow-xl min-w-fit text-sm bg-white duration-300 ease-linear overflow-hidden invisible opacity-0 group-hover/link:visible group-hover/link:opacity-100">
											<div className="relative min-w-max h-auto grid *:px-4 *:py-2 hover:*:bg-slate-100 hover:*:text-indigo-700 hover:*:font-semibold *:duration-300">
												<a href="/sold-properties">
													<span>Sold properties</span>
												</a>
												<a href="/sell-property">
													<span>Selling with Krish</span>
												</a>
												<a href={"/testimonials"}>
													<span>Testimonials</span>
												</a>
											</div>
										</div>
									</div>

									<div className="relative h-auto group/link cursor-default duration-300">
										<div className="px-4 py-5 group-hover/link:text-blue-600 flex items-center duration-300">
											<span className="relative">Renting</span>
										</div>

										<div className="absolute z-20 shadow-xl min-w-fit text-sm bg-white duration-300 ease-linear overflow-hidden invisible opacity-0 group-hover/link:visible group-hover/link:opacity-100">
											<div className="relative min-w-max h-auto grid *:px-4 *:py-2 hover:*:bg-slate-100 hover:*:text-indigo-700 hover:*:font-semibold *:duration-300">
												<a href="/properties-for-rent">
													<span>Properties for rent</span>
												</a>
												<a href="/rental-appraisal">
													<span>Rental appraisal</span>
												</a>
											</div>
										</div>
									</div>

									<div className="relative h-auto group/link cursor-default duration-300">
										<a href="/#aboutUs">
											<div className="px-4 py-5 group-hover/link:text-blue-600 flex items-center duration-300">
												<span className="relative">About us</span>
											</div>
										</a>

										<div className="absolute z-20 shadow-xl min-w-fit text-sm bg-white duration-300 ease-linear overflow-hidden invisible opacity-0 group-hover/link:visible group-hover/link:opacity-100">
											<div className="relative min-w-max h-auto grid *:px-4 *:py-2 hover:*:bg-slate-100 hover:*:text-indigo-700 hover:*:font-semibold *:duration-300">
												<a href="/our-team">
													<span>Meet our team</span>
												</a>
												<a href="/#whatWeDo">
													<span>What we do</span>
												</a>
												<a href="/latest-news">
													<span>Latest News</span>
												</a>
											</div>
										</div>
									</div>

									<div className="relative h-auto group/link cursor-default duration-300">
										<a
											href="/contact-us"
											className="px-4 py-5 group-hover/link:text-blue-600 flex items-center duration-300"
										>
											<span className="relative">Careers</span>
										</a>
									</div>

									<div className="relative h-auto group/link cursor-default duration-300">
										<div className="px-4 py-5 group-hover/link:text-blue-600 flex items-center duration-300">
											<span className="relative">Contact</span>
										</div>

										<div className="absolute z-20 shadow-xl min-w-fit text-sm bg-white duration-300 ease-linear overflow-hidden invisible opacity-0 group-hover/link:visible group-hover/link:opacity-100">
											<div className="relative min-w-max h-auto grid *:px-4 *:py-2 hover:*:bg-slate-100 hover:*:text-indigo-700 hover:*:font-semibold *:duration-300">
												<a href="/contact-us">
													<span>Contact us</span>
												</a>
												<a href="/get-a-free-appraisal">
													<span>Get a free appraisal</span>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* For mobile */}
			<div className="sticky z-999 left-0 top-0 w-full border-b bg-white block sm:hidden">
				<div className="w-full h-full py-2 px-4 flex items-center justify-between gap-10 duration-300">
					<a href={"/"}>
						<div className="w-14 h-auto">
							<img
								src={process.env.REACT_APP_APP_LOGO}
								alt="logo"
								className="w-full h-auto object-cover"
							/>
						</div>
					</a>

					<div role="button" className="text-4xl hover:text-orange-600" onClick={() => setShowMenu(true)}>
						<Icon icon="solar:hamburger-menu-line-duotone" />
					</div>
				</div>
			</div>

			{showMenu ? (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.3 }}
					className="fixed w-full h-screen max-h-screen z-9999 bg-black/30 flex items-start justify-start"
				>
					<motion.div
						initial={{ opacity: 0, x: -20 }}
						animate={{ opacity: 1, x: 0 }}
						transition={{ duration: 0.3 }}
						className="relative z-20 min-w-max h-full max-h-full overflow-auto bg-white tracking-tighter"
					>
						<div className="text-2xl font-semibold px-6 py-8">
							<span>Menus</span>
						</div>

						<div className="grid divide-y *:w-full *:px-6 *:h-12 *:flex *:items-center *:justify-between *:gap-10 hover:*:text-blue-600 hover:*:bg-gray-50 *:duration-300 font-semibold text-base">
							<a href="/">
								<span>Home</span>
							</a>
							<div
								role="button"
								onClick={() => setDropdownMenu((d) => ({ ...d, buying: !dropdownMenu.buying }))}
							>
								<span>Buying</span>
								<Icon icon="solar:alt-arrow-down-outline" />
							</div>
							{dropdownMenu.buying ? (
								<Fragment>
									<a href="/properties-for-sale">
										<span>Properties for sale</span>
									</a>
									<a href="/land-for-sale">
										<span>Land for sale</span>
									</a>
									<a href={links?.mortage ?? ""} target="_blank" rel="noreferrer">
										<span>Mortage calculator</span>
									</a>
									<a href={links?.stamp_duty ?? ""} target="_blank" rel="noreferrer">
										<span>Stamp duty calculator</span>
									</a>
								</Fragment>
							) : null}
							<div
								role="button"
								onClick={() =>
									setDropdownMenu((d) => ({
										...d,
										selling: !dropdownMenu.selling,
									}))
								}
							>
								<span>Selling</span>
								<Icon icon="solar:alt-arrow-down-outline" />
							</div>
							{dropdownMenu.selling ? (
								<Fragment>
									<a href="/sold-properties">
										<span>Sold properties</span>
									</a>
									<a href="/sell-property">
										<span>Selling with Krish</span>
									</a>
									<a href="/testimonials">
										<span>Testimonials</span>
									</a>
								</Fragment>
							) : null}
							<div
								role="button"
								onClick={() =>
									setDropdownMenu((d) => ({
										...d,
										renting: !dropdownMenu.renting,
									}))
								}
							>
								<span>Renting</span>
								<Icon icon="solar:alt-arrow-down-outline" />
							</div>
							{dropdownMenu.renting ? (
								<Fragment>
									<a href="/properties-for-rent">
										<span>Properties for rent</span>
									</a>
									<a href="/rental-appraisal">
										<span>Rental appraisal</span>
									</a>
								</Fragment>
							) : null}
							<div
								role="button"
								onClick={() =>
									setDropdownMenu((d) => ({
										...d,
										whoAreWe: !dropdownMenu.whoAreWe,
									}))
								}
							>
								<span>About us</span>
								<Icon icon="solar:alt-arrow-down-outline" />
							</div>
							{dropdownMenu.whoAreWe ? (
								<Fragment>
									<a href="/our-team">
										<span>Meet our team</span>
									</a>
									<a href="/#whatWeDo">
										<span>What we do</span>
									</a>
									<a href="/latest-news">
										<span>Latest news</span>
									</a>
								</Fragment>
							) : null}
							<a href="/">
								<span>Careers</span>
							</a>
							<div
								role="button"
								onClick={() =>
									setDropdownMenu((d) => ({
										...d,
										contact: !dropdownMenu.contact,
									}))
								}
							>
								<span>Contact</span>
								<Icon icon="solar:alt-arrow-down-outline" />
							</div>
							{dropdownMenu.contact ? (
								<Fragment>
									<a href="/contact-us">
										<span>Contact us</span>
									</a>
									<a href="/get-a-free-appraisal">
										<span>Get a free appraisal</span>
									</a>
								</Fragment>
							) : null}
						</div>
					</motion.div>

					<div className="absolute inset-0 z-10" onClick={() => setShowMenu(false)}></div>
				</motion.div>
			) : null}
		</Fragment>
	);
}
