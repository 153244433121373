import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Team from "./team";
import { useContainerContext } from "../../contexts/container";
import { collection, getDocs } from "firebase/firestore";
import db from "../../firebase-config";

export default function MeetOurTeam() {
	const { setTitle } = useContainerContext();

	const [teams, setTeams] = useState([]);

	const getTeams = async () => {
		const ref = collection(db, "teams");
		await getDocs(ref)
			.then((res) => {
				if (res.empty) setTeams([]);
				else setTeams(res.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
			})
			.catch(() => setTeams([]));
	};

	useEffect(() => {
		setTitle("Meet Our Team");
		getTeams();
	}, []);

	return (
		<motion.div
			initial={{ opacity: 0, y: 6 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.3 }}
			className="space-y-16 duration-300"
		>
			<div className="bg-gradient-to-br from-orange-600 to-orange-500 text-white flex items-center justify-center duration-300 p-20">
				<h1 className="font-semibold text-4xl">Meet Our Team</h1>
			</div>

			<div className="w-full px-2">
				<div className="w-full md:w-11/12 xl:w-10/12 mx-auto duration-300">
					{/* Grids | Properties | Contents */}
					<div className="grid grid-cols-1 xsm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8 row-auto">
						{teams.map((team, index) => (
							<Team key={index} team={team} rent={true} delay={0.1 * index} />
						))}
					</div>
				</div>
			</div>
		</motion.div>
	);
}
