import { Icon } from "@iconify/react";
import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import db from "../../../firebase-config";
import { generateRandomToken, setCookie, setLocalStorage, verifyString } from "../../libs";

const REDIRECT = "/admin-dashboard";

export default function AuthLogin() {
	const { pathname } = useLocation();

	const [authError, setAuthError] = useState(null);

	const emailInputRef = useRef();
	const passwordInputRef = useRef();

	const usersRef = collection(db, "users");

	const handleSubmit = async (event) => {
		event.preventDefault();

		setAuthError(null);

		const email = emailInputRef?.current?.value;
		const password = passwordInputRef?.current?.value;

		const token = generateRandomToken(60);
		const q = query(usersRef, where("email", "==", email));
		try {
			const querySnapshot = await getDocs(q);
			if (querySnapshot.empty) {
				setAuthError("User not found");
			} else {
				querySnapshot.forEach((doc) => {
					const d = doc.data();
					verifyString(password, d?.password)
						.then(async (matched) => {
							if (matched) {
								setCookie("token", token);
								await setLocalStorage({ key: "authUser", data: d?.email });
								setTimeout(() => {
									window.location.href = REDIRECT;
								}, 0);
							} else {
								setAuthError("Invalid username or password");
							}
						})
						.catch(() => {
							setAuthError("Authentication failed");
						});
				});
			}
		} catch (error) {
			setAuthError("Authentication failed");
		}
	};

	return (
		<div className="w-screen h-screen p-6 sm:p-10 md:p-14 lg:p-20 bg-gradient-to-br from-orange-600 to-yellow-600 flex items-center justify-center">
			<div className="relative rounded bg-white px-10 pb-10 py-20 grid justify-items-center space-y-10 min-w-96 max-w-2xl shadow-xl duration-300">
				{/* Top Logo */}
				<div className="absolute z-999 top-0 -translate-y-1/2 w-36 h-36 border-2 p-1 bg-white rounded-full overflow-hidden duration-300">
					<div className="w-full h-full flex items-center justify-center bg-orange-50 rounded-full">
						<img
							src={process.env.REACT_APP_APP_LOGO}
							alt={process.env.REACT_APP_APP_NAME ?? "App logo"}
							className="w-24 h-auto object-contain object-center duration-300"
						/>
					</div>
				</div>

				<div className="flex flex-col items-center text-center">
					<div className="font-bold text-3xl tracking-tighter">{process.env.REACT_APP_APP_NAME ?? ""}</div>

					<div className="font-semibold tracking-tighter text-sm text-gray-600">
						Login to continue to dashboard
					</div>
				</div>

				<form action={pathname ?? "#"} method="POST" onSubmit={handleSubmit} className="w-full space-y-10">
					<div className="space-y-6">
						<div className="grid justify-items-start gap-1">
							<div className="relative w-full h-11 flex items-center">
								<input
									type="email"
									id="email"
									required
									ref={emailInputRef}
									autoComplete="email"
									aria-labelledby="Email address field"
									className="w-full h-full text-sm pl-8 font-semibold tracking-tighter outline-none border-0 border-b focus-within:border-orange-600 focus:border-orange-600 bg-white placeholder:text-gray-500 placeholder:font-medium duration-300 ease-linear peer"
									placeholder="Email address"
								/>

								<label
									htmlFor="email"
									className="absolute left-0 z-10 font-medium text-xl peer-focus:text-orange-600 peer-focus-within:text-orange-600 duration-300"
								>
									<Icon icon="solar:letter-opened-bold-duotone" />
								</label>
							</div>

							{authError && <span className="font-medium text-sm text-red-500">{authError}</span>}
						</div>

						<div className="justify-items-start">
							<div className="relative w-full h-11 flex items-center">
								<input
									type="password"
									id="password"
									required
									aria-labelledby="Password field"
									ref={passwordInputRef}
									autoComplete="off"
									className="w-full h-full text-sm pl-8 font-semibold tracking-tighter outline-none border-0 border-b focus-within:border-orange-600 focus:border-orange-600 bg-white placeholder:text-gray-500 placeholder:font-medium duration-300 ease-linear peer"
									placeholder="Password"
								/>

								<label
									htmlFor="password"
									className="absolute left-0 z-10 font-medium text-xl peer-focus:text-orange-600 peer-focus-within:text-orange-600 duration-300"
								>
									<Icon icon="solar:lock-password-unlocked-bold-duotone" />
								</label>
							</div>
						</div>
					</div>

					<button
						type="submit"
						className="rounded-sm w-full outline-none border-none flex items-center justify-center gap-2 px-10 h-11 bg-gradient-to-br from-orange-400 to-orange-600 hover:bg-gradient-to-tr text-xs font-medium text-white duration-300"
					>
						<span>Log In</span>
						<Icon icon="solar:arrow-right-outline" fontSize={16} />
					</button>
				</form>
			</div>
		</div>
	);
}
