import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import { useLocation } from "react-router-dom";
import { useDashboardContext } from "../../dashboardContext";
import { doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import db from "../../../firebase-config";

const FormInput = ({ ...rest }) => {
	return (
		<input
			{...rest}
			className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
		/>
	);
};

export default function ContactUs() {
	const { setTitle, setUpdating } = useDashboardContext();

	const [editingMode, setEditingMode] = useState(false);
	const { pathname } = useLocation();

	const [data, setData] = useState(null);

	const ref = doc(db, "details", "contact_us");

	const getContent = async () => {
		try {
			const docSnap = await getDoc(ref);
			if (docSnap.exists()) {
				setData(docSnap.data());
			}
		} catch (error) {
			setData(null);
			console.error(error);
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		setUpdating(true);

		setDoc(ref, { ...data, updatedAt: Timestamp.now() }, { merge: true })
			.then(() => {
				setUpdating(false);
			})
			.catch((error) => {
				console.error(error);
				setUpdating(false);
			});
	};

	useEffect(() => {
		setTitle("Contact Us");
		getContent();
	}, []);

	return (
		<div className="py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 space-y-10 duration-300">
			{/* Top App Bar */}
			<div className="flex items-center justify-between gap-10">
				<div className="flex flex-col items-start">
					<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
						Manage Contact Us Details
					</h1>

					<div className="text-sm tracking-tight text-gray-600">
						Enable edit mode to start editing details ✌️
					</div>
				</div>

				<div
					className={
						"relative w-8 h-5 cursor-pointer rounded-full duration-300 flex items-center " +
						(editingMode ? "bg-blue-600" : "bg-gray-800")
					}
					onClick={() => setEditingMode(!editingMode)}
					title={editingMode ? "Disable editing mode" : "Enable to start editing"}
				>
					<div
						className={
							"absolute w-4 h-4 rounded-full bg-white duration-300 " +
							(editingMode ? "left-[45%]" : "left-[5%]")
						}
					></div>
				</div>
			</div>

			{/* Form */}
			<form
				action={pathname ?? "#"}
				method="POST"
				className="grid gap-12 tracking-tighter"
				onSubmit={handleSubmit}
			>
				<div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
					{/* Full Name */}
					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="name"
							className="font-medium text-sm"
						>
							<span>Full Name</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-11"
						>
							<FormInput
								type="text"
								id="name"
								readOnly={!editingMode}
								pattern="[a-zA-Z ]+"
								autoComplete="name"
								placeholder="Write your full Name"
								defaultValue={data?.name ?? ""}
								onKeyUp={(e) => {
									const val = e.currentTarget.value;
									setData((d) => ({
										...d,
										name: val.length > 0 ? val : "",
									}));
								}}
							/>
						</motion.div>
					</div>

					{/* Location */}
					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="location"
							className="font-medium text-sm"
						>
							<span>Location</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-11"
						>
							<FormInput
								type="text"
								id="location"
								readOnly={!editingMode}
								autoComplete="address-level1"
								placeholder="Write your location"
								defaultValue={data?.location ?? ""}
								onKeyUp={(e) => {
									const val = e.currentTarget.value;
									setData((d) => ({
										...d,
										location: val.length > 0 ? val : "",
									}));
								}}
							/>
						</motion.div>
					</div>
				</div>

				<div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
					{/* Company Email Address */}
					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="email"
							className="font-medium text-sm"
						>
							<span>Company Email Address</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-11"
						>
							<FormInput
								type="email"
								id="email"
								readOnly={!editingMode}
								autoComplete="email"
								placeholder="Company's email address"
								defaultValue={data?.email ?? ""}
								onKeyUp={(e) => {
									const val = e.currentTarget.value;
									setData((d) => ({
										...d,
										email: val.length > 0 ? val : "",
									}));
								}}
							/>
						</motion.div>
					</div>

					{/* Contact Number */}
					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="tel"
							className="font-medium text-sm"
						>
							<span>Contact Number</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-11"
						>
							<FormInput
								type="text"
								id="tel"
								readOnly={!editingMode}
								autoComplete="tel"
								defaultValue={data?.tel ?? ""}
								placeholder="Company's contact number"
								onKeyUp={(e) => {
									const val = e.currentTarget.value;
									setData((d) => ({
										...d,
										tel: val.length > 0 ? val : "",
									}));
								}}
							/>
						</motion.div>
					</div>
				</div>

				{/* Social Media Links */}
				<h1 className="inline-block tracking-tighter font-bold text-sm tn:text-base sm:text-lg duration-300">
					Social Media Links
				</h1>

				<div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
					{/* Instagram */}
					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="instagram"
							className="font-medium text-sm"
						>
							<span>Instagram</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-11"
						>
							<FormInput
								type="url"
								id="instagram"
								readOnly={!editingMode}
								autoComplete="off"
								placeholder="Instagram url"
								defaultValue={data?.instagram ?? ""}
								onKeyUp={(e) => {
									const val = e.currentTarget.value;
									setData((d) => ({
										...d,
										instagram: val.length > 0 ? val : "",
									}));
								}}
							/>
						</motion.div>
					</div>

					{/* Facebook */}
					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="facebook"
							className="font-medium text-sm"
						>
							<span>Facebook</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-11"
						>
							<FormInput
								type="url"
								id="facebook"
								readOnly={!editingMode}
								autoComplete="off"
								placeholder="Facebook url"
								defaultValue={data?.facebook ?? ""}
								onKeyUp={(e) => {
									const val = e.currentTarget.value;
									setData((d) => ({
										...d,
										facebook: val.length > 0 ? val : "",
									}));
								}}
							/>
						</motion.div>
					</div>
					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="linkedin"
							className="font-medium text-sm"
						>
							<span>LinkedIn</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-11"
						>
							<FormInput
								type="url"
								id="linkedin"
								readOnly={!editingMode}
								autoComplete="off"
								placeholder="LinkedIn url"
								defaultValue={data?.linkedin ?? ""}
								onKeyUp={(e) => {
									const val = e.currentTarget.value;
									setData((d) => ({
										...d,
										linkedin: val.length > 0 ? val : "",
									}));
								}}
							/>
						</motion.div>
					</div>
				</div>
				<div className="place-self-start flex items-center gap-6 flex-wrap">
					{editingMode ? (
						<button
							type="submit"
							className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-orange-400 to-orange-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
						>
							<span>Update Details</span>
							<Icon icon="solar:round-arrow-right-up-bold" fontSize={16} />
						</button>
					) : null}
				</div>
			</form>
		</div>
	);
}
