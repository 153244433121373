import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import { useLocation } from "react-router-dom";
import { useDashboardContext } from "../../dashboardContext";
import { doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import db from "../../../firebase-config";

const FormInput = ({ ...rest }) => {
	return (
		<input
			{...rest}
			className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
		/>
	);
};

export default function AboutUs() {
	const { pathname } = useLocation();
	const { setTitle, setUpdating } = useDashboardContext();

	const [editingMode, setEditingMode] = useState(false);

	const [data, setData] = useState(null);

	const ref = doc(db, "details", "about_us");

	const getContent = async () => {
		try {
			const docSnap = await getDoc(ref);
			if (docSnap.exists()) {
				setData(docSnap.data());
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		setUpdating(true);

		setDoc(ref, { ...data, updatedAt: Timestamp.now() }, { merge: true })
			.then(() => {
				setUpdating(false);
			})
			.catch((error) => {
				console.error(error);
				setUpdating(false);
			});
	};

	useEffect(() => {
		setTitle("About Us");
		getContent();
	}, []);

	return (
		<div className="py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 space-y-10 duration-300">
			{/* Top App Bar */}
			<div className="flex items-center justify-between gap-10">
				<div className="flex flex-col items-start">
					<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
						Manage About Us Contents
					</h1>

					<div className="text-sm tracking-tight text-gray-600">
						Enable edit mode to start editing contents
					</div>
				</div>

				<div
					className={
						"relative w-8 h-5 cursor-pointer rounded-full duration-300 flex items-center " +
						(editingMode ? "bg-blue-600" : "bg-gray-800")
					}
					onClick={() => setEditingMode(!editingMode)}
					title={editingMode ? "Disable editing mode" : "Enable to start editing"}
				>
					<div
						className={
							"absolute w-4 h-4 rounded-full bg-white duration-300 " +
							(editingMode ? "left-[45%]" : "left-[5%]")
						}
					></div>
				</div>
			</div>

			{/* Form */}
			<form
				action={pathname ?? "#"}
				method="POST"
				className="grid gap-12 tracking-tighter"
				onSubmit={handleSubmit}
			>
				<div className="w-full grid gap-2 justify-items-start">
					<motion.label
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						htmlFor="title"
						className="font-medium text-sm"
					>
						<span>Title</span>
					</motion.label>

					<motion.div
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						className="w-full h-11"
					>
						<FormInput
							type="text"
							id="title"
							readOnly={!editingMode}
							pattern="[a-zA-Z ]+"
							autoComplete="off"
							defaultValue={data?.title ?? ""}
							placeholder="Update title"
							onKeyUp={(e) => {
								const val = e.currentTarget.value;
								setData((d) => ({
									...d,
									title: val.length > 0 ? val : "",
								}));
							}}
						/>
					</motion.div>
				</div>

				<div className="w-full grid gap-2 justify-items-start">
					<motion.label
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						htmlFor="subtitle"
						className="font-medium text-sm"
					>
						<span>Sub Title</span>
					</motion.label>

					<motion.div
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						className="w-full h-11"
					>
						<FormInput
							type="text"
							id="subtitle"
							readOnly={!editingMode}
							autoComplete="off"
							defaultValue={data?.subTitle ?? ""}
							placeholder="Update subtitle"
							onKeyUp={(e) => {
								const val = e.currentTarget.value;
								setData((d) => ({
									...d,
									subTitle: val.length > 0 ? val : "",
								}));
							}}
						/>
					</motion.div>
				</div>

				<div className="w-full grid gap-2 justify-items-start">
					<motion.label
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						htmlFor="image"
						className="font-medium text-sm"
					>
						<span>Banner Image</span>
					</motion.label>

					<motion.div
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						className="w-full"
					>
						<input
							type="file"
							id="image"
							readOnly={!editingMode}
							disabled={!editingMode}
							accept="image/x-png, image/jpeg, image/webp, image/gif"
							className="tracking-tighter rounded-sm text-sm font-medium outline-none border-0"
						/>
					</motion.div>
				</div>

				<div className="w-full grid gap-2 justify-items-start">
					<motion.label
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						htmlFor="description"
						className="font-medium text-sm"
					>
						<span>Description</span>
					</motion.label>

					<motion.div
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						className="w-full h-auto"
					>
						<textarea
							rows={10}
							id="description"
							readOnly={!editingMode}
							className="w-full text-sm font-medium rounded-sm tracking-tighter outline-none py-2 px-4 border-2 focus-within:border-orange-500 focus:border-orange-500 bg-white placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
							placeholder="Write description about your organization..."
							defaultValue={data?.description ?? ""}
							onKeyUp={(e) => {
								const val = e.currentTarget.value;
								setData((d) => ({
									...d,
									description: val.length > 0 ? val : "",
								}));
							}}
						></textarea>
					</motion.div>
				</div>

				<div className="place-self-start flex items-center gap-6 flex-wrap">
					{editingMode ? (
						<button
							type="submit"
							className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-orange-400 to-orange-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
						>
							<span>Update Contents</span>
							<Icon icon="solar:round-arrow-right-up-bold" fontSize={16} />
						</button>
					) : null}
				</div>
			</form>
		</div>
	);
}
