import { deleteDoc, doc } from "firebase/firestore";
import db from "../../../firebase-config";
import { deleteFiles, updateCount } from "../utils";
import axios from "axios";
import moment from "moment";

export function deleteProperty(property) {
	return new Promise(async (resolve, reject) => {
		await deleteFiles([...property.images, ...property.files]);
		const newsDoc = doc(db, "properties", property.id);
		await deleteDoc(newsDoc)
			.then(async () => {
				await updateCount("properties");
				resolve(true);
			})
			.catch(() => reject(false));
	});
}

const xmlTags = {
	id: "uniqueID",
	subTitle: "",
	beds: "features bedrooms",
	tubs: "features bathrooms",
	parkings: "features secureParking",
	land: "landCategory",
	ptype: "category",
	agents: "listingAgent[id]",
	price: "price",
	rental_per_week: "rent[period='weekly']",
	security_bond: "bond",
	files: "media attachment",
	images: "objects img",
	links: "externalLink",
	inspections: "inspectionTimes inspection",
	area: "landDetails area",
	desc: "description",
	title: "headline",
	loc: {
		name: "address street",
		city: "address suburb",
		url: "",
		lat: "",
		long: "",
	},
	createdAt: "propertyList",
};

const getXMLContent = (doc, key, attr = null) => {
	const el = doc.querySelector(`${key}`);
	if (el) {
		if (attr != null) {
			if (el.hasAttribute(attr)) {
				return el.getAttribute(attr);
			} else {
				return "";
			}
		} else {
			return el.textContent;
		}
	} else {
		return "";
	}
};

function parseXmlDocument(doc) {
	return new Promise(async (resolve, reject) => {
		try {
			const getContent = (key, attr = null) => {
				return getXMLContent(doc, key, attr);
			};

			const getAgents = () => {
				const agents = [];
				doc.querySelectorAll(`${xmlTags.agents}`).forEach((el, i) => {
					if (el.querySelector("name")) {
						agents.push({
							name: getContent(`${xmlTags.agents} name`),
							email: getContent(`${xmlTags.agents} email`),
							phone: getContent(`${xmlTags.agents} telephone[type="mobile"]`),
							image: null,
						});
					}
				});

				return [...agents];
			};

			const formatInspectionTime = (str) => {
				const text = "18-Apr-2024 05:30pm to 05:45pm";

				// Regular expression patterns to match date, start time, and end time
				const datePattern = /^\d{1,2}-[A-Za-z]{3}-\d{4}/;
				const timePattern = /\b\d{2}:\d{2}[ap]m\b/g;

				// Extracting date
				const dateMatch = text.match(datePattern);
				const date = dateMatch ? dateMatch[0] : "";

				// Extracting start time and end time
				const times = text.match(timePattern);
				const start_time = times ? times[0] : "";
				const end_time = times ? times[1] : "";
				return { date, start_time, end_time };
			};

			const isLand = doc.querySelector(`propertyList land`);
			const isRental = doc.querySelector(`propertyList rental`);
			const isSale = doc.querySelector(`propertyList residential`);

			const result = {
				id: getContent(xmlTags.id),
				addedFromXML: true,
				land: isLand ? true : false,
				rental: isRental ? true : false,
				sale: isSale ? true : false,
				status: "ACTIVE",
				title: getContent(xmlTags.title),
				price: "$ " + getContent(xmlTags.price),
				area: getContent(xmlTags.area) + " M2",
				ptype: isLand ? getContent(xmlTags.land, "name") : getContent(xmlTags.ptype, "name"),
				beds: parseInt(getContent(xmlTags.beds)),
				tubs: parseInt(getContent(xmlTags.tubs)),
				parkings: parseInt(getContent(xmlTags.parkings)),
				desc: getContent(xmlTags.desc),
				images: Array.from(doc.querySelectorAll(`${xmlTags.images}[id][url][format]`)).map((el) => ({
					url: el.getAttribute("url"),
				})),
				files: Array.from(doc.querySelectorAll(`${xmlTags.files}[id][url][contentType]`)).map((el, i) => ({
					url: el.getAttribute("url"),
					name: "Attachment File " + i + 1,
				})),
				inspections: Array.from(doc.querySelectorAll(`${xmlTags.inspections}`)).map((el) =>
					formatInspectionTime(el.textContent)
				),
				agents: getAgents(),
				links: [
					...Array.from(doc.querySelectorAll(`${xmlTags.links}[href]`)).map((el, i) => ({
						url: el.getAttribute("href"),
						title: null,
					})),
					{
						url: getContent("videoLink", "href"),
						title: null,
					},
				],
				loc: {
					name: getContent(xmlTags.loc.name),
					city: getContent(xmlTags.loc.city),
					url: "",
					lat: "",
					long: "",
				},
				createdAt: {
					seconds: moment(getContent(xmlTags.createdAt, "date"), "YYYY-MM-DD-HH:mm:ss").format("X"),
				},
			};

			if (isRental) {
				result.security_bond = getContent(xmlTags.security_bond);
				result.rental_per_week = getContent(xmlTags.rental_per_week);
			}

			resolve(result);
		} catch (error) {
			resolve({});
		}
	});
}

export async function readXMLFiles(file = null) {
	try {
		const xmlFilepaths = (await axios.get("/backend/readXMLFiles.php")).data;

		// Checking if the file is provided or not and if it is available in the file paths or not.
		// if (file != null && xmlFilepaths.some((p) => p === file)) {
		// 	return new Promise(async (resolve, reject) => {
		// 		try {
		// 			const response = await axios.get("/eagle/" + file, {
		// 				"Content-Type": "application/xml; charset=utf-8",
		// 			});
		// 			var xml = response.data;
		// 			const parser = new DOMParser();
		// 			const xmlDoc = parser.parseFromString(xml, "text/xml");
		// 			const data = await parseXmlDocument(xmlDoc);
		// 			resolve({ ...data, xmlFile: file });
		// 		} catch (error) {
		// 			resolve({});
		// 		}
		// 	});
		// } else {
		return Promise.all(
			xmlFilepaths.map(async (file, i) => {
				// const xmlFilePath = "/eagle/" + file;
				// const response = await axios.get(xmlFilePath, {
				// 	"Content-Type": "application/xml; charset=utf-8",
				// });
				// var xml = response.data;
				var xml = file.content;
				const parser = new DOMParser();
				const xmlDoc = parser.parseFromString(xml, "text/xml");
				const data = await parseXmlDocument(xmlDoc);
				return { ...data, xmlFile: file };
			})
		);
		// }
	} catch (error) {
		return [];
	}
}
