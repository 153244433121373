import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import { addDoc, collection, doc, getDoc, increment, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import db from "../firebase-config";
import { updateCount } from "../admin/pages";

export default function ContactForm() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		id: null,
		fullname: null,
		email: null,
		mobile: null,
		subject: null,
		message: null,
		status: true,
		viewed: false,
	});

	const ref = collection(db, "messages");

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);

		await addDoc(ref, { ...data, sentAt: Timestamp.now() })
			.then(async (res) => {
				await updateDoc(doc(db, "messages", res.id), {
					id: res.id,
				});
				await updateCount("messages", true);
				setLoading(false);
				alert("Message sent successfully.");
				window.location.reload();
			})
			.catch(() => {
				setLoading(false);
				alert("Failed to send message");
			});
	};

	useEffect(() => {
		// var map = L.map('map').setView([51.505, -0.09], 13);
	}, []);

	return (
		<div className="w-full px-2 py-6 xs:py-10 lxs:py-14 md:py-20 duration-300" id="contactUs">
			<div className="w-full md:w-11/12 xl:w-10/12 mx-auto space-y-36 xs:space-y-36 lxs:space-y-32 md:space-y-40 grid duration-300">
				{/* Contact Information */}
				<div className="flex flex-col-reverse md:flex-row gap-10 md:gap-20 mlg:gap-32 justify-center shadow-sm overflow-hidden rounded-sm duration-300">
					{/* Contact Us Form */}
					<div className="relative w-full md:w-1/2 grid gap-10 justify-items-center rounded-2xl overflow-hidden duration-300">
						{/* Diplay while sending message */}
						{loading ? (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ duration: 0.3 }}
								className="absolute z-10 inset-0 flex items-center justify-center bg-black/30 text-white"
							>
								<div className="flex flex-col items-center text-center gap-4">
									<div className="text-3xl animate-spin">
										<Icon icon="solar:black-hole-line-duotone" />
									</div>

									<span className="text-base font-medium tracking-tighter">Sending message</span>
								</div>
							</motion.div>
						) : null}

						<h1
							className="place-self-start mxs:place-self-center md:place-self-start text-4xl font-bold tracking-tight duration-300"
							style={{ wordSpacing: 10 }}
						>
							Write a Message
						</h1>

						<form action="/" method="POST" onSubmit={handleSubmit} className="w-full max-w-xl grid gap-8">
							<div className="w-full grid grid-cols-1 mxs:grid-cols-2 md:grid-cols-1 mlg:grid-cols-2 gap-8">
								<div className="w-full grid justify-items-start">
									<label htmlFor="fullname" className="font-medium text-xsm">
										<span>Full name</span>
									</label>

									<div className="w-full h-11">
										<input
											type="text"
											id="fullname"
											required
											autoComplete="name"
											className="w-full h-full text-sm font-medium outline-none border-0 border-b border-slate-200 focus-within:border-slate-700 focus:border-slate-700 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear"
											placeholder="Enter your full name"
											onKeyUp={(e) => {
												const val = e.currentTarget.value;
												setData((d) => ({
													...d,
													fullname: val.length > 0 ? val : "",
												}));
											}}
										/>
									</div>
								</div>

								<div className="w-full grid justify-items-start">
									<label htmlFor="email" className="font-medium text-xsm">
										<span>Email address</span>
									</label>

									<div className="w-full h-11">
										<input
											type="email"
											id="email"
											required
											autoComplete="email"
											className="w-full h-full text-sm font-medium outline-none border-0 border-b border-slate-200 focus-within:border-slate-700 focus:border-slate-700 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear"
											placeholder="Your email address"
											onKeyUp={(e) => {
												const val = e.currentTarget.value;
												setData((d) => ({
													...d,
													email: val.length > 0 ? val : "",
												}));
											}}
										/>
									</div>
								</div>
							</div>

							<div className="w-full grid grid-cols-1 mxs:grid-cols-2 md:grid-cols-1 mlg:grid-cols-2 gap-8">
								<div className="w-full grid justify-items-start">
									<label htmlFor="mobile" className="font-medium text-xsm">
										<span>Phone</span>
									</label>

									<div className="w-full h-11">
										<input
											type="text"
											id="mobile"
											required
											autoComplete="tel"
											className="w-full h-full text-sm font-medium outline-none border-0 border-b border-slate-200 focus-within:border-slate-700 focus:border-slate-700 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear"
											placeholder="Mobile number"
											onKeyUp={(e) => {
												const val = e.currentTarget.value;
												setData((d) => ({
													...d,
													mobile: val.length > 0 ? val : "",
												}));
											}}
										/>
									</div>
								</div>

								<div className="w-full grid justify-items-start">
									<label htmlFor="subject" className="font-medium text-xsm">
										<span>Subject</span>
									</label>

									<div className="w-full h-11">
										<input
											type="text"
											id="subject"
											required
											autoComplete="subject"
											className="w-full h-full text-sm font-medium outline-none border-0 border-b border-slate-200 focus-within:border-slate-700 focus:border-slate-700 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear"
											placeholder="Your subject"
											onKeyUp={(e) => {
												const val = e.currentTarget.value;
												setData((d) => ({
													...d,
													subject: val.length > 0 ? val : "",
												}));
											}}
										/>
									</div>
								</div>
							</div>

							<div className="w-full grid justify-items-start">
								<label htmlFor="message" className="font-medium text-xsm">
									<span>Message</span>
								</label>

								<div className="w-full h-auto">
									<textarea
										id="message"
										required
										className="w-full text-sm py-2 font-medium outline-none border-0 border-b border-slate-200 focus-within:border-slate-700 focus:border-slate-700 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear"
										rows={6}
										placeholder="Your message"
										onKeyUp={(e) => {
											const val = e.currentTarget.value;
											setData((d) => ({
												...d,
												message: val.length > 0 ? val : "",
											}));
										}}
									></textarea>
								</div>
							</div>

							<button
								type="submit"
								className="place-self-center rounded-sm w-full mxs:w-auto outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 font-light bg-gradient-to-br from-yellow-500 to-yellow-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
							>
								<span>Send a Message</span>
								<Icon icon="solar:arrow-right-outline" fontSize={16} />
							</button>
						</form>
					</div>

					<div className="w-full md:w-1/2 h-80 md:h-auto border rounded-sm duration-300">
						<div className="w-full h-full bg-orange-500">
							<iframe
								title="Location"
								src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12643.214665180589!2d144.9994168!3d-37.6067795!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad64dce250cdab9%3A0x4d578f6f7d52c269!2sKrish%20Real%20Estate%20Pty%20Ltd!5e0!3m2!1sen!2snp!4v1711597922888!5m2!1sen!2snp"
								allowFullScreen=""
								loading="lazy"
								className="w-full h-full border-0"
								referrerPolicy="no-referrer-when-downgrade"
							></iframe>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
