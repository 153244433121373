import { deleteDoc, doc } from "firebase/firestore";
import db, { storage } from "../../../firebase-config";
import { deleteObject, ref } from "firebase/storage";
import { updateCount } from "..";

export function deleteReview(review) {
	return new Promise(async (resolve, reject) => {
		const deletingNewsRef = ref(storage, review.image.path);
		const newsDoc = doc(db, "reviews", review.id);
		await deleteObject(deletingNewsRef)
			.then(async () => {
				await deleteDoc(newsDoc)
					.then(async () => {
						await updateCount("reviews", false);
						resolve(true);
					})
					.catch(() => reject(false));
			})
			.catch(async (error) => {
				const confirmed = window.confirm(
					"Something went wrong while deleting image. Invalid file path or file not found.\nDo you want to continue deletion?"
				);
				if (confirmed) {
					deleteDoc(newsDoc)
						.then(() => resolve(true))
						.catch(() => reject(false));
				} else {
					resolve(true);
				}
			});
	});
}
