import React from "react";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import { deleteTeam } from "./actions";

export default function Team({ team, prefix = "/admin-dashboard", delay }) {
	return (
		<motion.div
			initial={{ opacity: 0, y: -10 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.3, delay: delay }}
			className="relative w-full h-auto group/team"
		>
			<div className="w-full h-auto rounded border hover:border-orange-200 overflow-hidden bg-white hover:bg-orange-50/20 duration-300">
				<a
					href={prefix + "/" + team.id}
					className="relative w-full aspect-[1/1] flex items-center"
				>
					<div className="w-full h-full border-b overflow-hidden">
						<img
							src={
								team.image !== null && team.image !== ""
									? team.image
									: "/static/images/default.jpg"
							}
							alt={"Property"}
							className="w-full h-full object-cover transition-all group-hover/team:scale-105 duration-300"
						/>
					</div>
				</a>

				{/* Content */}
				<div className="p-2 grid gap-3 justify-items-start">
					<div className="flex flex-col items-start -space-y-1">
						<h1 className="text-base font-semibold tracking-tight">
							{(team.firstname ?? "") +
								" " +
								(team.middlename ?? "") +
								" " +
								(team.lastname ?? "")}
						</h1>

						<h4 className="text-sm text-gray-600">{team.position ?? ""}</h4>
					</div>

					<div className="flex flex-col items-start font-medium text-gray-800">
						<h4 className="text-sm flex items-center gap-2">
							<Icon icon={"solar:phone-bold"} />
							<span>{team.phone ?? ""}</span>
						</h4>
					</div>

					<div className="w-full flex h-9 gap-2">
						<a
							href={prefix + "/" + team.id}
							className="w-full h-full flex-grow text-xsm rounded-sm flex items-center font-light justify-center px-2 bg-orange-500 hover:bg-orange-600 text-white duration-300"
						>
							<span>View profile</span>
						</a>

						<a
							href={prefix + "/" + team.id + "/edit"}
							className="h-full w-10 min-w-10 max-w-10 text-base rounded-sm flex items-center justify-center px-2 bg-gradient-to-br from-slate-200 to-gray-300 hover:bg-gradient-to-tr hover:text-blue-600 duration-300"
							title="Edit member details"
						>
							<Icon icon="solar:pen-new-round-bold-duotone" />
						</a>

						<div
							role="button"
							className="h-full w-10 min-w-10 max-w-10 text-lg rounded-sm flex items-center justify-center px-2 bg-gradient-to-br from-slate-200 to-gray-300 hover:bg-gradient-to-tr hover:text-rose-600 duration-300"
							title="Remove member from team"
							onClick={() => {
								const confirmed = window.confirm(
									"Are you sure you want to remove " +
										(team?.firstname ?? "") +
										" from team."
								);

								if (confirmed) {
									deleteTeam(team)
										.then(() => {
											window.location.reload();
										})
										.catch(() => alert("Failed to delete"));
								}
							}}
						>
							<Icon icon="solar:trash-bin-minimalistic-bold-duotone" />
						</div>
					</div>
				</div>
			</div>
		</motion.div>
	);
}
