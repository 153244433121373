import React from "react";
import { motion } from "framer-motion";
import FormInput from "./FormInput";
import { Icon } from "@iconify/react";

export default function InspectionTime({ inspections = [], setInspections }) {
	const removeBox = (key) => {
		const boxToRemove = document.getElementById(`#${key}`);
		if (boxToRemove) {
			boxToRemove.remove();
		}
		let __inspections = [...inspections];
		__inspections = __inspections.filter((c) => c.key !== key);
		setInspections([...__inspections]);
	};

	return (
		<div className="space-y-10">
			<h1 className="inline-block tracking-tighter font-bold text-sm tn:text-base sm:text-lg duration-300">
				Create Inspection Times
			</h1>

			<div className="flex flex-col items-start gap-4">
				<div className="flex flex-col items-start tracking-tighter">
					<span className="text-sm font-medium">Add multiple inspection times.</span>
				</div>

				{inspections.length > 0 && (
					<div className="flex flex-col items-start gap-4" id="inspectionTimeContainer">
						{inspections.map((inspection, index) => (
							<motion.div
								key={index}
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ duration: 0.3 }}
								id={inspection.key}
								className="w-full grid grid-cols-1 xsm:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-8 border-blue-500 duration-300 rounded-sm"
							>
								{/* Inspection Date */}
								<div className="w-full grid gap-2 justify-items-start">
									<motion.label
										initial={{ opacity: 0, y: -20 }}
										animate={{ opacity: 1, y: 0 }}
										transition={{ duration: 0.3, ease: "easeInOut" }}
										htmlFor="date"
										className="font-medium text-sm"
									>
										<span>Inspection Date</span>
									</motion.label>

									<motion.div
										initial={{ opacity: 0, y: -20 }}
										animate={{ opacity: 1, y: 0 }}
										transition={{ duration: 0.3, ease: "easeOut" }}
										className="w-full h-11"
									>
										<FormInput
											type="date"
											id="date"
											autoComplete="off"
											required
											placeholder="Inspection Date"
											value={inspection?.date ?? ""}
											onChange={(e) => {
												const val = e.currentTarget.value;
												inspections[index].date = val;
												setInspections([...inspections]);
											}}
										/>
									</motion.div>
								</div>

								{/* Start time */}
								<div className="w-full grid gap-2 justify-items-start">
									<motion.label
										initial={{ opacity: 0, y: -20 }}
										animate={{ opacity: 1, y: 0 }}
										transition={{ duration: 0.3, ease: "easeInOut" }}
										htmlFor="start_time"
										className="font-medium text-sm"
									>
										<span>Start time</span>
									</motion.label>

									<motion.div
										initial={{ opacity: 0, y: -20 }}
										animate={{ opacity: 1, y: 0 }}
										transition={{ duration: 0.3, ease: "easeOut" }}
										className="w-full h-11"
									>
										<FormInput
											type="time"
											id="start_time"
											autoComplete="off"
											required
											placeholder="Start time"
											value={inspection?.start_time ?? ""}
											onChange={(e) => {
												const val = e.currentTarget.value;
												inspections[index].start_time = val;
												setInspections([...inspections]);
											}}
										/>
									</motion.div>
								</div>

								{/* End time */}
								<div className="w-full grid gap-2 justify-items-start">
									<motion.label
										initial={{ opacity: 0, y: -20 }}
										animate={{ opacity: 1, y: 0 }}
										transition={{ duration: 0.3, ease: "easeInOut" }}
										htmlFor="end_time"
										className="font-medium text-sm"
									>
										<span>End time</span>
									</motion.label>

									<motion.div
										initial={{ opacity: 0, y: -20 }}
										animate={{ opacity: 1, y: 0 }}
										transition={{ duration: 0.3, ease: "easeOut" }}
										className="w-full h-11"
									>
										<FormInput
											type="time"
											id="end_time"
											autoComplete="off"
											required
											placeholder="End time"
											value={inspection?.end_time ?? ""}
											onChange={(e) => {
												const val = e.currentTarget.value;
												inspections[index].end_time = val;
												setInspections([...inspections]);
											}}
										/>
									</motion.div>
								</div>

								<div
									role="button"
									title="Remove"
									onClick={() => removeBox(inspection.key)}
									className="w-full rounded-sm flex items-center justify-center place-self-end gap-2 px-10 h-11 bg-gradient-to-br from-red-400 to-red-600 hover:bg-gradient-to-tr text-xs text-white cursor-pointer duration-300"
								>
									<span>Remove</span>
								</div>
							</motion.div>
						))}
					</div>
				)}

				{/* Upload files button */}
				<div
					role="button"
					onClick={() => {
						const date = new Date();
						const key = date.getTime();
						setInspections((prev) => [
							...prev,
							{ date: "", start_time: "", end_time: "", key: "box_" + key },
						]);
					}}
					className="rounded-sm flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-blue-400 to-blue-600 hover:bg-gradient-to-tr text-xs text-white cursor-pointer duration-300"
				>
					<span>Add</span>
					<Icon icon="solar:add-circle-outline" fontSize={16} />
				</div>
			</div>
		</div>
	);
}
