import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";
import { useDashboardContext } from "../../../dashboardContext";
import { Icon } from "@iconify/react";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import db from "../../../../firebase-config";
import { saveFiles, updateCount } from "../../utils";

const REDIRECT = "/admin-dashboard/our-teams";
const COLLECTION_NAME = "teams";

const FormInput = ({ ...rest }) => {
	return (
		<input
			{...rest}
			className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
		/>
	);
};

export default function AddMember() {
	const { setTitle, setUpdating } = useDashboardContext();

	const [data, setData] = useState({
		firstname: null,
		middlename: null,
		lastname: null,
		email: null,
		phone: null,
		gender: null,
		position: null,
		description: null,
	});

	const navigate = useNavigate();
	const { pathname } = useLocation();

	const [image, setImage] = useState({
		uri: "",
		ext: "",
	});

	const handleFileInput = (evt) => {
		const file = evt.target.files[0];

		if (file && evt.target.files.length >= 1) {
			const ext = file.name.substr(file.name.lastIndexOf(".") + 1);
			const reader = new FileReader();

			reader.readAsDataURL(file);

			reader.onloadend = (e) => {
				setImage({ uri: reader.result, ext });
			};
		} else {
			return;
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setUpdating(true);

		try {
			const { url, path } = (await saveFiles([image], COLLECTION_NAME))[0];
			const teamsRef = collection(db, COLLECTION_NAME);
			await addDoc(teamsRef, {
				...data,
				image: url,
				imagePath: path,
				createdAt: Timestamp.now(),
				updatedAt: Timestamp.now(),
			})
				.then(async (t) => {
					// Update the teams count
					await updateCount(COLLECTION_NAME, true);
					setUpdating(false);
					setTimeout(() => {
						window.location.href = REDIRECT + "/" + t.id;
					}, 100);
				})
				.catch((error) => {
					throw new Error(error);
				});
		} catch (error) {
			console.error(error);
			setUpdating(false);
		}
	};

	useEffect(() => {
		setTitle("Add new team member");
	}, []);

	return (
		<div className="py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 space-y-10 duration-300">
			{/* Top App Bar */}
			<div className="flex items-center justify-between gap-10">
				<div className="flex items-center gap-4">
					<div
						role="link"
						onClick={() => navigate(-1)}
						className="hover:scale-110 duration-200 text-xl cursor-pointer"
					>
						<Icon icon="solar:arrow-left-line-duotone" />
					</div>

					<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
						Add New Team Member
					</h1>
				</div>
			</div>

			{/* Bottom Form */}
			<form
				action={pathname ?? "#"}
				method="POST"
				className="grid gap-12 tracking-tighter"
				onSubmit={handleSubmit}
			>
				<div className="w-full grid grid-cols-1 lxs:grid-cols-2 sm:grid-cols-3 gap-12 duration-300">
					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="firstname"
							className="font-medium text-sm"
						>
							<span>First name</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-11"
						>
							<FormInput
								type="text"
								required
								id="firstname"
								pattern="[a-zA-Z]+"
								autoComplete="given-name"
								placeholder="Enter first name"
								onKeyUp={(e) => {
									const val = e.currentTarget.value;
									setData((d) => ({
										...d,
										firstname: val.length > 0 ? val : "",
									}));
								}}
							/>
						</motion.div>
					</div>

					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="middlename"
							className="font-medium text-sm"
						>
							<span>Middle name</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-11"
						>
							<FormInput
								type="text"
								id="middlename"
								pattern="[a-zA-Z]+"
								autoComplete="additional-name"
								placeholder="Enter middle name"
								onKeyUp={(e) => {
									const val = e.currentTarget.value;
									setData((d) => ({
										...d,
										middlename: val.length > 0 ? val : "",
									}));
								}}
							/>
						</motion.div>
					</div>

					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="lastname"
							className="font-medium text-sm"
						>
							<span>Last name</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-11"
						>
							<FormInput
								type="text"
								required
								id="lastname"
								pattern="[a-zA-Z]+"
								autoComplete="family-name"
								placeholder="Enter last name"
								onKeyUp={(e) => {
									const val = e.currentTarget.value;
									setData((d) => ({
										...d,
										lastname: val.length > 0 ? val : "",
									}));
								}}
							/>
						</motion.div>
					</div>
				</div>

				<div className="w-full grid grid-cols-1 lxs:grid-cols-2 sm:grid-cols-3 gap-12 duration-300">
					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="email"
							className="font-medium text-sm"
						>
							<span>Email address</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-11"
						>
							<FormInput
								type="email"
								id="email"
								required
								autoComplete="email"
								placeholder="Your email address"
								onKeyUp={(e) => {
									const val = e.currentTarget.value;
									setData((d) => ({
										...d,
										email: val.length > 0 ? val : "",
									}));
								}}
							/>
						</motion.div>
					</div>

					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="phone"
							className="font-medium text-sm"
						>
							<span>Mobile Number</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-11"
						>
							<FormInput
								type="text"
								id="phone"
								required
								autoComplete="tel"
								maxLength={15}
								placeholder="Enter contact number"
								onKeyUp={(e) => {
									const val = e.currentTarget.value;
									setData((d) => ({
										...d,
										phone: val.length > 0 ? val : "",
									}));
								}}
							/>
						</motion.div>
					</div>

					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="gender"
							className="font-medium text-sm"
						>
							<span>Gender</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-11"
						>
							<select
								id="gender"
								required
								className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
								defaultValue={""}
								onChange={(e) => {
									const val = parseInt(e.currentTarget.value);
									setData((d) => ({
										...d,
										gender: val,
									}));
								}}
							>
								<option value="" key="-1" disabled>
									Choose gender
								</option>
								<option value="0" key="0">
									Male
								</option>
								<option value="1" key="1">
									Female
								</option>
								<option value="2" key="2">
									Other
								</option>
							</select>
						</motion.div>
					</div>
				</div>

				<div className="w-full grid grid-cols-1 lxs:grid-cols-2 sm:grid-cols-3 gap-12 duration-300">
					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="position_role"
							className="font-medium text-sm"
						>
							<span>Position/Role</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-11"
						>
							<FormInput
								type="text"
								required
								id="position_role"
								autoComplete="off"
								placeholder="Enter position/role"
								onKeyUp={(e) => {
									const val = e.currentTarget.value;
									setData((d) => ({
										...d,
										position: val.length > 0 ? val : "",
									}));
								}}
							/>
						</motion.div>
					</div>

					{/* <div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="country"
							className="font-medium text-sm"
						>
							<span>Country</span>
						</motion.label>

						<div className="w-full h-11">
							<select
								id="country"
								required
								className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
								onChange={(e) => {
									const val = e.currentTarget.value;
									setData((d) => ({
										...d,
										country: val,
									}));
								}}
							>
								<option value="" key="-1" disabled selected>
									Choose country
								</option>
								<option value="Nepal" key="0">
									Nepal
								</option>
								<option value="India" key="1">
									India
								</option>
								<option value="Australia" key="2">
									Australia
								</option>
							</select>
						</div>
					</div> */}
				</div>

				<div className="w-full grid gap-2 justify-items-start">
					<motion.label
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						htmlFor="image"
						className="font-medium text-sm"
					>
						<span>Profile Image</span>
					</motion.label>

					<motion.div
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						className="w-full"
					>
						<input
							type="file"
							id="image"
							required
							onChange={handleFileInput}
							accept="image/x-png, image/jpeg, image/webp, image/gif"
							className="tracking-tighter rounded-sm text-sm font-medium outline-none border-0"
						/>
					</motion.div>
				</div>

				<div className="w-full grid gap-2 justify-items-start">
					<motion.label
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						htmlFor="description"
						className="font-medium text-sm"
					>
						<span>Member Description</span>
					</motion.label>

					<motion.div
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						className="w-full h-auto"
					>
						<textarea
							rows={10}
							id="description"
							required
							className="w-full text-sm font-medium rounded-sm tracking-tighter outline-none py-2 px-4 border-2 focus-within:border-orange-500 focus:border-orange-500 bg-white placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
							placeholder="Write description about team member..."
							onKeyUp={(e) => {
								const val = e.currentTarget.value;
								setData((d) => ({
									...d,
									description: val.length > 0 ? val : "",
								}));
							}}
						></textarea>
					</motion.div>
				</div>

				<div className="place-self-end flex items-center gap-6 flex-wrap">
					<button
						type="reset"
						className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-gray-300 to-gray-400 hover:bg-gradient-to-tr text-xs text-gray-700 font-semibold hover:text-black duration-300"
					>
						<span>Reset</span>
						<Icon icon="solar:close-square-outline" fontSize={16} />
					</button>

					<button
						type="submit"
						className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-orange-400 to-orange-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
					>
						<span>Add Member</span>
						<Icon icon="solar:add-square-bold" fontSize={16} />
					</button>
				</div>
			</form>
		</div>
	);
}
