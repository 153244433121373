import { deleteObject, ref, uploadString } from "firebase/storage";
import db, { storage } from "../../firebase-config";
import { doc, getDoc, increment, setDoc, updateDoc } from "firebase/firestore";

/**
 * Update the counter of given field
 *
 * @param {String} field Name of the field for counter operation
 * @param {Boolean} incr Whether to increase of decrease counter, by default it is set to false.
 * @param {Boolean} zero It determines whether to set the count to zero, default is set to false.
 * @returns Object
 */
export function updateCount(field = "", incr = false, zero = false) {
	return new Promise(async (resolve) => {
		if (typeof field == "string" && field !== "") {
			const counterRef = doc(db, "counters", field);
			const docSnap = await getDoc(counterRef);
			if (docSnap.exists()) {
				await updateDoc(counterRef, {
					count: zero ? 0 : increment(incr ? 1 : -1),
				})
					.then(() => resolve(true, field))
					.catch(() => resolve(null));
			} else {
				await setDoc(counterRef, {
					count: 1,
				})
					.then(() => resolve(true, field))
					.catch(() => resolve(null));
			}
		} else {
			resolve(null);
		}
	});
}

/**
 * Saves the file to storage and returns file url,path
 *
 * @param {String} data
 * @param {String} path
 * @returns String
 */
const saveFile = (data, path) => {
	return new Promise(async (resolve, reject) => {
		try {
			const storageRef = ref(storage, path);

			await uploadString(storageRef, data, "data_url")
				.then(async (res) => {
					let path = res.metadata.fullPath;
					const url = `https://firebasestorage.googleapis.com/v0/b/${
						process.env.REACT_APP_FB_projectId
					}.appspot.com/o/${path.replace(/\//g, "%2F")}?alt=media`;
					resolve(url);
				})
				.catch(() => resolve(""));
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Save multiple files to storage and returns array of url,path
 *
 * @param {Array} files Image blobs - base64 uri data
 * @param {String} dir Images Directory
 * @param {Boolean} customFilePath Whether to add custom file path or not,
 * by default it is set to false.
 * @returns Array of file url, path
 */
export async function saveFiles(files = [], dir = "", customFilePath = false) {
	return Promise.all(
		files.map(async (file, i) => {
			const date = new Date();
			const imgId = date.getTime();
			const path = customFilePath ? `${dir}/${file.name}` : `images/${dir}/${imgId}.${file.ext}`;

			const url = await saveFile(file.uri, path);

			if (customFilePath) {
				return { url, path, name: file?.name ?? "", size: file?.size ?? "" };
			} else {
				return { url, path };
			}
		})
	);
}

/**
 *
 * @param {String} path Path of the file in storage to delete
 * @returns Returns true if deleted successfully, else false.
 */
const deleteFile = async (path) => {
	return new Promise(async (resolve) => {
		try {
			const oldImageRef = ref(storage, path);
			await deleteObject(oldImageRef)
				.then(() => resolve(true))
				.catch(() => resolve(false));
		} catch (error) {
			resolve(false);
		}
	});
};

/**
 *
 * @param {Array} files Array of files paths to delete from storage.
 * @returns Bool
 */
export async function deleteFiles(files) {
	return Promise.all(
		files.map(async (file, i) => {
			const result = await deleteFile(file.path);
			return result;
		})
	);
}
