import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { doc, getDoc } from "firebase/firestore";
import db from "../../firebase-config";
import { useContainerContext } from "../../contexts/container";

export default function TermsAndConditions() {
	const { setTitle } = useContainerContext();
	const [content, setContent] = useState({});

	const getContent = async () => {
		const ref = doc(db, "contents", "terms-and-conditions");
		await getDoc(ref).then((res) => {
			if (res.exists()) {
				setContent({ ...res.data() });
			}
		});
	};

	useEffect(() => {
		setTitle("Terms and Conditions");
		getContent();
	}, []);

	return (
		<motion.div initial={{ opacity: 0, y: 6 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }}>
			<div className="w-full px-2 duration-300">
				<div className="w-full md:w-11/12 xl:w-10/12 mx-auto py-8 space-y-8 duration-300">
					<div className="grid gap-10 duration-300">
						<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-3xl duration-300">
							Terms and Conditions
						</h1>
					</div>

					<div
						className="text-pretty leading-loose text-sm"
						dangerouslySetInnerHTML={{ __html: content?.content ?? "" }}
					></div>
				</div>
			</div>
		</motion.div>
	);
}
