import React from "react";
import { motion } from "framer-motion";
import { IoBedOutline, IoCarOutline } from "react-icons/io5";
import { LuBath } from "react-icons/lu";

export default function Property({ property, delay }) {
	let option = property?.status?.replace("_", " ").toLowerCase();
	option = option.charAt(0).toUpperCase() + option.slice(1, option.length);

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.3, delay: delay }}
			className="relative w-full h-auto group/property"
		>
			{property.addedFromXML || property.status === "ACTIVE" ? null : (
				<div className="absolute z-20 left-1 top-1 rounded-sm bg-orange-500 text-white text-xs font-light px-2 py-[2px]">
					<span>{option}</span>
				</div>
			)}

			<div className="w-full h-auto rounded border border-gray-100 hover:border-orange-200 overflow-hidden bg-white hover:bg-orange-50/20 duration-300">
				<a
					href={
						(property.sale
							? "/properties-for-sale/"
							: property.rental
							? "/properties-for-rent/"
							: "/land-for-sale/") +
						property.id +
						(property.addedFromXML ? "?read-from-xml=true" : "?title=" + property.title)
					}
					className="w-full aspect-[2/1]"
				>
					<div className="w-full h-full border-b overflow-hidden">
						<img
							src={property.images[0].url ?? "/static/images/placeholder.jpg"}
							alt={property.title ?? "Property"}
							className="w-full aspect-[2/1] object-cover transition-all group-hover/property:scale-105 duration-300"
						/>
					</div>
				</a>

				{/* Property contents */}
				<div className="p-4 grid gap-5 justify-items-start">
					<div className="block space-y-4 text-left">
						<div className="text-sm font-semibold flex items-center gap-2 text-gray-800 cursor-default">
							<span>{property.ptype ?? ""}</span>
							{property.area && (
								<div className="inline-flex text-[0.8rem] gap-2 items-center py-1 px-2 rounded-full bg-gray-100">
									<img
										src="/static/icons/area.png"
										alt="area-icon"
										className="w-4 h-4 -scale-x-[1]"
									/>
									<span>{property.area ?? ""}</span>
								</div>
							)}
						</div>

						<div className="flex flex-col items-start tracking-tight">
							<a
								href={
									(property.sale
										? "/properties-for-sale/"
										: property.rental
										? "/properties-for-rent/"
										: "/land-for-sale/") +
									property.id +
									(property.addedFromXML ? "?read-from-xml=true" : "?title=" + property.title)
								}
								className="text-lg font-bold line-clamp-3 text-ellipsis leading-tight text-pretty group-hover/property:text-orange-600 duration-200"
							>
								{property.title ?? "Unknown"}
							</a>

							<span className="text-sm font-medium line-clamp-2 text-ellipsis text-gray-500">
								{property.subTitle ?? ""}
							</span>
						</div>

						<div className="flex flex-col items-start text-sm font-medium">
							<a href={property.loc.url ?? ""} rel="noreferrer" target="_blank" className="text-gray-500">
								{property.loc.name ?? ""}
							</a>

							<span className="font-semibold">{property.price ?? ""}</span>
						</div>
					</div>

					{property.land ? null : (
						<div className="flex items-center gap-4 *:cursor-default *:flex *:items-center *:gap-1 *:text-base *:font-semibold *:py-1 *:px-1.5 *:rounded-full">
							<div className="duration-200 hover:bg-slate-100 hover:text-orange-700">
								<IoBedOutline size={18} />
								<span>{property.beds}</span>
							</div>

							<div className="duration-200 hover:bg-rose-100 hover:text-rose-600">
								<LuBath size={18} />
								<span>{property.tubs}</span>
							</div>

							<div className="duration-200 hover:bg-green-100 hover:text-green-600">
								<IoCarOutline size={18} />
								<span>{property.parkings}</span>
							</div>
						</div>
					)}
				</div>
			</div>
		</motion.div>
	);
}
