import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { screens } from "../../../../utils";
import { useContainerContext } from "../../../../contexts/container";
import Team from "./team";
import { collection, getDocs } from "firebase/firestore";
import db from "../../../../firebase-config";

export default function MeetOurTeam() {
	const { width } = useContainerContext();
	const [swiperKey, setSwiperKey] = useState(0);

	const swiperRef = useRef();

	const [teams, setTeams] = useState([]);

	const getTeams = async () => {
		const ref = collection(db, "teams");
		await getDocs(ref)
			.then((res) => {
				if (res.empty) setTeams([]);
				else setTeams(res.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
			})
			.catch(() => setTeams([]));
	};

	useEffect(() => {
		setSwiperKey(1);
		getTeams();

		return () => {
			setSwiperKey(0);
		};
	}, []);

	return teams.length > 0 ? (
		<div className="w-full px-2 py-6 xs:py-10 lxs:py-14 md:py-20 duration-300" id="ourTeam">
			<div className="w-full md:w-11/12 xl:w-10/12 mx-auto space-y-6 xs:space-y-10 lxs:space-y-14 md:space-y-20 grid duration-300">
				<div className="text-center">
					<h1 className="text-2xl sm:text-3xl mlg:text-4xl font-extrabold tracking-tighter uppercase duration-300">
						Meet Our Team
					</h1>
				</div>

				{/* Swiper | teams | Contents */}
				<div className="relative w-full h-auto overflow-hidden">
					<Swiper
						key={swiperKey}
						ref={swiperRef}
						slidesPerView={width > screens.xl ? 4 : width > screens.md ? 3 : width > screens.xsm ? 2 : 1}
						slidesPerGroup={1}
						spaceBetween={20}
						keyboard={{
							enabled: true,
						}}
						autoplay={{
							delay: 5000,
							disableOnInteraction: false,
						}}
						grabCursor={true}
						modules={[Navigation]}
						className="bg-transparent"
					>
						{teams.map((team, index) => (
							<SwiperSlide key={index} className="overflow-hidden bg-transparent rounded hover:bg-white">
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									transition={{ duration: 0.3, delay: 0.1 * index }}
									className="relative w-full h-auto"
								>
									<Team team={team} />
								</motion.div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</div>
	) : null;
}
