import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiPhone, FiInstagram, FiLinkedin, FiMapPin, FiMail, FiFacebook } from "react-icons/fi";
import { doc, getDoc } from "firebase/firestore";
import db from "../firebase-config";

export default function Footer() {
	const [data, setData] = useState({});

	const getData = async () => {
		const ref = doc(db, "details", "contact_us");
		await getDoc(ref)
			.then((res) => {
				if (res.exists()) {
					setData({ ...res.data() });
				} else {
					setData({});
				}
			})
			.catch(() => setData({}));
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<footer className="w-full space-y-10">
			<div className="w-full bg-gradient-to-br from-yellow-500 to-yellow-700 text-black p-6 xs:p-10 lxs:p-14 md:p-20 gap-6 xs:gap-10 lxs:gap-14 md:gap-20 grid justify-items-start xs:justify-items-center duration-300">
				<div className="w-full flex flex-wrap justify-between items-start gap-10 text-black tracking-tighter duration-300">
					<div className="grid justify-items-start gap-6">
						<h3 className="font-medium text-2xl">Quick Links</h3>

						<ul className="list-none space-y-2 text-base hover:*:text-gray-300 *:duration-300">
							<li>
								<a href="/">Home</a>
							</li>

							<li>
								<a href="/contact-us">Contact Us</a>
							</li>

							<li>
								<a href="/get-a-free-appraisal">Get free appraisal</a>
							</li>
						</ul>
					</div>

					{/* Logo and Social media links */}
					<div className="grid gap-8 justify-items-start xs:justify-items-center">
						<div className="w-32 h-auto flex items-center justify-start xs:justify-center duration-300">
							<img src={process.env.REACT_APP_APP_LOGO} alt="logo" className="w-full h-auto" />
						</div>

						<div className="w-full xs:w-auto flex flex-col xs:flex-row items-start xs:items-center *:w-full xs:*:w-auto *:py-4 xs:*:py-0 *:px-0 xs:*:px-4 *:flex *:items-center *:gap-2 *:duration-500 uppercase text-black-300 font-medium tracking-tight text-xsm">
							<a
								href={data?.instagram ?? "#"}
								rel="noreferrer"
								target="_blank"
								className="hover:text-[#600B0B]"
							>
								<FiInstagram size={20} />

								<span>Instagram</span>
							</a>

							<a
								href={data?.linkedin ?? "#"}
								rel="noreferrer"
								target="_blank"
								className="hover:text-[#0D2673] border-y xs:border-y-0 border-x-0 xs:border-x border-gray-300/50"
							>
								<FiLinkedin size={20} />

								<span>LinkedIn</span>
							</a>

							<a
								href={data?.facebook ?? "#"}
								rel="noreferrer"
								target="_blank"
								className="hover:text-blue-700 duration-300"
							>
								<FiFacebook size={20} />

								<span>Facebook</span>
							</a>
						</div>
					</div>

					<div className="grid justify-items-start gap-6">
						<h3 className="font-medium text-2xl">Contact Us</h3>

						<ul className="list-none space-y-2 text-base hover:*:text-gray-300 *:flex *:items-center *:gap-2 *:duration-300">
							<a href={`tel:${data?.tel ?? ""}`}>
								<FiPhone />

								<span>{data?.tel ?? ""}</span>
							</a>

							<a href={`mailTo:${data?.email ?? ""}`}>
								<FiMail />
								<span>{data?.email ?? ""}</span>
							</a>

							<span>
								<FiMapPin />
								<span>{data?.location ?? ""}</span>
							</span>
						</ul>
					</div>
				</div>

				<div className="w-full md:w-11/12 text-white xl:w-10/12 mx-auto duration-300">
					<div className="flex flex-col xsm:flex-row divide-y xsm:divide-y-0 items-start xs:items-center justify-between text-xsm font-medium">
						<div className="py-4 text-zinc-300 flex flex-col items-start">
							<span>© Krish Real Estate. All rights reserved.</span>
							<a href="https://ideagen.com.np/" rel="noreferrer" target="_blank">
								<span>Crafted by IdeaGen.</span>
							</a>
						</div>

						<div className="w-full xs:w-auto flex flex-col xsm:flex-row items-start xsm:items-center gap-0 xsm:gap-6 divide-y xsm:divide-y-0 *:w-full xs:*:w-auto *:py-4">
							<a
								href={"/privacy-policy"}
								className="hover:text-zinc-100 text-zinc-300 hover:first-letter:font-bold"
							>
								Privacy policy
							</a>

							<a
								href={"/terms-and-conditions"}
								className="hover:text-zinc-100 text-zinc-300 hover:first-letter:font-bold"
							>
								Terms and conditions
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}
