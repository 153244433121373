import React from "react";
import { motion } from "framer-motion";
import { Empty, Property } from "../utils";

export default function SearchResults({ properties = [], tag = null }) {
	return (
		<motion.div
			initial={{ opacity: 0, y: -6 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.2, ease: "easeOut" }}
			className="w-full h-auto grid gap-6"
		>
			{properties.length > 0 ? (
				<div className="flex items-center justify-between gap-10">
					<div className="flex gap-4 items-center">
						<div className="flex items-center gap-2">
							<span className="text-sm font-semibold text-indigo-600">
								{properties.length},
							</span>

							<div className="font-semibold text-base tracking-tight">
								{properties.length > 1 ? "properties" : "property"} found{" "}
								{tag ? "in " : ""}
								<span className="text-indigo-600">
									{tag ? tag + " city" : ""}&nbsp;
								</span>
							</div>
						</div>
					</div>
				</div>
			) : null}

			<div className="w-full grid grid-cols-1 xsm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8 row-auto duration-200">
				{properties.length > 0 ? (
					properties.map((property, index) => (
						<Property key={index} property={property} delay={0.1 * index} />
					))
				) : (
					<Empty />
				)}
			</div>

			{/* {properties.length > 8 ? (
				<div className="place-self-center">
					<Link
						to={"/"}
						className="text-xsm text-indigo-500 font-medium hover:text-indigo-700 rounded-full px-4 py-2 hover:bg-slate-50 duration-200 flex items-center gap-2"
					>
						<span>Load more...</span>
						<Icon icon="solar:arrow-down-outline" />
					</Link>
				</div>
			) : null} */}
		</motion.div>
	);
}
