import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDashboardContext } from "../../../dashboardContext";
import { Icon } from "@iconify/react";
import db from "../../../../firebase-config";
import { doc, getDoc, Timestamp, updateDoc } from "firebase/firestore";

const FormInput = ({ ...rest }) => {
	return (
		<input
			{...rest}
			className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
		/>
	);
};

export default function EditNews() {
	const { setTitle, setUpdating } = useDashboardContext();

	const navigate = useNavigate();
	const { pathname } = useLocation();

	const { id } = useParams();
	const [news, setNews] = useState(null);

	const ref = doc(db, "news", id);

	const getContent = async () => {
		try {
			const docSnap = await getDoc(ref);
			if (docSnap.exists()) {
				setNews(docSnap.data());
			} else {
				setNews({});
			}
		} catch (error) {
			setNews({});
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setUpdating(true);

		try {
			await getEditorData().then(async (content) => {
				updateDoc(ref, { ...news, content, updatedAt: Timestamp.now() })
					.then((_) => setUpdating(false))
					.catch((_) => setUpdating(false));
			});
		} catch (error) {
			setUpdating(false);
		}
	};

	useEffect(() => {
		getContent();
	}, [id]);

	const editorRef = useRef(null);
	const loadScript = (url, callback) => {
		const existingScript = document.getElementById("ckeditor-script");
		if (!existingScript) {
			const script = document.createElement("script");
			script.src = url;
			script.id = "ckeditor-script";
			document.body.appendChild(script);
			script.onload = () => {
				if (callback) callback();
			};
		} else if (existingScript && callback) {
			callback();
		}
	};

	useEffect(() => {
		setTitle("Update news details");
		if (window.CKEDITOR) {
			initCKEditor();
		} else {
			loadScript("/lib/ckeditor/ckeditor.js", initCKEditor);
		}

		return () => {
			if (window.CKEDITOR) {
				for (let instance in window.CKEDITOR.instances) {
					window.CKEDITOR.instances[instance].destroy(true);
				}
				const script = document.getElementById("ckeditor-script");
				if (script) {
					document.body.removeChild(script);
				}
			}
		};
	}, []);

	const initCKEditor = () => {
		if (editorRef.current && window.CKEDITOR) {
			window.CKEDITOR.replace(editorRef.current);
		}
	};

	const getEditorData = () => {
		return new Promise((resolve) => {
			const content =
				window.CKEDITOR.instances[editorRef.current.getAttribute("id")].getData();
			resolve(content);
		});
	};

	return news ? (
		Object.keys(news).length > 0 ? (
			<div className="py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 space-y-10 duration-300">
				{/* Top App Bar */}
				<div className="flex items-center justify-between gap-10">
					<div className="flex items-center gap-4">
						<div
							role="link"
							onClick={() => navigate(-1)}
							className="hover:scale-110 duration-200 text-xl cursor-pointer"
						>
							<Icon icon="solar:arrow-left-line-duotone" />
						</div>

						<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
							Update News
						</h1>
					</div>
				</div>

				{/* Bottom Form */}
				<form
					action={pathname ?? "#"}
					method="POST"
					className="grid gap-12 tracking-tighter"
					onSubmit={handleSubmit}
				>
					{/* News Title */}
					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="title"
							className="font-medium text-sm"
						>
							<span>Title</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-11"
						>
							<FormInput
								type="text"
								id="title"
								required
								autoComplete="off"
								placeholder="Add news title"
								defaultValue={news?.title ?? ""}
								onKeyUp={(e) => {
									const val = e.currentTarget.value;
									setNews((n) => ({
										...n,
										title: val.length > 0 ? val : "",
									}));
								}}
							/>
						</motion.div>
					</div>

					<div className="place-self-start w-60 grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="status"
							className="font-medium text-sm"
						>
							<span>Status</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-11"
						>
							<select
								id="status"
								required
								className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
								onChange={(e) => {
									const val = parseInt(e.currentTarget.value);
									setNews((n) => ({
										...n,
										status: Boolean(val),
									}));
								}}
							>
								<option value="1" key="0">
									Active
								</option>
								<option value="0" key="1">
									Hidden
								</option>
							</select>
						</motion.div>
					</div>

					{/* News Images */}
					<motion.div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="images"
							className="font-medium text-sm"
						>
							<span>Update Image</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full"
						>
							<input
								type="file"
								id="images"
								multiple
								accept="image/x-png, image/jpeg, image/webp, image/gif"
								className="tracking-tighter rounded-sm text-sm font-medium outline-none border-0"
							/>
						</motion.div>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-40 h-40 rounded border bg-white flex items-center justify-center overflow-hidden"
						>
							<img
								src={news.image.url ?? "/static/images/default.jpg"}
								alt={news.title ?? "News"}
								className="w-auto h-auto object-cover bg-gray-50"
							/>
						</motion.div>
					</motion.div>

					{/* News Contents */}
					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="content"
							className="font-medium text-sm"
						>
							<span>News Contents</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-auto"
						>
							<div
								id="content"
								ref={editorRef}
								contentEditable
								className="w-full h-full resize-y text-sm font-medium rounded-sm tracking-tighter outline-none py-2 px-4 border-2 focus-within:border-orange-500 focus:border-orange-500 bg-white placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
								dangerouslySetInnerHTML={{ __html: news.content ?? "" }}
							></div>
						</motion.div>
					</div>

					<div className="place-self-end flex items-center gap-6 flex-wrap">
						{/* Go back */}
						<button
							type="button"
							onClick={() => navigate(-1)}
							className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-gray-300 to-gray-400 hover:bg-gradient-to-tr text-xs text-gray-700 font-semibold hover:text-black duration-300"
						>
							<Icon icon="solar:arrow-left-outline" fontSize={16} />
							<span>Go Back</span>
						</button>

						<button
							type="submit"
							className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-orange-400 to-orange-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
						>
							<span>Update News</span>
							<Icon icon="solar:add-square-bold" fontSize={16} />
						</button>
					</div>
				</form>
			</div>
		) : (
			<div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
				<div className="grid text-center justify-items-center gap-4">
					<div className="text-6xl text-orange-500">
						<Icon icon="solar:notebook-bold-duotone" />
					</div>

					<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
						News not found.
					</h1>
				</div>
			</div>
		)
	) : (
		<div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
			<div className="grid text-center justify-items-center gap-4">
				<div className="text-6xl text-indigo-500 animate-spin">
					<Icon icon="solar:black-hole-line-duotone" />
				</div>

				<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
					Loading news details...
				</h1>
			</div>
		</div>
	);
}
