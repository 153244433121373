import React, { Fragment, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { doc, getDoc } from "firebase/firestore";
import db from "../../../../firebase-config";
import { deleteTeam } from "../actions";

const REDIRECT = "/admin-dashboard/our-teams";

export default function ViewMember() {
	const { pathname } = useLocation();
	const { id } = useParams();
	const [team, setTeam] = useState(null);

	const getTeam = async () => {
		const ref = doc(db, "teams", id);
		try {
			const docSnap = await getDoc(ref);
			if (docSnap.exists()) {
				setTeam({ id: docSnap.id, ...docSnap.data() });
			} else {
				setTeam({});
			}
		} catch (error) {
			setTeam({});
		}
	};

	useEffect(() => {
		getTeam();
	}, [id]);

	return team ? (
		Object.keys(team).length > 0 ? (
			<Fragment>
				<div className="relative bg-gradient-to-br from-orange-600 to-yellow-600 duration-300">
					<div className="w-full md:w-11/12 xl:w-10/12 mx-auto px-2 py-6 xs:py-10 lxs:py-14 md:py-20 flex items-center gap-6 xs:gap-10 lxs:gap-14 md:gap-20 duration-300">
						<div className="relative w-64 h-64 rounded border border-orange-400 overflow-hidden duration-300">
							<img
								src={team.image ?? "/static/images/default.jpg"}
								alt={team.firstname ?? "profile"}
								className="w-full h-full object-cover"
							/>
						</div>

						<div className="py-4 grid items-center justify-items-start gap-10 tracking-tighter duration-300">
							<div className="flex flex-col items-start">
								<h1 className="font-semibold text-4xl">
									{(team.firstname ?? "") +
										" " +
										(team.middlename ?? "") +
										" " +
										(team.lastname ?? "")}
								</h1>
								<h4 className="font-medium text-base text-gray-900">{team.position ?? "..."}</h4>
							</div>

							<div className="flex flex-wrap gap-6 h-11">
								{team.email && (
									<a
										href="/"
										className="h-full px-6 rounded-xl bg-gradient-to-tr from-slate-200 to-gray-100 hover:bg-gradient-to-br text-sm font-semibold flex items-center gap-2 hover:text-orange-600 duration-300"
									>
										<Icon icon="solar:letter-opened-bold-duotone" fontSize={20} />
										<span>{team.email ?? ""}</span>
									</a>
								)}
								{team.phone && (
									<a
										href="/"
										className="h-full px-6 rounded-xl bg-gradient-to-tr from-slate-200 to-gray-100 hover:bg-gradient-to-br text-sm font-semibold flex items-center gap-2 hover:text-orange-600 duration-300"
									>
										<Icon icon="solar:smartphone-2-bold-duotone" fontSize={20} />
										<span>{team.phone ?? ""}</span>
									</a>
								)}
							</div>
						</div>
					</div>
				</div>

				<div className="sticky top-0 bg-white py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 duration-300">
					<div className="w-full flex flex-wrap items-start justify-between gap-2">
						<div className="space-y-2">
							<div className="w-full font-semibold text-lg sm:text-xl md:text-2xl tracking-tighter duration-300">
								About <span className="text-gray-800">{team?.firstname ?? ""}</span>
							</div>
						</div>

						<div className="w-full lxs:w-auto flex items-center font-light gap-4 flex-wrap duration-300">
							<div
								role="button"
								onClick={() => {
									const confirmed = window.confirm(
										"Are you sure you want to remove " + (team?.firstname ?? "") + " from team."
									);

									if (confirmed) {
										deleteTeam(team)
											.then(() => {
												window.location.href = REDIRECT;
											})
											.catch((error) => {
												alert("Failed to remove member");
												console.log(error);
											});
									}
								}}
								className="bg-gradient-to-br from-red-600 to-rose-500 hover:bg-gradient-to-tr text-white text-sm w-1/2 sm:w-auto px-6 h-9 flex items-center justify-center rounded border duration-300"
							>
								<span>Remove member</span>
							</div>

							<a
								href={pathname + "/edit"}
								className="bg-gradient-to-br from-blue-600 to-indigo-400 hover:bg-gradient-to-tr text-white text-sm w-1/2 sm:w-auto px-6 h-9 flex items-center justify-center rounded border duration-300"
							>
								<span>Edit</span>
							</a>
						</div>
					</div>
				</div>

				<div className="px-6 sm:px-10 md:px-14 lg:px-20 pb-6 duration-300">
					<div className="text-pretty leading-loose text-left font-medium text-sm">
						{team?.description ?? ""}
					</div>
				</div>
			</Fragment>
		) : (
			<div className="w-full h-full p-10 flex items-center justify-center">
				<div className="grid justify-items-center gap-4">
					<div className="text-8xl text-orange-500">
						<Icon icon="solar:planet-bold-duotone" />
					</div>

					<span className="font-medium text-lg tracking-tighter">Member not found.</span>
				</div>
			</div>
		)
	) : (
		<div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
			<div className="grid text-center justify-items-center gap-4">
				<div className="text-6xl text-indigo-500 animate-spin">
					<Icon icon="solar:black-hole-line-duotone" />
				</div>

				<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
					Loading member details...
				</h1>
			</div>
		</div>
	);
}
