import { doc, deleteDoc, updateDoc, increment } from "firebase/firestore";
import db from "../../../../firebase-config";

const REDIRECT = "/admin-dashboard/free-appraisals";

export const deleteRequest = async (id, redirect = false) => {
	const confirmed = window.confirm("Are you sure you want to delete this request?");

	if (confirmed) {
		const ref = doc(db, "free-appraisals", id);
		await deleteDoc(ref)
			.then(async () => {
				const counterRef = doc(db, "counters", "free-appraisals");
				await updateDoc(counterRef, {
					count: increment(-1),
				});

				alert("Deleted");
				if (redirect) {
					window.location.href = REDIRECT;
				} else {
					window.location.reload();
				}
			})
			.catch(() => alert("Failed to delete."));
	}
};
