import { collection, deleteDoc, doc, getDocs, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import db from "../../../firebase-config";
import AddAuthUser from "./add";
import { Icon } from "@iconify/react";
import moment from "moment";
import { useDashboardContext } from "../../dashboardContext";
import EditAuthUser from "./edit";

export default function AuthUsers() {
	const { user: authUser, setTitle } = useDashboardContext();
	const [loaded, setLoaded] = useState(false);

	const [users, setUsers] = useState([]);

	const getUsers = async () => {
		let ref = collection(db, "users");
		ref = query(ref, orderBy("createdAt", "desc"));
		await getDocs(ref).then((res) => {
			if (!res.empty) {
				setUsers(res.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
			}
		});
	};

	const deleteUser = async (user) => {
		const ref = doc(db, "users", user.id);
		await deleteDoc(ref).then(() => {
			window.location.reload();
		});
	};

	useEffect(() => {
		if (loaded) {
			setTitle("Admin users");
			getUsers();
		} else return;
	}, [loaded]);

	useEffect(() => {
		setLoaded(true);

		return () => {
			setLoaded(false);
			setUsers([]);
		};
	}, []);

	return (
		<div className="h-screen py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 flex flex-col space-y-10 duration-300">
			{/* Top App Bar */}
			<div className="w-full flex items-center justify-between gap-10">
				<div className="flex flex-col items-start">
					<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
						Admin Users
					</h1>
				</div>
			</div>

			<div className="w-full h-auto overflow-auto">
				<table className="w-full h-full min-w-max overflow-auto border-collapse">
					<thead className="w-full text-sm tracking-tighter border-b">
						<tr className="text-left *:px-4">
							<th className="py-2">SN</th>
							<th>Full Name</th>
							<th>Email Address</th>
							<th>Added At</th>
							<th>Actions</th>
						</tr>
					</thead>

					<tbody>
						{users.map((user, index) => (
							<tr key={index} className="text-left text-xsm hover:bg-gray-100 even:bg-gray-50 *:px-4">
								<td>
									<div className="w-6 h-6 rounded-full flex items-center justify-center">
										<span className="font-medium text-sm">{index + 1}.</span>
									</div>
								</td>

								<td className="py-4 font-semibold">
									<span className="hover:text-blue-600">{user.username ?? ""}</span>
								</td>

								<td>
									<span className="font-semibold min-w-max">{user.email ?? ""}</span>
								</td>

								<td>
									<div className="min-w-max font-medium">
										{moment(user?.createdAt?.seconds * 1000).format("MMM DD, hh:mm a")}
									</div>
								</td>
								<td>
									<div className="flex items-center gap-4 text-gray-800 *:duration-300 *:text-2xl">
										<a
											href={"/admin-dashboard/users/" + user.id}
											className="hover:text-blue-600"
											title="Edit User"
										>
											<Icon icon="solar:pen-new-round-bold-duotone" />
										</a>

										{authUser.email === user?.email ? null : (
											<div
												role="button"
												onClick={() => {
													const confirmed = window.confirm(
														"Are you sure you want to delete " +
															(user?.username ?? "") +
															"."
													);

													if (confirmed) {
														deleteUser(user);
													}
												}}
												className="hover:text-red-600 cursor-pointer"
												title="Delete user"
											>
												<Icon icon="solar:trash-bin-minimalistic-bold-duotone" />
											</div>
										)}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export { AddAuthUser, EditAuthUser };
