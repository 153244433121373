import React, { Fragment, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useDashboardContext } from "../../../dashboardContext";
import { Icon } from "@iconify/react";
import { useParams } from "react-router-dom";
import { doc, updateDoc, Timestamp, getDoc, collection, getDocs } from "firebase/firestore";
import db from "../../../../firebase-config";
import { Location } from "../components";
import { deleteFiles, saveFiles } from "../../utils";
import { COLLECTION_NAME } from "..";
import {
	Area,
	Baths,
	Beds,
	Parkings,
	Price,
	PropertyStatus,
	PropertyType,
	RentalPerWeek,
	SecurityBond,
	Status,
	SubTitle,
	Title,
} from "../components/FormInputs";
import Attachments from "../components/Attachments";
import { FiX } from "react-icons/fi";
import Images from "../components/Images";
import InspectionTime from "../components/InspectionTime";
import Links from "../components/Links";

export default function EditProperty() {
	const { setTitle, setUpdating } = useDashboardContext();

	const { id } = useParams();

	const docRef = doc(db, COLLECTION_NAME, id);

	const [property, setProperty] = useState(null);

	const [loc, setLoc] = useState({
		name: null,
		city: null,
		url: null,
		lat: null,
		long: null,
	});

	const [inspections, setInspections] = useState([]);
	const [links, setLinks] = useState([]);

	const [checkedValues, setCheckedValues] = useState([]);
	const handleCheckboxChange = (event) => {
		const { value, checked } = event.target;
		setCheckedValues((prev) => {
			if (checked) {
				return [...prev, value];
			} else {
				return prev.filter((item) => item !== value);
			}
		});
	};

	const [uris, setUris] = useState([]);
	const handleFileInput = (event) => {
		const files = event.target.files;
		if (files && files.length > 0) {
			setUris([]);
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				const ext = file.name.substr(file.name.lastIndexOf(".") + 1);

				// Skip large files > 5MB
				if (file.size > 5120000) {
					continue;
				}

				const reader = new FileReader();
				reader.onloadend = (e) => {
					setUris((prev) => [...prev, { uri: e.target.result, ext }]);
				};
				reader.readAsDataURL(file);
			}
		}
	};

	/** Delete property image */
	const deleteImage = async (path, index) => {
		const confirmed = window.confirm("Are you sure to delete?");
		if (confirmed) {
			try {
				const deleted = (await deleteFiles([{ path }]))[0];
				if (deleted) {
					const __prevImages = property.images;
					__prevImages.splice(index, 1);
					await updateDoc(docRef, {
						images: __prevImages,
					})
						.then(() => {
							setProperty((prev) => ({ ...prev, images: __prevImages }));
							alert("Image deleted.");
						})
						.catch((error) => {
							throw new Error(error);
						});
				} else {
					throw new Error("Failed");
				}
			} catch (error) {
				console.log(error);
				alert("Failed to delete image.");
			}
		} else return;
	};

	const [attachments, setAttachments] = useState([]);
	const deleteAttachmentFile = (index) => {
		const __attachments = [...attachments];
		__attachments.splice(index, 1);
		setAttachments([...__attachments]);
	};

	/**
	 * Delete the file from storage and firestore.
	 *
	 * @param {String} path Path of the file to delete.
	 * @param {Number} index Index of the file to delete.
	 */
	const deleteOldAttachmentFile = async (path, index) => {
		setUpdating(true);

		try {
			const deleted = (await deleteFiles([{ path }]))[0];
			if (deleted) {
				const files = [...property.files];
				files.splice(index, 1);
				await updateDoc(docRef, {
					files: [...files],
				});
				setProperty((prev) => ({ ...prev, files }));
				setUpdating(false);
			} else {
				throw new Error("Failed to delete file.");
			}
		} catch (error) {
			setUpdating(false);
			console.error(error);
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setUpdating(true);

		try {
			await getEditorData().then(async (desc) => {
				const data = { ...property, desc, loc, inspections, links };
				if (uris != null && uris.length > 0) {
					const images = await saveFiles(uris, COLLECTION_NAME);
					data.images = [...property.images, ...images];
				}

				if (attachments != null && typeof attachments === "object" && attachments.length > 0) {
					const files = await saveFiles(attachments, "files", true);
					if (property.hasOwnProperty("files") && typeof property.files[Symbol.iterator] === "function") {
						data.files = [...property.files, ...files];
					} else {
						data.files = [...files];
					}
				}

				const agentRefs = checkedValues.map((c) => {
					return doc(db, "teams", c);
				});
				data.agents = agentRefs;

				await updateDoc(docRef, {
					...data,
					updatedAt: Timestamp.now(),
				})
					.then(async () => {
						setUpdating(false);
						window.location.href = "/admin-dashboard/all-properties/" + id;
					})
					.catch((error) => {
						throw new Error(error);
					});
			});
		} catch (error) {
			console.error(error);
			setUpdating(false);
		}
	};

	useEffect(() => {
		if (property && Object.keys(property).length > 0) {
			setLoc({ ...property.loc });
			if (property.hasOwnProperty("inspections") && typeof property.inspections[Symbol.iterator] === "function") {
				setInspections([...property.inspections]);
			}

			if (property.hasOwnProperty("links") && typeof property.links[Symbol.iterator] === "function") {
				setLinks([...property.links]);
			}
			initCKEditor();
		} else return;
	}, [property]);

	const getProperty = async () => {
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			const agentRefs = docSnap.data().agents;
			let __agents = [];
			await Promise.all(
				agentRefs.map(async (agentRef) => {
					try {
						await getDoc(agentRef).then((agent) => {
							if (agent.exists()) {
								__agents.push({ id: agent.id, ...agent.data() });
							}
						});
					} catch (error) {
						__agents = [];
					}
				})
			);

			setProperty({ ...docSnap.data(), agents: __agents });
			setCheckedAgent(__agents);
		} else {
			setProperty({});
		}
	};

	const setCheckedAgent = async (a = []) => {
		const ags = [];
		await Promise.all(
			a.map(async (g) => {
				ags.push(g.id);
			})
		);
		setCheckedValues(ags);
	};

	const editorRef = useRef(null);
	const loadScript = (url, callback) => {
		const existingScript = document.getElementById("ckeditor-script");
		if (!existingScript) {
			const script = document.createElement("script");
			script.src = url;
			script.id = "ckeditor-script";
			document.body.appendChild(script);
			script.onload = () => {
				if (callback) callback();
			};
		} else if (existingScript && callback) {
			callback();
		}
	};

	const [agents, setAgents] = useState([]);
	const getAgents = async () => {
		const teamsRef = collection(db, "teams");
		await getDocs(teamsRef).then((res) => {
			if (res.empty) return;
			setAgents(res.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
		});
	};

	useEffect(() => {
		setTitle("Edit property details");
		getProperty();
		getAgents();

		loadScript("/lib/ckeditor/ckeditor.js");

		return () => {
			if (window.CKEDITOR) {
				for (let instance in window.CKEDITOR.instances) {
					window.CKEDITOR.instances[instance].destroy(true);
				}
				const script = document.getElementById("ckeditor-script");
				if (script) {
					document.body.removeChild(script);
				}
			}
		};
	}, []);

	const initCKEditor = () => {
		if (editorRef.current) {
			window.CKEDITOR.replace(editorRef.current);
		}
	};

	const getEditorData = () => {
		return new Promise((resolve) => {
			const content = window.CKEDITOR.instances[editorRef.current.getAttribute("id")].getData();
			resolve(content);
		});
	};

	return property ? (
		Object.keys(property).length > 0 ? (
			<div className="py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 space-y-10 duration-300">
				{/* Top App Bar */}
				<div className="flex items-center justify-between gap-10">
					<div className="flex flex-col items-start">
						<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
							Edit Property
						</h1>
					</div>
				</div>

				{/* Update property Form */}
				<form
					action={"#"}
					method="POST"
					onSubmit={handleSubmit}
					className="grid gap-6 tracking-tighter divide-y *:pt-6"
				>
					{/* Property Title */}
					<Title title={property.title} setProperty={setProperty} />

					{/* Property Subtitle */}
					<SubTitle title={property.subTitle} setProperty={setProperty} />

					{/* Location */}
					<Location loc={loc} setLoc={setLoc} update={true} />

					{/* Area of property */}
					<Area area={property.area} setProperty={setProperty} />

					{/* Land */}
					{property.land ? null : (
						<Fragment>
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ duration: 0.3 }}
								className="w-full grid grid-cols-1 xsm:grid-cols-2 sm:grid-cols-3 gap-8"
							>
								{/* Beds */}
								<Beds beds={property.beds} setProperty={setProperty} />

								{/* Baths */}
								<Baths tubs={property.tubs} setProperty={setProperty} />

								{/* Parkings */}
								<Parkings parkings={property.parkings} setProperty={setProperty} />
							</motion.div>

							<div className="w-full grid grid-cols-1 lxs:grid-cols-2 gap-8 duration-300">
								{/* Property Type */}
								<PropertyType type={property.ptype} setProperty={setProperty} />

								{/* Property Status */}
								<PropertyStatus status={property.pstatus} setProperty={setProperty} />
							</div>
						</Fragment>
					)}

					{/* For rental only */}
					{property.rental ? (
						<div className="w-full grid grid-cols-1 lxs:grid-cols-2 gap-8 duration-300">
							{/* Rental per week */}
							<RentalPerWeek rental={property.rental_per_week} setProperty={setProperty} />

							{/* Security bond */}
							<SecurityBond bond={property.security_bond} setProperty={setProperty} />
						</div>
					) : null}

					{/* Price of property */}
					<Price
						price={property.price}
						type={property.rental ?? property.sale ?? property.land}
						setProperty={setProperty}
					/>

					{/* Status */}
					<Status status={property.status} setProperty={setProperty} />

					{/* Property Images */}
					<Images images={property.images} handleFileInput={handleFileInput} deleteImage={deleteImage} />

					{/* Property Description */}
					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="desc"
							className="font-medium text-sm"
						>
							<span>Description</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-auto overflow-auto"
						>
							<div
								id="desc"
								ref={editorRef}
								contentEditable
								className="w-full h-full resize-y text-sm font-medium rounded-sm tracking-tighter outline-none py-2 px-4 border-2 focus-within:border-orange-500 focus:border-orange-500 bg-white placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
								dangerouslySetInnerHTML={{ __html: property.desc ?? "" }}
							></div>
						</motion.div>
					</div>

					{/* Inspection times */}
					<InspectionTime inspections={inspections} setInspections={setInspections} />

					{/* Attachment files */}
					<div className="flex flex-col gap-4">
						<div className="flex items-start justify-between gap-4">
							<div className="flex flex-col items-start">
								<h1 className="inline-block tracking-tighter font-bold text-sm tn:text-base sm:text-lg duration-300">
									Attachments
								</h1>

								<span className="text-sm text-gray-800">Upload files (.docx, .pdf)</span>
							</div>

							{/* Upload files button */}
							<label
								htmlFor="attachments"
								className="rounded-sm flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-blue-400 to-blue-600 hover:bg-gradient-to-tr text-xs text-white cursor-pointer hover:rounded-lg duration-300"
							>
								<span>Upload files</span>
							</label>
						</div>

						{/* Files */}
						<Attachments setAttachments={setAttachments} />

						{/* Old files */}
						{property.hasOwnProperty("files") &&
							typeof property.files[Symbol.iterator] === "function" &&
							property?.files.length > 0 && (
								<div className="w-full flex flex-col items-start gap-4">
									<div className="text-sm font-medium tracking-tighter">
										<span>Old files</span>
									</div>

									<div className="w-full max-h-[320px] overflow-auto grid divide-y border rounded-sm odd:*:bg-gray-50 hover:*:bg-gray-100 *:cursor-default *:px-4 *:py-2 *:flex *:items-center *:justify-between *:gap-4 *:w-full *:duration-300">
										{property?.files.map((file, index) => (
											<div className="group/file" key={index}>
												<div className="w-full flex items-start gap-2">
													<span className="relative top-1 text-xs text-gray-700">
														{index + 1}.
													</span>

													<div className="w-full flex flex-col items-start tracking-tighter">
														<div className="max-w-[70%] text-sm font-medium line-clamp-1 text-ellipsis group-hover/file:text-orange-500 duration-200">
															{file?.name ?? ""}
														</div>
														<span className="text-xs text-gray-700">
															{file?.size ?? ""}
														</span>
													</div>
												</div>

												<div
													onClick={() => {
														const confirmed = window.confirm(
															"Are you sure to delete this file?"
														);
														if (confirmed) {
															deleteOldAttachmentFile(file.path, index);
														}
													}}
													className="text-lg hover:text-red-500 duration-100 invisible group-hover/file:visible opacity-0 group-hover/file:opacity-100 !cursor-pointer hover:scale-110"
												>
													<FiX />
												</div>
											</div>
										))}
									</div>
								</div>
							)}

						{/* New added files */}
						{attachments.length > 0 && (
							<div className="w-full flex flex-col items-start gap-4">
								<div className="text-sm font-medium tracking-tighter">
									<span>New added files</span>
								</div>

								<div className="w-full max-h-[320px] overflow-auto grid divide-y border rounded-sm odd:*:bg-gray-50 hover:*:bg-gray-100 *:cursor-default *:px-4 *:py-2 *:flex *:items-center *:justify-between *:gap-4 *:w-full *:duration-300">
									{attachments.map((file, index) => (
										<div className="group/file" key={index}>
											<div className="w-full flex items-start gap-2">
												<span className="relative top-1 text-xs text-gray-700">
													{index + 1}.
												</span>

												<div className="w-full flex flex-col items-start tracking-tighter">
													<div className="max-w-[70%] text-sm font-medium line-clamp-1 text-ellipsis group-hover/file:text-orange-500 duration-200">
														{file?.name ?? ""}
													</div>
													<span className="text-xs text-gray-700">{file?.size ?? ""}</span>
												</div>
											</div>

											<div
												onClick={() => deleteAttachmentFile(index)}
												className="text-lg hover:text-red-500 duration-100 invisible group-hover/file:visible opacity-0 group-hover/file:opacity-100 !cursor-pointer hover:scale-110"
											>
												<FiX />
											</div>
										</div>
									))}
								</div>
							</div>
						)}
					</div>

					{/* Links */}
					<Links links={links} setLinks={setLinks} />

					{/* Agent */}
					<div className="space-y-10">
						<h1 className="inline-block tracking-tighter font-bold text-sm tn:text-base sm:text-lg duration-300">
							Contact
						</h1>

						<div className="w-full grid gap-2 justify-items-start">
							<motion.label
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3, ease: "easeInOut" }}
								htmlFor="agent"
								className="font-medium text-sm"
							>
								<span>Choose Agents</span>
							</motion.label>

							<motion.div
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3, ease: "easeInOut" }}
								className="w-full h-auto"
							>
								<div className="w-full h-auto max-h-40 rounded-sm border shadow-sm grid divide-y">
									{agents.map((agent, i) => (
										<label
											key={agent.id + i}
											htmlFor={"agent" + i}
											className="flex items-center gap-4 h-11 px-4 cursor-pointer hover:bg-gray-50 duration-300"
										>
											<input
												type="checkbox"
												id={"agent" + i}
												value={agent.id}
												onChange={handleCheckboxChange}
												required={checkedValues.length <= 0}
												defaultChecked={checkedValues.includes(agent.id)}
											/>

											<label htmlFor={"agent" + i} className="text-sm font-medium">
												{(agent.firstname ?? "") +
													" " +
													(agent.middlename ?? "") +
													" " +
													(agent.lastname ?? "")}
											</label>
										</label>
									))}
								</div>
							</motion.div>
						</div>
					</div>

					{/* Reset / Submit */}
					<div className="place-self-end flex items-center gap-6 flex-wrap">
						<button
							type="reset"
							className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-gray-300 to-gray-400 hover:bg-gradient-to-tr text-xs text-gray-700 font-semibold hover:text-black duration-300"
						>
							<span>Reset</span>
							<Icon icon="solar:close-square-outline" fontSize={16} />
						</button>

						<button
							type="submit"
							className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-orange-400 to-orange-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
						>
							<span>Update Property</span>
							<Icon icon="solar:add-square-bold" fontSize={16} />
						</button>
					</div>
				</form>
			</div>
		) : (
			<div className="w-full h-full p-10 flex items-center justify-center">
				<div className="grid justify-items-center gap-4">
					<div className="text-8xl text-orange-500">
						<Icon icon="solar:buildings-3-bold-duotone" />
					</div>

					<span className="font-medium text-lg tracking-tighter">Property not found.</span>
				</div>
			</div>
		)
	) : (
		<div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
			<div className="grid text-center justify-items-center gap-4">
				<div className="text-6xl text-indigo-500 animate-spin">
					<Icon icon="solar:black-hole-line-duotone" />
				</div>

				<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
					Loading property details...
				</h1>
			</div>
		</div>
	);
}
