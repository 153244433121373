import React from "react";

export default function AboutUs() {
	return (
		<div
			className="w-full px-2 py-12 xs:py-16 sm:py-28 md:py-40 bg-sky-50/50 duration-300"
			id="aboutUs"
		>
			<div className="w-full md:w-11/12 xl:w-10/12 mx-auto duration-200">
				<div className="flex flex-col-reverse md:flex-row items-center gap-10 md:gap-0 duration-300">
					<div className="w-full sm:w-8/12 md:w-1/2 flex flex-col items-start gap-10 duration-300">
						<div className="tracking-tighter space-y-1">
							<h1 className="font-bold text-5xl duration-300">About Us</h1>

							<h6 className="text-lg text-gray-500">
								Bringing homes and hearts together
							</h6>
						</div>

						<div className="w-full text-lg tracking-tight text-pretty">
							Krish Real Estate offers full extensive real estate services including
							residential sales and re sales of properties direct from owners or
							developers, property management and investment opportunities. We are a
							boutique Agency set up to service everyone with a difference, by
							bringing back the traditional values and customer service, all while
							moving forward with the times by utilizing the latest technology.
						</div>
					</div>

					<div className="w-full sm:w-8/12 md:w-1/2 flex justify-end duration-300">
						<div className="w-full md:w-11/12 mlg:w-9/12 aspect-[4/2] md:aspect-square rounded-3xl overflow-hidden duration-300">
							<img
								src="https://images.unsplash.com/photo-1600596542815-ffad4c1539a9?q=80&w=1475&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
								alt="about-us"
								className="relative w-full h-full object-cover"
							/>
						</div>
					</div>
				</div>

				<div className="hidden justify-items-start gap-60">
					<div className="grid gap-4 justify-items-start">
						<div className="flex items-center gap-4">
							<h1 className="text-4xl font-bold tracking-tight">Why choose us?</h1>

							<div className="w-24 h-[1px] bg-gray-300"></div>
						</div>

						<div className="text-base text-pretty tracking-noraml leading-relaxed">
							Krish Real Estate offers full extensive Real Estate services including
							residential sales and re sales of properties direct from owners or
							developers, property management and investment opportunities. At Krish
							Real Estate, we understand that buying, selling, and renting out a
							property can be a challenging and time-consuming process. We are
							dedicated to providing seamless and stress-free experience to all our
							clients, also meets their unique needs and requirements. We are a
							boutique Agency set up to service everyone with a difference, by
							bringing back the traditional values and customer service, all while
							moving forward with the times by utilizing the latest technology.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
