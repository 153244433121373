import { Icon } from "@iconify/react";
import { FiX } from "react-icons/fi";
import { Fragment, useEffect, useState } from "react";
import { useDashboardContext } from "../../../dashboardContext";
import { doc, updateDoc } from "firebase/firestore";
import db from "../../../../firebase-config";
import { deleteFiles, saveFiles } from "../../utils";
import { COLLECTION_NAME } from "..";

const keys = {
	LEFT: 37,
	RIGHT: 39,
	UP: 38,
	DOWM: 40,
};
export default function Images({ id, images = [], addedFromXML = false, closeModal }) {
	const { setUpdating } = useDashboardContext();
	const docRef = doc(db, COLLECTION_NAME, id);

	const [tempImages, setTempImages] = useState([...images]);
	const [files, setFiles] = useState([...images]);

	const [currClicked, setCurrClicked] = useState({
		order: null,
		key: null,
		index: null,
	});

	const handleKeyDownEvent = (event) => {
		const { keyCode } = event;

		switch (keyCode) {
			case keys.LEFT:
				if (currClicked.index <= 0) {
					return;
				} else {
					const currIndex = currClicked.index;
					const prevIndex = currIndex - 1;

					const currBox = document.getElementById(`propertyImageBox${currIndex}`);
					const prevBox = document.getElementById(`propertyImageBox${prevIndex}`);

					const temp = currBox.innerHTML;
					currBox.innerHTML = prevBox.innerHTML;
					prevBox.innerHTML = temp;

					const tempImage = tempImages[currIndex];
					tempImages[currIndex] = tempImages[prevIndex];
					tempImages[prevIndex] = tempImage;

					setCurrClicked((prev) => ({
						...prev,
						index: prevIndex,
					}));
				}
				break;
			case keys.RIGHT:
				if (currClicked.index >= tempImages.length - 1) {
					return;
				} else {
					const currIndex = currClicked.index;
					const nextIndex = currIndex + 1;

					const currBox = document.getElementById(`propertyImageBox${currIndex}`);
					const nextBox = document.getElementById(`propertyImageBox${nextIndex}`);

					const temp = currBox.innerHTML;
					currBox.innerHTML = nextBox.innerHTML;
					nextBox.innerHTML = temp;

					const tempImage = tempImages[currIndex];
					tempImages[currIndex] = tempImages[nextIndex];
					tempImages[nextIndex] = tempImage;

					setCurrClicked((prev) => ({
						...prev,
						index: nextIndex,
					}));
				}
				break;

			default:
				break;
		}
	};

	useEffect(() => {
		if (currClicked.key != null) {
			document.addEventListener("keydown", handleKeyDownEvent);
		} else {
			document.removeEventListener("keydown", handleKeyDownEvent);
		}

		return () => {
			document.removeEventListener("keydown", handleKeyDownEvent);
		};
	}, [currClicked]);

	const [uris, setUris] = useState([]);
	const handleFileInput = (event) => {
		const files = event.target.files ?? [];
		if (files && files.length > 0) {
			if (files.length <= 10) {
				setUris([]);
				for (let i = 0; i < files.length; i++) {
					const file = files[i];
					const ext = file.name.substr(file.name.lastIndexOf(".") + 1);

					// Skip large files > 5MB
					if (file.size > 5120000) {
						continue;
					}

					const reader = new FileReader();
					reader.onloadend = (e) => {
						setUris((prev) => [...prev, { uri: e.target.result, ext }]);
					};
					reader.readAsDataURL(file);
				}
			} else {
				alert("Select files less than or equal to 10");
			}
		}
	};

	const updateImages = async () => {
		setUpdating(true);

		try {
			let newImages = [];
			if (uris.length > 0) {
				newImages = await saveFiles(uris, COLLECTION_NAME);
			}

			const combinedImages = [...tempImages, ...newImages];
			await updateDoc(docRef, {
				images: [...combinedImages],
			})
				.then(() => {
					setFiles([...combinedImages]);
					setTempImages([...combinedImages]);
					setUris([]);
					setUpdating(false);
				})
				.catch((error) => {
					throw new Error(error);
				});
		} catch (error) {
			setUris([]);
			console.error(error);
			setUpdating(false);
		}
	};

	/** Delete property image */
	const deleteImage = async (path, index) => {
		const confirmed = window.confirm("Are you sure to delete?");
		if (confirmed) {
			try {
				const deleted = (await deleteFiles([{ path }]))[0];
				if (deleted) {
					const __prevImages = files;
					__prevImages.splice(index, 1);
					await updateDoc(docRef, {
						images: __prevImages,
					})
						.then(() => {
							setFiles([...__prevImages]);
							setTempImages([...__prevImages]);
						})
						.catch((error) => {
							throw new Error(error);
						});
				} else {
					throw new Error("Failed");
				}
			} catch (error) {
				console.log(error);
				alert("Failed to delete image.");
			}
		} else return;
	};

	return (
		<div className="relative z-20 mx-auto flex flex-col rounded border bg-white overflow-auto w-full sm:w-10/12 md:w-8/12 lg:w-7/12 tracking-tighter *:w-full">
			<div className="flex items-center justify-between gap-4 px-4 py-4 border-b">
				<h1 className="text-lg font-semibold inline-flex gap-2 items-center">
					<span className="font-bold text-blue-600">{files.length}</span> Property Images
				</h1>

				<div
					role="button"
					onClick={closeModal}
					className="w-6 h-6 min-w-[24px] min-h-[24px] flex items-center justify-center rounded-full border bg-red-500 text-white text-base hover:scale-110 cursor-pointer duration-300"
				>
					<FiX />
				</div>
			</div>

			<div className="w-full flex flex-col items-start gap-4 p-4">
				<span className="text-sm text-gray-700">Use arrow keys (➡️⬆️⬅️⬇️) to move and order the images.</span>

				<div className="w-full grid grid-cols-4 gap-4 row-auto *:w-full *:aspect-square *:border-2 *:rounded-sm duration-300">
					{files.map((image, index) => (
						<div
							key={index}
							id={"propertyImageBox" + index}
							onClick={(event) => {
								if (addedFromXML) {
									return;
								}
								const id = event.currentTarget.id;
								if (currClicked.key === id) {
									setCurrClicked({
										order: null,
										key: null,
										index: null,
									});
								} else {
									setCurrClicked({
										order: image.order,
										key: id,
										index: index,
									});
								}
							}}
							className={
								"relative cursor-pointer overflow-hidden flex items-center justify-center group/image duration-300 " +
								(currClicked.index === index ? "border-orange-600" : "hover:border-orange-400")
							}
						>
							<img
								src={image.url ?? "/static/images/placeholder.jpg"}
								alt="property"
								className="w-full h-full object-cover"
							/>

							{/* Absolute delete image icon */}
							{addedFromXML ? null : (
								<div
									role="button"
									onClick={() => {
										if (addedFromXML) {
											return;
										}

										deleteImage(image.path, index);
									}}
									className="absolute bottom-2 z-10 w-10 h-10 rounded-full bg-black bg-opacity-30 hover:bg-opacity-60 flex items-center justify-center text-gray-200 text-2xl hover:text-white cursor-pointer scale-0 opacity-0 group-hover/image:scale-100 group-hover/image:opacity-100 duration-300"
								>
									<Icon icon="solar:trash-bin-minimalistic-bold" />
								</div>
							)}
						</div>
					))}
				</div>

				{addedFromXML ? null : (
					<div className="w-full flex items-center justify-between gap-4">
						{/* Multiple file uploads */}
						<input
							type="file"
							id="uploadFiles"
							multiple
							accept="image/x-png, image/jpeg, image/webp, image/gif"
							onChange={handleFileInput}
							className="hidden"
						/>

						<div className="flex items-center gap-4">
							<label
								htmlFor="uploadFiles"
								role="button"
								className="rounded-sm flex items-center justify-center gap-2 px-6 h-9 bg-gradient-to-br from-blue-400 to-blue-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
							>
								<span>Add</span>
							</label>

							{uris.length > 0 && (
								<span className="text-sm font-medium">{uris.length} images selected.</span>
							)}
						</div>
						<button
							type="button"
							onClick={updateImages}
							className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-6 h-9 bg-gradient-to-br from-orange-400 to-orange-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
						>
							<span>Update</span>
						</button>
					</div>
				)}
			</div>
		</div>
	);
}
