import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import Property from "../utils/property";
import { fetchProperties } from "../../../lib";

export default function Featured() {
	const [properties, setProperties] = useState(null);

	const getProperties = async () => {
		await fetchProperties(8)
			.then((res) => {
				setProperties([...res]);
			})
			.catch((error) => {
				setProperties([]);
			});
	};

	useEffect(() => {
		getProperties();
	}, []);

	return (
		<div className="w-full px-2 py-6 xs:py-10 lxs:py-14 md:py-20 bg-orange-50/30 duration-300">
			<div className="w-full md:w-11/12 xl:w-10/12 mx-auto space-y-6 xs:space-y-10 lxs:space-y-14 md:space-y-20 grid duration-300">
				{/* Top heading */}
				<div className="flex items-center justify-between gap-4 lxs:gap-12 duration-300">
					<h1 className="font-bold inline-flex gap-4 items-center duration-200">
						<span className="tracking-tighter text-base tn:text-lg sm:text-3xl duration-300">
							Featured properties
						</span>

						{properties && properties.length > 0 ? (
							<div className="hidden lxs:inline-flex gap-4 items-center">
								<div className="w-16 h-[1px] bg-gray-200"></div>

								<span className="text-base font-semibold text-orange-600">{properties.length}</span>
							</div>
						) : null}
					</h1>

					{/* <a
						href={"/properties"}
						className="text-xs tn:text-xsm sm:text-lsm tracking-tighter text-orange-600 font-medium hover:text-white bg-gradient-to-br hover:from-orange-600 hover:to-yellow-400 rounded-full px-2 sm:px-4 py-2 duration-200 flex items-center gap-2"
					>
						<span>View all properties</span>
						<Icon icon="solar:arrow-right-outline" />
					</a> */}
				</div>

				{/* Grids | Properties | Contents */}
				<div className="grid grid-cols-1 xsm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8 row-auto">
					{properties ? (
						properties.map((property, index) => (
							<Property key={index} property={property} delay={0.1 * index} />
						))
					) : (
						<div className="w-full p-20 bg-orange-50 rounded-xl flex items-center justify-center">
							<div className="grid text-center justify-items-center gap-4">
								<div className="text-6xl text-indigo-500 animate-spin">
									<Icon icon="solar:black-hole-line-duotone" />
								</div>

								<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
									Loading properties...
								</h1>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
