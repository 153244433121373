import React from "react";
import { motion } from "framer-motion";
import { IoBedOutline, IoCarOutline } from "react-icons/io5";
import { LuBath } from "react-icons/lu";

export default function Property({ property, delay }) {
	let option = property.status.replace("_", " ").toLowerCase();
	option = option.charAt(0).toUpperCase() + option.slice(1, option.length);

	return (
		<motion.div
			initial={{ opacity: 0, scale: 0.9 }}
			animate={{ opacity: 1, scale: 1 }}
			transition={{ duration: 0.3, delay }}
			className="relative w-full h-auto group/property"
		>
			{property.status === "ACTIVE" ? null : (
				<div className="absolute z-20 left-1 top-1 rounded-sm bg-orange-500 text-white text-xs font-light px-2 py-[2px]">
					<span>{option}</span>
				</div>
			)}

			<div className="w-full h-auto rounded border border-gray-100 hover:border-gray-200 hover:bg-slate-50/50 duration-200 overflow-hidden bg-white">
				<a
					href={
						(property.sale
							? "/properties-for-sale/"
							: property.rental
							? "/properties-for-rent/"
							: "/land-for-sale/") +
						property.id +
						"?query=" +
						property.title
					}
					className="w-full"
				>
					<img
						src={property.images[0].url ?? "/static/images/placeholder.jpg"}
						alt={property.title ?? "Property"}
						className="w-full aspect-[2/1] object-cover border-b transition-all group-hover/property:scale-105 duration-300"
					/>
				</a>

				{/* Property contents */}
				<div className="p-4 grid gap-4 justify-items-start">
					<div className="block space-y-4 text-left">
						<div className="flex flex-col items-start tracking-tight">
							<a
								href={
									(property.sale
										? "/properties-for-sale/"
										: property.rental
										? "/properties-for-rent/"
										: "/land-for-sale/") +
									property.id +
									(property.addedFromXML ? "?read-from-xml=true" : "?title=" + property.title)
								}
								className="text-base font-bold line-clamp-3 text-ellipsis text-pretty group-hover/property:text-indigo-600 duration-200"
							>
								{property.title ?? "Unknown"}
							</a>
						</div>

						<div className="flex flex-col items-start text-xsm font-medium">
							{/* <span className="text-gray-800">4, 2.5 BHK Apartments</span> */}
							<a
								href="https://www.google.com/maps"
								rel="noreferrer"
								target="_blank"
								className="text-gray-500"
							>
								{property.loc.name ?? ""}
							</a>
						</div>
					</div>

					<div className="flex items-center gap-4 *:cursor-default *:flex *:items-center *:gap-1 *:text-xsm *:font-semibold *:py-1 *:px-1.5 *:rounded-full">
						<div className="duration-200 hover:bg-slate-100 hover:text-indigo-700">
							<IoBedOutline size={18} />
							<span>10</span>
						</div>

						<div className="duration-200 hover:bg-rose-100 hover:text-rose-600">
							<LuBath size={18} />
							<span>4</span>
						</div>

						<div className="duration-200 hover:bg-green-100 hover:text-green-600">
							<IoCarOutline size={18} />
							<span>5</span>
						</div>
					</div>
				</div>
			</div>
		</motion.div>
	);
}
