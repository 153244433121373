import React from "react";
import { useLocation } from "react-router-dom";

export default function NavItem({ to = "/admin-dashboard", title = "Dashboard", children }) {
	const { pathname } = useLocation();

	return (
		<a
			href={to}
			className={
				"text-sm " +
				(pathname === to
					? "font-semibold text-black bg-white border-orange-500"
					: "font-medium border-transparent text-gray-700 hover:text-black bg-transparent hover:bg-white")
			}
		>
			<div className="flex items-center gap-4">
				{children}
				<span>{title}</span>
			</div>
		</a>
	);
}
