import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useDashboardContext } from "../../../dashboardContext";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import db from "../../../../firebase-config";
import moment from "moment";
import { deleteRequest } from "./actions";
import ViewSellRequest from "./view";

export default function SellPropertyRequests() {
	const { setTitle } = useDashboardContext();
	const [requests, setRequests] = useState(null);

	const { pathname } = useLocation();

	const ref = collection(db, "sell-property-requests");

	const getAppraisals = async () => {
		try {
			const q = query(ref, orderBy("sentAt", "desc"), limit(100));
			const docsSnap = await getDocs(q);
			if (docsSnap.empty) {
				setRequests([]);
			} else {
				setRequests(docsSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
			}
		} catch (error) {
			setRequests({});
		}
	};

	useEffect(() => {
		getAppraisals();
		setTitle("Sell Property Requests");
	}, []);

	return requests ? (
		<div className="h-screen py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 flex flex-col space-y-10 duration-300">
			{/* Top App Bar */}
			<div className="w-full flex items-center justify-between gap-10">
				<div className="flex flex-col items-start">
					<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
						Sell Property Requests
					</h1>

					{requests.length > 0 && (
						<div className="text-sm tracking-tight text-gray-600">
							Total <span className="font-semibold">{requests.length}</span> requests found.
						</div>
					)}
				</div>
			</div>

			{requests.length > 0 ? (
				<div className="w-full h-auto overflow-auto">
					<table className="w-full h-full min-w-max overflow-auto border-collapse">
						<thead className="w-full text-sm tracking-tighter border-b">
							<tr className="text-left *:px-4">
								<th className="py-2">SN</th>
								<th>Full Name</th>
								<th>Email Address</th>
								<th>Mobile</th>
								<th>Property Address</th>
								<th>Property Type</th>
								<th>Property Status</th>
								<th>Property Description</th>
								<th>Request Received At</th>
								<th>Actions</th>
							</tr>
						</thead>

						<tbody>
							{requests.map((request, index) => (
								<tr
									key={index}
									className={
										"text-left text-xsm hover:bg-gray-100 even:bg-gray-50 *:px-4 " +
										(request.viewed ? "" : "bg-green-50")
									}
								>
									<td align="middle">
										<div
											className={
												"w-6 h-6 rounded-full flex items-center justify-center " +
												(request.viewed ? "" : "bg-green-500 text-white")
											}
										>
											<span className="font-medium text-sm">{index + 1}</span>
										</div>
									</td>

									<td className="py-4 font-semibold">
										<a href={pathname + "/" + request.id} className="hover:text-blue-600">
											{request.fullname ?? ""}
										</a>
									</td>
									<td>
										<a href={"mailTo:" + (request.email ?? "")} className="font-semibold min-w-max">
											{request.email ?? ""}
										</a>
									</td>
									<td className="font-medium">
										<a href={"tel:" + (request.mobile ?? "")} className="min-w-max">
											{request.mobile ?? ""}
										</a>
									</td>
									<td className="font-semibold">
										<div className="line-clamp-1 text-ellipsis max-w-72">
											{request.address ?? ""}
										</div>
									</td>
									<td className="font-semibold text-gray-700">{request.type ?? ""}</td>
									<td className="font-semibold text-gray-700">{request.status ?? ""}</td>
									<td>
										<div className="line-clamp-1 text-ellipsis max-w-96 font-medium">
											{request.description ?? ""}
										</div>
									</td>
									<td>
										<div className="min-w-max font-medium">
											{moment(request.sentAt.seconds * 1000).format("MMM DD, hh:mm A")}
										</div>
									</td>
									<td>
										<div className="flex items-center gap-4 text-gray-800 *:duration-300 *:text-2xl">
											<div
												role="button"
												onClick={() => deleteRequest(request.id)}
												className="hover:text-red-600 cursor-pointer"
												title="Delete request"
											>
												<Icon icon="solar:trash-bin-minimalistic-bold-duotone" />
											</div>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : (
				<div className="flex-grow p-20 rounded-xl bg-red-50 flex items-center justify-center">
					<div className="grid text-center justify-items-center gap-4">
						<div className="text-8xl text-red-500">
							<Icon icon="solar:users-group-rounded-bold-duotone" />
						</div>

						<div className="font-medium text-lg tracking-tighter">
							<span>Couldn't found any requests.</span>
						</div>
					</div>
				</div>
			)}
		</div>
	) : (
		<div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
			<div className="grid text-center justify-items-center gap-4">
				<div className="text-6xl text-indigo-500 animate-spin">
					<Icon icon="solar:black-hole-line-duotone" />
				</div>

				<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
					Loading requests...
				</h1>
			</div>
		</div>
	);
}

export { ViewSellRequest };
