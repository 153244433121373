import React, { Fragment, useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import { doc, getDoc } from "firebase/firestore";
import db from "../../../firebase-config";
import SlideShow from "./SlideShow";
import moment from "moment";
import { FiDownloadCloud } from "react-icons/fi";
import { MdArrowOutward } from "react-icons/md";
import { readXMLFiles } from "../../../admin/pages/properties/actions";

export default function PropertyDetails() {
	const mapContainerRef = useRef(null);
	const [map, setMap] = useState(null);

	const [property, setProperty] = useState(null);

	const { id } = useParams();
	const [params, setParams] = useSearchParams();

	const getProperty = async () => {
		if (params.has("read-from-xml") && params.get("read-from-xml")) {
			let xmlResults = await readXMLFiles();
			xmlResults = xmlResults.filter((p) => p.id === id)[0];

			setProperty({ ...xmlResults });
		} else {
			const ref = doc(db, "properties", id);
			await getDoc(ref)
				.then(async (res) => {
					if (res.exists() && res.data().status) {
						const agentRefs = res.data().agents;
						let agents = [];
						await Promise.all(
							agentRefs.map(async (agentRef) => {
								try {
									await getDoc(agentRef).then((agent) => {
										if (agent.exists()) {
											agents.push({ id: agent.id, ...agent.data() });
										}
									});
								} catch (error) {
									agents = [];
								}
							})
						);

						setProperty({ id: res.id, ...res.data(), agents });
					} else {
						setProperty({});
					}
				})
				.catch(() => setProperty({}));
		}
	};

	const loadScript = (url, callback) => {
		const existingScript = document.getElementById("leaflet-script");
		if (!existingScript) {
			const script = document.createElement("script");
			script.src = url;
			// script.integrity = "sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo=";
			// script.crossOrigin = "";
			script.id = "leaflet-script";
			document.body.appendChild(script);
			script.onload = () => {
				if (callback) callback();
			};
		} else if (existingScript && callback) {
			return callback();
		}
	};

	const initMap = () => {
		if (window.L) {
			const mapContainer = document.getElementById("propertyMap");
			if (map) return;
			if (mapContainer) {
				var __map = window.L.map(mapContainer).setView([property.loc.lat, property.loc.long], 13);
				window.L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
					maxZoom: 19,
				}).addTo(__map);

				var marker = window.L.marker([property.loc.lat, property.loc.long]).addTo(__map);
				marker
					.bindPopup(
						`<div class="line-clamp-2 font-semibold text-ellipsis tracking-tighter text-sm">${
							property.title ?? "Unknown"
						}</div><span class="tracking-tighter text-xsm">${
							(property.loc.name ?? "") +
							(property.loc.name && property.loc.city ? " , " : "") +
							(property.loc.city ?? "")
						}</span>`
					)
					.openPopup();

				setMap(__map);
			} else return;
		}
	};

	useEffect(() => {
		if (property && Object.keys(property).length > 0) {
			// Also only initialize the map if lat, long are available.
			if (property.loc.lat && property.loc.long) {
				initMap();
			}
		}
	}, [property]);

	useEffect(() => {
		getProperty();
	}, [id]);

	useEffect(() => {
		loadScript("/lib/leaflet.js");

		return () => {
			const script = document.getElementById("leaflet-script");
			if (script) {
				document.body.removeChild(script);
			}
		};
	}, []);

	return (
		<motion.div initial={{ opacity: 0, y: 6 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }}>
			<div className="w-full px-2 duration-300">
				{property ? (
					Object.keys(property).length > 0 ? (
						<div className="w-full md:w-11/12 xl:w-10/12 mx-auto py-8 space-y-8 duration-200">
							<div className="w-full flex flex-col slg:flex-row gap-10 duration-300">
								{/* Left side content */}
								<div className="w-full slg:w-7/12 mlg:w-8/12 space-y-10 duration-300">
									{property.images.length > 1 ? (
										<SlideShow images={property.images} title={property.title} />
									) : (
										<div className="w-full flex flex-col" draggable={false}>
											<div className="w-full aspect-[5/3] rounded-t border border-gray-100 bg-white overflow-hidden duration-300">
												<div className="relative w-full h-full flex items-center justify-center overflow-hidden">
													<img
														src={property.images[0].url}
														alt={property.title}
														className="w-full h-full object-cover"
														draggable={false}
													/>
												</div>
											</div>
										</div>
									)}

									<div className="w-full flex items-start justify-between gap-8">
										<div className="relative flex flex-col items-start gap-2">
											<div className="px-2 py-0.5 rounded-full bg-gray-100 font-medium text-xsm text-gray-800">
												<span>Added {moment(property.createdAt.seconds * 1000).fromNow()}</span>
											</div>

											<div className="flex flex-col items-start">
												<h1 className="text-4xl font-bold leading-tight tracking-tighter duration-300">
													{property.title ?? "Unknown"}
												</h1>

												<h3 className="text-xl font-medium leading-tight text-ellipsis text-gray-500">
													{property.subTitle ?? ""}
												</h3>
											</div>

											<h3 className="text-2xl font-semibold tracking-tighter text-gray-700 duration-300">
												{property.loc.name ?? ""}
												{property.loc.name && property.loc.city ? " , " : ""}
												{property.loc.city ?? ""}
											</h3>
										</div>

										{/* For rental property only */}
										{property.rental ? (
											<div className="flex flex-col items-start text-xl font-bold leading-tight tracking-tighter gap-2">
												<span>{property?.rental_per_week ?? "$ 0.0 "} per week</span>

												<span>Bond {property?.rental_per_week ?? "$ 0.0 "}</span>
											</div>
										) : null}

										{/* <div className="flex items-center gap-4 min-w-max">
											<div className="border hover:border-gray-300 rounded-full overflow-hidden w-9 h-9 flex items-center justify-center text-xl hover:text-rose-500 cursor-pointer duration-300">
												<Icon icon="solar:heart-outline" />
											</div>

											<div className="border hover:border-gray-300 rounded-full overflow-hidden w-9 h-9 flex items-center justify-center text-xl hover:text-blue-700 cursor-pointer duration-300">
												<Icon icon="solar:upload-minimalistic-outline" />
											</div>
										</div> */}
									</div>

									{property.hasOwnProperty("inspections") &&
										typeof property.inspections[Symbol.iterator] === "function" &&
										property.inspections.length > 0 && (
											<div className="w-full flex flex-col items-start gap-4">
												<h1 className="text-base font-medium tracking-tighter">
													Inspection Times
												</h1>

												<div className="w-full max-h-[320px] overflow-auto grid divide-y border rounded-sm odd:*:bg-gray-50 hover:*:bg-gray-100 *:px-4 *:py-2 *:flex *:items-center *:justify-between *:gap-4 *:w-full *:duration-300">
													{property.inspections.map((inspection, index) => (
														<div className="group/file" key={index}>
															<h3 className="w-full text-base text-gray-700 tracking-tighter flex items-center justify-between gap-4">
																<span>
																	{moment(inspection?.date).format("dddd , DD MMM")}{" "}
																</span>
																<span>
																	{moment(inspection?.start_time, "HH:mm").format(
																		"hh:mm a"
																	)}

																	{" - "}

																	{moment(inspection?.end_time, "HH:mm").format(
																		"hh:mm a"
																	)}
																</span>
															</h3>
														</div>
													))}
												</div>
											</div>
										)}

									<div className="border border-gray-100 bg-gray-50/50 rounded-sm p-4 duration-300">
										{property?.land ? (
											<div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-10">
												<div className="w-full flex items-center justify-between gap-4 py-4 border-b">
													<span className="font-semibold text-xsm text-gray-800">
														Listing type
													</span>
													<span className="font-semibold text-sm">
														{property.rental ? "Rent" : property.sale ? "Sale" : "Land"}
													</span>
												</div>

												<div className="w-full flex items-center justify-between gap-4 py-4 border-b">
													<span className="font-semibold text-xsm text-gray-800">
														Property type
													</span>
													<span className="font-semibold text-sm">
														{property.ptype ?? ""}
													</span>
												</div>
											</div>
										) : (
											<div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-10">
												<div className="w-full flex items-center justify-between gap-4 py-4 border-b">
													<span className="font-semibold text-xsm text-gray-800">
														Bedrooms
													</span>
													<span className="font-semibold text-sm">{property.beds}</span>
												</div>

												<div className="w-full flex items-center justify-between gap-4 py-4 border-b">
													<span className="font-semibold text-xsm text-gray-800">
														Bathrooms
													</span>
													<span className="font-semibold text-sm">{property.tubs}</span>
												</div>
											</div>
										)}

										<div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-10">
											{property?.land ? null : (
												<Fragment>
													<div className="w-full flex items-center justify-between gap-4 py-4 border-b">
														<span className="font-semibold text-xsm text-gray-800">
															Car Parkings
														</span>
														<span className="font-semibold text-sm">
															{property.parkings}
														</span>
													</div>

													<div className="w-full flex items-center justify-between gap-4 py-4 border-b">
														<span className="font-semibold text-xsm text-gray-800">
															Listing type
														</span>
														<span className="font-semibold text-sm">
															{property.rental ? "Rent" : property.sale ? "Sale" : "Land"}
														</span>
													</div>
												</Fragment>
											)}
										</div>

										{property?.land ? null : (
											<div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-10">
												<div className="w-full flex items-center justify-between gap-4 py-4">
													<span className="font-semibold text-xsm text-gray-800">
														Property type
													</span>
													<span className="font-semibold text-sm">{property.ptype}</span>
												</div>
											</div>
										)}

										<div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-10">
											{/* Area of property */}
											<div className="w-full flex items-center justify-between gap-4 py-4">
												<span className="font-semibold text-xsm text-gray-800">
													Price of property
												</span>
												<span className="font-semibold text-sm">{property.price ?? ""}</span>
											</div>

											{/* Price of property */}
											<div className="w-full flex items-center justify-between gap-4 py-4">
												<span className="font-semibold text-xsm text-gray-800">Area</span>
												<span className="font-semibold text-sm">{property.area ?? ""}</span>
											</div>
										</div>
									</div>

									{property.files && property.files.length > 0 && (
										<div className="w-full flex flex-col items-start gap-4">
											<h1 className="text-base font-medium tracking-tighter">Attachment files</h1>

											<div className="w-full max-h-[320px] overflow-auto grid divide-y border rounded-sm odd:*:bg-gray-50 hover:*:bg-gray-100 *:px-4 *:py-2 *:flex *:items-center *:justify-between *:gap-4 *:w-full *:duration-300">
												{property.files.map((file, index) => (
													<div className="group/file" key={index}>
														<a
															href={file.url}
															target="_blank"
															rel="noreferrer"
															className="w-full"
														>
															<div className="w-full flex items-start gap-2">
																<span className="relative top-1 text-xs text-gray-700">
																	{index + 1}.
																</span>

																<div className="w-full flex flex-col items-start tracking-tighter">
																	<div className="text-sm font-medium line-clamp-1 text-ellipsis group-hover/file:text-orange-500 duration-200">
																		{file?.name ?? ""}
																	</div>
																	<span className="text-xs text-gray-700">
																		{file?.size ?? ""}
																	</span>
																</div>
															</div>
														</a>

														<a
															href={file.url ?? ""}
															download={true}
															className="text-lg hover:text-red-500 duration-100 invisible group-hover/file:visible opacity-0 group-hover/file:opacity-100 hover:scale-110"
														>
															<FiDownloadCloud />
														</a>
													</div>
												))}
											</div>
										</div>
									)}

									<div
										className="text-left text-pretty leading-relaxed text-sm text-gray-800"
										dangerouslySetInnerHTML={{ __html: property?.desc ?? "" }}
									></div>

									{/* Links */}
									{property.hasOwnProperty("links") &&
										typeof property.links[Symbol.iterator] === "function" &&
										property.links.length > 0 && (
											<div className="w-full flex flex-col items-start gap-4">
												<h1 className="text-base font-medium tracking-tighter">
													External Links
												</h1>

												<div className="w-full max-h-[320px] overflow-auto grid divide-y border rounded-sm odd:*:bg-gray-50 hover:*:bg-gray-100 *:px-4 *:py-2 *:flex *:items-center *:justify-between *:gap-4 *:w-full *:duration-300">
													{property.links.map((link, index) =>
														link?.url.length > 0 ? (
															<div className="group/link" key={index}>
																<a
																	href={link?.url}
																	target="_blank"
																	rel="noreferrer"
																	className="w-full"
																>
																	<div className="text-sm font-medium line-clamp-1 text-ellipsis group-hover/link:text-orange-500 tracking-tighter duration-200">
																		{link?.title ?? link?.url}
																	</div>
																</a>

																<a
																	href={link?.url ?? ""}
																	download={true}
																	className="text-lg hover:text-red-500 duration-100 hover:scale-110"
																>
																	<MdArrowOutward />
																</a>
															</div>
														) : null
													)}
												</div>
											</div>
										)}
								</div>

								{/* Right Side Content */}
								<div className="w-full slg:w-5/12 mlg:w-4/12 h-auto flex flex-col items-start gap-6 overflow-hidden duration-300">
									<div className="w-full flex flex-col items-start gap-2">
										<div className="tracking-tighter">
											<h3 className="text-base font-semibold uppercase">Location</h3>
										</div>

										<div className="w-full flex items-center justify-between gap-4 rounded border overflow-hidden p-4 duration-300">
											<div className="grid justify-items-start tracking-tighter">
												<span className="text-base font-semibold">
													{property.loc.name ?? ""}
												</span>
												<span className="text-gray-600 text-sm font-medium">
													{property.loc.city ?? ""}
												</span>
											</div>

											{property.loc.url && (
												<a
													href={property.loc.url}
													target="_blank"
													rel="noreferrer"
													title={
														(property.loc.name ?? "") +
														(property.loc.name && property.loc.city ? " , " : "") +
														(property.loc.city ?? "")
													}
												>
													<div className="min-w-max w-9 h-9 rounded-full flex items-center justify-center text-xl bg-orange-50 hover:bg-orange-200 text-orange-500 hover:text-orange-600 group duration-300">
														<Icon
															icon="solar:map-arrow-up-outline"
															className="rotate-45 stroke-current stroke-[0px] group-hover:stroke-[1px]"
														/>
													</div>
												</a>
											)}
										</div>

										{property.loc.lat && property.loc.long ? (
											<div
												id="propertyMap"
												ref={mapContainerRef}
												className="w-full h-96 border rounded overflow-hidden"
											>
												{/* <iframe
													src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3085.437518752404!2d85.31840853348248!3d27.718071604503514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb191b0080f92b%3A0x3d08c08a7f53eace!2sRadisson%20Hotel%20Kathmandu!5e0!3m2!1sen!2snp!4v1711557863641!5m2!1sen!2snp"
													allowFullScreen=""
													loading="lazy"
													title="Location Map"
													className="border-0 w-full h-full"
													referrerPolicy="no-referrer-when-downgrade"
												></iframe> */}
											</div>
										) : null}
									</div>

									<div className="w-full grid gap-4 duration-300">
										<h4 className="text-base font-semibold uppercase tracking-tighter">
											Contact Details
										</h4>

										{property.hasOwnProperty("agents") &&
											typeof property.agents[Symbol.iterator] === "function" &&
											property.agents.length > 0 &&
											property?.agents.map((agent, i) => (
												<div
													key={i}
													className="border border-gray-100 bg-gray-50/50 rounded-sm flex flex-col xs:flex-row slg:flex-col items-start gap-4 sm:gap-20 slg:gap-2 duration-300 justify-items-start font-medium text-sm text-gray-800 overflow-hidden"
												>
													<div className="w-full slg:aspect-[5/4] max-h-[80] bg-white">
														<img
															src={agent?.image ?? "/static/images/default.jpg"}
															alt={agent?.firstname ?? agent?.name ?? ""}
															className="w-full h-full object-cover"
														/>
													</div>

													<div className="h-auto grid gap-2 items-start justify-items-start p-4">
														<span className="text-black font-semibold text-xl">
															{agent?.name ??
																(agent?.firstname ?? "") +
																	" " +
																	(agent?.middlename ?? "") +
																	" " +
																	(agent?.lastname ?? "")}
														</span>

														<div className="flex items-center gap-4">
															<Icon
																icon="solar:incoming-call-rounded-outline"
																fontSize={20}
															/>
															<span>{agent?.phone}</span>
														</div>

														<div className="flex items-center gap-4">
															<Icon icon="solar:letter-outline" fontSize={18} />
															<span>{agent?.email}</span>
														</div>
													</div>
												</div>
											))}
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
							<div className="grid text-center justify-items-center gap-4">
								<div className="text-6xl text-orange-500">
									<Icon icon="solar:buildings-3-bold-duotone" />
								</div>

								<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
									Couldn't found property.
								</h1>
							</div>
						</div>
					)
				) : (
					<div className="p-20 flex items-center justify-center">
						<Icon icon="solar:round-graph-broken" fontSize={40} className="text-slate-500 animate-spin" />
					</div>
				)}
			</div>
		</motion.div>
	);
}
