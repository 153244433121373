import React from "react";
import { SearchProvider } from "./searchContext";
import SearchProperty from ".";

export default function SearchLayout() {
	return (
		<SearchProvider>
			<SearchProperty />
		</SearchProvider>
	);
}
