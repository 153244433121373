import React, { Fragment } from "react";
import { motion } from "framer-motion";
import { FormInput } from "./";

const cities = ["Sydney", "Melbourne", "Brisbane", "Perth", "Adelaide", "Canberra", "Hobart", "Darwin"];

export default function Location({ loc, setLoc, update = false }) {
	return (
		<Fragment>
			<div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
				{/* City */}
				<div className="w-full grid gap-2 justify-items-start">
					<motion.label
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3, ease: "easeInOut" }}
						htmlFor="city"
						className="font-medium text-sm"
					>
						<span>City</span>
					</motion.label>

					<motion.div
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3, ease: "easeInOut" }}
						className="w-full h-11"
					>
						<select
							id="city"
							required
							className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
							defaultValue={update ? loc?.city : ""}
							onChange={(e) => {
								const val = e.currentTarget.value;
								setLoc((l) => ({
									...l,
									city: val,
								}));
							}}
						>
							<option value="" key={"-1"} disabled>
								Select city
							</option>
							{cities.map((city, i) => (
								<option value={city} key={i}>
									{city}
								</option>
							))}
						</select>
					</motion.div>
				</div>
			</div>

			{/* Location Url */}
			<div className="w-full grid gap-2 justify-items-start">
				<motion.label
					initial={{ opacity: 0, y: -20 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3 }}
					htmlFor="locationUrl"
					className="font-medium text-sm"
				>
					<span>Url</span>
				</motion.label>

				<motion.div
					initial={{ opacity: 0, y: -20 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3 }}
					className="w-full h-11"
				>
					<FormInput
						type="url"
						id="locationUrl"
						autoComplete="url"
						defaultValue={update ? loc.url : ""}
						placeholder="Location url"
						onKeyUp={(e) => {
							const val = e.currentTarget.value;
							setLoc((l) => ({
								...l,
								url: val.length > 0 ? val : "",
							}));
						}}
					/>
				</motion.div>
			</div>
		</Fragment>
	);
}
