import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDashboardContext } from "../../dashboardContext";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import db from "../../../firebase-config";
import { Icon } from "@iconify/react";
import moment from "moment";
import { deleteMessage } from "./actions";

export default function ViewMessage() {
	const { setTitle } = useDashboardContext();
	const { id } = useParams();

	const [message, setMessage] = useState(null);

	const ref = doc(db, "messages", id);

	const getMessage = async () => {
		const docSnap = await getDoc(ref);

		if (docSnap.exists()) {
			setMessage(docSnap.data());
		} else {
			setMessage({});
		}
	};

	useEffect(() => {
		if (message && Object.keys(message).length > 0) {
			if (!message.viewed) {
				updateDoc(ref, { viewed: true });
			} else return;
		} else return;
	}, [message]);

	useEffect(() => {
		getMessage();
	}, [id]);

	useEffect(() => {
		setTitle("View Message");
	}, []);

	return message ? (
		Object.keys(message).length > 0 ? (
			<div className="py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 space-y-10 duration-300">
				{/* Top App Bar */}
				<div className="flex items-center justify-between gap-10">
					<div className="flex flex-col items-start">
						<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
							{message.subject ?? ""}
						</h1>

						<div className="text-sm tracking-tight text-gray-600">
							Received at -{" "}
							<span className="font-semibold">
								{moment(message.sentAt.seconds * 1000).format("MMM DD, hh:mm A")}
							</span>
						</div>
					</div>

					<div
						role="button"
						onClick={() => deleteMessage(id, true)}
						className="rounded-full flex items-center justify-center gap-2 px-6 h-10 bg-gradient-to-br from-rose-400 to-red-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
					>
						<span>Delete message</span>
						<Icon icon="solar:trash-bin-trash-bold-duotone" fontSize={16} />
					</div>
				</div>

				<div className="flex flex-col items-start gap-2 divide-y">
					<h2 className="tracking-tighter font-bold text-sm tn:text-base sm:text-lg duration-300">
						Message by{" "}
						<span className="text-gray-600">{message.fullname ?? "Unknown"}</span>
					</h2>

					<div className="w-full text-pretty leading-loose text-left font-medium text-base">
						{message.message ?? ""}
					</div>
				</div>
			</div>
		) : (
			<div className="w-full h-full p-10 flex items-center justify-center">
				<div className="grid justify-items-center gap-4">
					<div className="text-8xl text-orange-500">
						<Icon icon="solar:planet-bold-duotone" />
					</div>

					<span className="font-medium text-lg tracking-tighter">Message not found.</span>
				</div>
			</div>
		)
	) : (
		<div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
			<div className="grid text-center justify-items-center gap-4">
				<div className="text-6xl text-indigo-500 animate-spin">
					<Icon icon="solar:black-hole-line-duotone" />
				</div>

				<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
					Loading message...
				</h1>
			</div>
		</div>
	);
}
