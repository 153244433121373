import { Fragment, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

// Import Swiper styles
import "swiper/css";

import {
	Header,
	Footer,
	ContactForm,
	// GetListed
} from "./components";
import { useContainerContext } from "./contexts/container";

function Layout() {
	const { width, headerHeight, title } = useContainerContext();
	const [scrollOffset, setScrollOffset] = useState(0);
	const [headerHide, setHeaderHide] = useState(false);

	const location = useLocation();

	const handleScroll = () => {
		const offsetY = window.scrollY;
		setScrollOffset(offsetY);
	};

	useEffect(() => {
		if (scrollOffset > headerHeight) {
			setHeaderHide(true);
		} else {
			setHeaderHide(false);
		}
	}, [scrollOffset, headerHeight]);

	// Reset scroll position when the url changes
	useEffect(() => {
		if (location.hash) {
			return;
		} else {
			window.scrollTo(0, 0);
		}
	}, [location]);

	useEffect(() => {
		document.title = (title.length > 0 ? title + " - " : "") + process.env.REACT_APP_APP_NAME;
	}, [title]);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<Fragment>
			<div className="relative min-w-[340px] h-screen min-h-screen bg-white text-black gap-y-40 overflow-auto scroll-smooth">
				<Header hidden={headerHide} width={width} />

				<Outlet />

				{/* Get Listed */}
				{/* <GetListed /> */}

				{/* Newsletter box */}
				<ContactForm />

				<Footer />
			</div>
		</Fragment>
	);
}

export default Layout;
