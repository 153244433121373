import { Icon } from "@iconify/react";
import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { DashboardContext } from "../../dashboardContext";
import EditProperty from "./edit";
import ViewProperty from "./view";
import AddProperty from "./add";
import { collection, doc, getDocs, limit, orderBy, query, updateDoc } from "firebase/firestore";
import db from "../../../firebase-config";
import { deleteProperty, readXMLFiles } from "./actions";
import { Files, Images } from "./modals";

const propertyTypes = [
	"Land",
	"House",
	"Apartment",
	"Studio",
	"Terrace",
	"Townhouse",
	"Unit",
	"Villa",
	"Block of Units",
];

const propertyStatus = ["Vacant", "Tenanted", "Owner occupied"];
const statusTypes = ["ACTIVE", "SOLD", "DELETED", "OFF_MARKET", "UNDER_OFFER"];
const COLLECTION_NAME = "properties";

export default function Properties() {
	const [modalData, setModalData] = useState({
		id: null,
		modal: null,
		addedFromXML: false,
		data: [],
	});

	const { setTitle } = useContext(DashboardContext);
	const [properties, setProperties] = useState(null);

	const { pathname } = useLocation();

	const toggleStatus = async (id, status) => {
		try {
			const ref = doc(db, COLLECTION_NAME, id);
			await updateDoc(ref, { status })
				.then(() => {
					setProperties((prev) => prev.map((p) => (p.id === id ? { ...p, status } : p)));
				})
				.catch(() => alert("Failed"));
		} catch (error) {
			alert("Failed to update status");
		}
	};

	const getProperties = async () => {
		let ref = collection(db, COLLECTION_NAME);
		ref = query(ref, orderBy("createdAt", "desc"), limit(100));
		const res = await getDocs(ref);

		if (res.empty) {
			setProperties([]);
		} else {
			setProperties(res.docs.map((doc) => ({ id: doc.id, addedFromXML: false, ...doc.data() })));
		}

		loadDataFromXMLFiles();
	};

	useEffect(() => {
		if (modalData.modal == null && !modalData.addedFromXML) {
			getProperties();
		} else {
			return;
		}
	}, [modalData.modal]);

	const loadDataFromXMLFiles = async () => {
		const results = await readXMLFiles();
		if (results.length > 0) {
			if (properties && properties.length > 0) {
				setProperties((prev) => [...prev, ...results]);
			} else {
				setProperties([...results]);
			}
		}
	};

	useEffect(() => {
		setTitle("All properties");
		getProperties();
	}, []);

	return properties ? (
		<div className="h-screen py-4 xsm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 flex flex-col gap-10 duration-300">
			{/* Absolute modal box */}
			{modalData.modal != null && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.3 }}
					className="fixed z-999 w-screen h-screen inset-0 bg-black/5 p-2 flex items-center justify-center duration-300"
				>
					{/* Absolute box */}
					<div
						className="absolute inset-0 z-10"
						onClick={() => {
							setModalData({
								modal: null,
								data: [],
							});
						}}
					></div>

					{modalData.modal === "FILES" ? (
						<Files
							id={modalData.id}
							files={modalData.data}
							addedFromXML={modalData.addedFromXML}
							closeModal={() => {
								setModalData({ modal: null, data: [] });
							}}
						/>
					) : modalData.modal === "IMAGES" ? (
						<Images
							id={modalData.id}
							images={modalData.data}
							addedFromXML={modalData.addedFromXML}
							closeModal={() => {
								setModalData({ modal: null, data: [] });
							}}
						/>
					) : null}
				</motion.div>
			)}

			{/* Top App Bar */}
			<div className="w-full flex items-center justify-between gap-10">
				<div className="flex flex-col items-start">
					<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
						Properties Listings
					</h1>

					{properties.length > 0 && (
						<div className="text-sm tracking-tight text-gray-600">
							{" "}
							Total <span className="font-medium text-gray-800">{properties.length}</span> properties
							found.{" "}
						</div>
					)}
				</div>

				<div className="flex items-center gap-6">
					<a
						href={pathname + "/add"}
						className="rounded-full flex items-center justify-center gap-2 px-6 h-10 bg-gradient-to-br from-blue-400 to-indigo-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
					>
						<span>Add new property</span>
						<Icon icon="solar:add-square-bold" fontSize={16} />
					</a>
				</div>
			</div>

			{/* Table | Properties */}
			{properties.length > 0 ? (
				<div className="w-full h-auto overflow-auto">
					<table className="w-full h-full min-w-max overflow-auto border-collapse" id="datasTable">
						<thead className="w-full text-sm tracking-tighter border-b">
							<tr className="text-left *:px-4">
								<th className="py-2">SN</th>
								<th>Property Title</th>
								<th>SubTitle</th>
								<th>City</th>
								<th>Price</th>
								<th>Area</th>
								<th>Property Type</th>
								<th>Property Status</th>
								<th>Property Images</th>
								<th>Attachment Files</th>
								<th>Added At</th>
								<th>Status</th>
								<th>Actions</th>
							</tr>
						</thead>

						<tbody>
							{properties.map((property, index) => (
								<tr key={index} className="text-left text-xsm hover:bg-gray-100 even:bg-gray-50 *:px-4">
									<td align="middle" className="py-6">
										<span className="font-medium text-xs">{index + 1}.</span>
									</td>

									<td className="font-semibold">
										<a
											href={`${pathname}/${property.id}`}
											className="max-w-[350px] line-clamp-1 text-ellipsis"
										>
											{property.title ?? ""}
										</a>
									</td>

									<td className="font-semibold">
										<span className="text-gray-800 max-w-[300px] line-clamp-1 text-ellipsis">
											{property.subTitle ?? "..."}
										</span>
									</td>

									<td className="font-medium">
										<div className="max-w-60 line-clamp-1 text-ellipsis">
											{property.loc.city ?? ""}
										</div>
									</td>

									<td className="font-semibold">{property.price ?? "..."}</td>
									<td className="font-semibold">{property.area ?? "..."}</td>
									<td className="font-semibold">{property.ptype ?? "..."}</td>
									<td className="font-semibold">{property.pstatus ?? "..."}</td>

									<td>
										<div className="flex items-center gap-4">
											<span className="font-semibold">{property?.images?.length ?? 0}</span>

											<button
												title="View property images"
												className="border-none outline-none text-xsm bg-blue-600 hover:bg-blue-800 duration-300 text-white px-3 py-1 rounded cursor-pointer tracking-tighter"
												onClick={() => {
													setModalData({
														id: property.id,
														modal: "IMAGES",
														data:
															property.hasOwnProperty("images") &&
															typeof property.images[Symbol.iterator] === "function"
																? property.images
																: [],
														addedFromXML: property.addedFromXML,
													});
												}}
											>
												<span>{property?.images.length > 0 ? "View" : "Add"} images</span>
											</button>
										</div>
									</td>

									<td>
										{property.hasOwnProperty("files") &&
										typeof property.files[Symbol.iterator] === "function" &&
										property.files.length <= 0 &&
										property.addedFromXML ? (
											<span>No files added</span>
										) : (
											<div className="flex items-center gap-4">
												<span className="font-semibold">{property?.files?.length ?? 0}</span>

												<button
													title="View attachment files"
													className="border-none outline-none text-xsm bg-blue-600 hover:bg-blue-800 duration-300 text-white px-3 py-1 rounded cursor-pointer tracking-tighter"
													onClick={() => {
														setModalData({
															id: property.id,
															modal: "FILES",
															data:
																property.hasOwnProperty("files") &&
																typeof property.files[Symbol.iterator] === "function"
																	? property.files
																	: [],
															addedFromXML: property.addedFromXML,
														});
													}}
												>
													<span>{property?.files.length > 0 ? "View" : "Add"} files</span>
												</button>
											</div>
										)}
									</td>

									<td>
										<div className="min-w-max font-medium">
											{moment(property.createdAt.seconds * 1000).format("MMM DD, hh:mm A")}
										</div>
									</td>
									<td>
										<select
											id={"status" + index}
											required
											className="px-4 py-1 border border-blue-500 outline-none rounded-sm bg-transparent font-semibold text-sm"
											defaultValue={property.status ?? ""}
											onChange={(e) => {
												const val = e.currentTarget.value;
												toggleStatus(property.id, val);
											}}
										>
											{property.addedFromXML ? (
												<option value="" key="0">
													Active
												</option>
											) : (
												statusTypes.map((s, i) => {
													let option = s.replace("_", " ").toLowerCase();
													option =
														option.charAt(0).toUpperCase() + option.slice(1, option.length);
													return (
														<option value={s} key={i}>
															{option}
														</option>
													);
												})
											)}
										</select>
									</td>
									{property.addedFromXML ? (
										<td>...</td>
									) : (
										<td>
											<div className="flex items-center gap-4 text-gray-800 *:duration-300 *:text-2xl">
												<a
													href={`${pathname}/${property.id}/edit`}
													className="hover:text-blue-600"
													title="Edit property"
												>
													<Icon icon="solar:pen-new-round-bold-duotone" />
												</a>

												<div
													role="button"
													onClick={() => {
														const confirmed = window.confirm(
															"Are you sure you want to delete this property permanently."
														);

														if (confirmed) {
															deleteProperty(property)
																.then(() => {
																	window.location.reload();
																})
																.catch(() => alert("Failed to delete."));
														}
													}}
													className="hover:text-red-600 cursor-pointer"
													title="Delete property"
												>
													<Icon icon="solar:trash-bin-minimalistic-bold-duotone" />
												</div>
											</div>
										</td>
									)}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : (
				<div className="flex-grow p-20 rounded-xl bg-red-50 flex items-center justify-center">
					<div className="grid text-center justify-items-center gap-4">
						<div className="text-8xl text-red-500">
							<Icon icon="solar:buildings-3-bold-duotone" />
						</div>

						<div className="font-medium text-lg tracking-tighter">
							<span>Couldn't found any properties.</span>
						</div>
					</div>
				</div>
			)}

			{/* Grids | Properties | Contents */}
			{/* <div className="grid grid-cols-1 xsm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8 row-auto">
				{properties.map((property, index) => (
					<Property key={index} property={property} prefix={pathname} delay={0.1 * index} />
				))}
			</div> */}
		</div>
	) : (
		<div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
			<div className="grid text-center justify-items-center gap-4">
				<div className="text-6xl text-indigo-500 animate-spin">
					<Icon icon="solar:black-hole-line-duotone" />
				</div>

				<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
					Loading properties...
				</h1>
			</div>
		</div>
	);
}

// Its other components
export { AddProperty, ViewProperty, EditProperty, propertyStatus, propertyTypes, COLLECTION_NAME };
