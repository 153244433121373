import { Icon } from "@iconify/react";
import React from "react";

export default function WhatWeDo() {
	return (
		<div className="w-full px-2 py-6 xs:py-10 lxs:py-14 md:py-20 duration-300" id="whatWeDo">
			<div className="w-full md:w-11/12 xl:w-10/12 mx-auto duration-200">
				<div className="w-full rounded-3xl bg-slate-100 p-6 md:p-12 grid gap-20 justify-items-center duration-300">
					{/* Top Heading */}
					<div className="flex flex-col items-center text-center gap-4">
						<h1 className="font-bold text-2xl sm:text-3xl md:text-4xl tracking-tighter duration-300">
							<span>‟ Working Together Means Winning Together ”</span>
						</h1>

						<div className="text-sm sm:text-base text-gray-500 max-w-lg duration-300">
							We are committed to providing exceptional services tailored to meet your real estate needs.
							With our expertise and dedication, we offer a range of comprehensive solutions designed to
							assist you in every step of your real estate journey.
						</div>
					</div>

					<div className="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-4 gap-2 md:gap-4 duration-300">
						<div className="w-full gap-y-6 flex flex-col items-center gap-4 text-center p-4 rounded-xl cursor-default hover:bg-white duration-200">
							<div className="w-16 h-16 rounded-full bg-gray-200 text-orange-500 flex items-center justify-center duration-300">
								<Icon icon="solar:buildings-bold-duotone" fontSize={30} />
							</div>

							<h2 className="text-sm sm:text-base lg:text-lg font-bold tracking-tight">Property Sales</h2>

							<div className="text-xsm sm:text-sm md:text-base tracking-tight font-medium text-slate-700 max-w-full">
								Whether you're selling a residential property, commercial space, or land, our team is
								here to help you navigate the complexities of the sales process.
							</div>
						</div>

						<div className="w-full gap-y-6 flex flex-col items-center gap-4 text-center p-4 rounded-xl cursor-default hover:bg-white duration-200">
							<div className="w-16 h-16 rounded-full bg-gray-200 text-orange-500 flex items-center justify-center duration-300">
								<Icon icon="solar:archive-minimalistic-bold-duotone" fontSize={30} />
							</div>

							<h2 className="text-sm sm:text-base lg:text-lg font-bold tracking-tight">
								Buyer Representation
							</h2>

							<div className="text-xsm sm:text-sm md:text-base tracking-tight font-medium text-slate-700 max-w-full">
								Finding your dream property can be overwhelming, but with our buyer representation
								services, it doesn't have to be.
							</div>
						</div>

						<div className="w-full gap-y-6 flex flex-col items-center gap-4 text-center p-4 rounded-xl cursor-default hover:bg-white duration-200">
							<div className="w-16 h-16 rounded-full bg-gray-200 text-orange-500 flex items-center justify-center duration-300">
								<Icon icon="solar:key-minimalistic-square-bold" fontSize={30} />
							</div>

							<h2 className="text-sm sm:text-base lg:text-lg font-bold tracking-tight">
								Property Management
							</h2>

							<div className="text-xsm sm:text-sm md:text-base tracking-tight font-medium text-slate-700 max-w-full">
								Investing in real estate comes with its own set of challenges, but our property
								management services make it hassle-free.
							</div>
						</div>

						<div className="w-full gap-y-6 flex flex-col items-center gap-4 text-center p-4 rounded-xl cursor-default hover:bg-white duration-200">
							<div className="w-16 h-16 rounded-full bg-gray-200 text-orange-500 flex items-center justify-center duration-300">
								<Icon icon="solar:chat-round-call-bold" fontSize={30} />
							</div>

							<h2 className="text-sm sm:text-base lg:text-lg font-bold tracking-tight">
								Real Estate Consulting
							</h2>

							<div className="text-xsm sm:text-sm md:text-base tracking-tight font-medium text-slate-700 max-w-full">
								Navigating the real estate market can be complex, especially for first-time buyers or
								investors.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
