import React from "react";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";

export function Loader() {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.3 }}
		>
			<div className="w-full p-20 bg-gray-50 rounded-xl flex items-center justify-center">
				<div className="text-3xl text-blue-500 animate-spin">
					<Icon icon="solar:black-hole-line-duotone" />
				</div>
			</div>
		</motion.div>
	);
}
