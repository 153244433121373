import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDashboardContext } from "../../../dashboardContext";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import db from "../../../../firebase-config";
import { Icon } from "@iconify/react";
import moment from "moment";
import { deleteRequest } from "./actions";

export default function ViewRentalRequest() {
	const { setTitle } = useDashboardContext();
	const { id } = useParams();

	const [request, setRequest] = useState(null);

	const ref = doc(db, "rental-appraisals", id);

	const getRequest = async () => {
		const docSnap = await getDoc(ref);

		if (docSnap.exists()) {
			setRequest(docSnap.data());
		} else {
			setRequest({});
		}
	};

	useEffect(() => {
		if (request && Object.keys(request).length > 0) {
			if (!request.viewed) {
				updateDoc(ref, { viewed: true });
			} else return;
		} else return;
	}, [request]);

	useEffect(() => {
		getRequest();
	}, [id]);

	useEffect(() => {
		setTitle("View Rental Appraisal");
	}, []);

	return request ? (
		Object.keys(request).length > 0 ? (
			<div className="py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 space-y-10 duration-300">
				{/* Top App Bar */}
				<div className="flex items-center justify-between gap-10">
					<div className="flex flex-col items-start">
						<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
							{request.fullname ?? ""}
						</h1>

						<div className="text-sm tracking-tight text-gray-600">
							Received at -{" "}
							<span className="font-semibold">
								{moment(request.sentAt.seconds * 1000).format("MMM DD, hh:mm A")}
							</span>
						</div>
					</div>

					<div
						role="button"
						onClick={() => deleteRequest(id, true)}
						className="rounded-full flex items-center justify-center gap-2 px-6 h-10 bg-gradient-to-br from-rose-400 to-red-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
					>
						<span>Delete request</span>
						<Icon icon="solar:trash-bin-trash-bold-duotone" fontSize={16} />
					</div>
				</div>

				<div className="flex">
					<div className="border rounded-sm flex flex-wrap items-center divide-x *:px-6 *:py-4 hover:*:bg-gray-50 *:cursor-default *:duration-300">
						<div className="inline-flex items-center gap-4">
							<span className="font-medium text-sm">Total No. of Beds Rooms</span>
							<span className="font-bold text-base">{request.beds}</span>
						</div>

						<div className="inline-flex items-center gap-4">
							<span className="font-medium text-sm">Total No. of Bathrooms</span>
							<span className="font-bold text-base">{request.baths}</span>
						</div>

						<div className="inline-flex items-center gap-4">
							<span className="font-medium text-sm">Total No. of Parkings</span>
							<span className="font-bold text-base">{request.parkings}</span>
						</div>
					</div>
				</div>

				<div className="flex flex-col items-start gap-2 divide-y">
					<h2 className="tracking-tighter font-bold text-sm tn:text-base sm:text-lg duration-300">
						Description
					</h2>

					<div className="w-full text-pretty leading-loose text-left font-medium text-base">
						{request.desc ?? ""}
					</div>
				</div>
			</div>
		) : (
			<div className="w-full h-full p-10 flex items-center justify-center">
				<div className="grid justify-items-center gap-4">
					<div className="text-8xl text-orange-500">
						<Icon icon="solar:planet-bold-duotone" />
					</div>

					<span className="font-medium text-lg tracking-tighter">Request not found.</span>
				</div>
			</div>
		)
	) : (
		<div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
			<div className="grid text-center justify-items-center gap-4">
				<div className="text-6xl text-indigo-500 animate-spin">
					<Icon icon="solar:black-hole-line-duotone" />
				</div>

				<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
					Loading request...
				</h1>
			</div>
		</div>
	);
}
