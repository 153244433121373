import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import db from "../../../../firebase-config";

export async function getRecentProperties() {
	return new Promise(async (resolve) => {
		let ref = collection(db, "properties");
		ref = query(ref, orderBy("createdAt", "desc"), limit(6));
		await getDocs(ref)
			.then((res) => {
				if (res.empty) {
					resolve([]);
				} else {
					resolve(res.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
				}
			})
			.catch(() => resolve([]));
	});
}

export async function getRecentNews() {
	return new Promise(async (resolve) => {
		let ref = collection(db, "news");
		ref = query(ref, orderBy("createdAt", "desc"), limit(6));
		await getDocs(ref)
			.then((res) => {
				if (res.empty) {
					resolve([]);
				} else {
					resolve(res.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
				}
			})
			.catch(() => resolve([]));
	});
}

export async function getInsights() {
	return new Promise(async (resolve) => {
		let ref = collection(db, "counters");
		await getDocs(ref)
			.then((res) => {
				if (res.empty) {
					resolve({});
				} else {
					const insights = {};
					res.docs.map((doc) => {
						insights[doc.id] = doc.data().count;
					});
					resolve(insights);
				}
			})
			.catch(() => resolve({}));
	});
}
