import React, { useEffect, useState } from "react";
import { FiArrowDown } from "react-icons/fi";
import { Icon } from "@iconify/react";
import NewsBox from "./news";
import { collection, getDocs } from "firebase/firestore";
import db from "../../../../firebase-config";

export default function LatestNews() {
	const [latestNews, setLatestNews] = useState([]);

	const getLatestNews = async () => {
		const ref = collection(db, "news");
		await getDocs(ref)
			.then((res) => {
				if (res.empty) setLatestNews([]);
				else setLatestNews(res.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
			})
			.catch(() => setLatestNews([]));
	};

	useEffect(() => {
		getLatestNews();
	}, []);

	return latestNews.length > 0 ? (
		<div className="w-full px-2 py-20 bg-white" id="latestNews">
			<div className="w-full md:w-11/12 xl:w-10/12 mx-auto duration-200 space-y-6 xs:space-y-10 lxs:space-y-14 md:space-y-20 grid">
				{/* Top heading */}
				<div className="flex items-center justify-between gap-4 lxs:gap-12 duration-300">
					<h1 className="font-bold inline-flex gap-4 items-center duration-200">
						<span className="tracking-tighter text-base tn:text-lg sm:text-3xl duration-300">
							Latest news
						</span>

						{latestNews.length > 0 ? (
							<div className="hidden lxs:inline-flex gap-4 items-center">
								<div className="w-16 h-[1px] bg-gray-200"></div>

								<span className="text-base font-semibold text-orange-600">{latestNews.length}</span>
							</div>
						) : null}
					</h1>

					<a
						href={"/latest-news"}
						className="text-xs tn:text-xsm sm:text-lsm tracking-tighter text-orange-600 font-medium hover:text-white bg-gradient-to-br hover:from-orange-600 hover:to-yellow-400 rounded-full px-4 py-2 duration-200 flex items-center gap-2"
					>
						<span>View all news</span>
						<Icon icon="solar:arrow-right-outline" />
					</a>
				</div>

				{/* Grids | News | Contents */}
				<div className="grid grid-cols-1 xsm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-10 duration-300">
					{latestNews.map((news, index) => (
						<NewsBox key={index} news={news} delay={0.1 * index} />
					))}
				</div>

				{latestNews.length > 2 ? (
					<div className="place-self-center">
						<div
							role="button"
							className="text-sm text-orange-500 font-medium hover:text-orange-600 rounded-full px-4 py-2 hover:bg-orange-100 duration-200 flex items-center gap-10"
						>
							<span>Load more...</span>
							<FiArrowDown />
						</div>
					</div>
				) : null}
			</div>
		</div>
	) : null;
}
