import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";
import { useDashboardContext } from "../../../dashboardContext";
import { Icon } from "@iconify/react";
import {
	addDoc,
	collection,
	doc,
	getDoc,
	increment,
	setDoc,
	Timestamp,
	updateDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import db, { storage } from "../../../../firebase-config";

const REDIRECT = "/admin-dashboard/all-news";

const FormInput = ({ ...rest }) => {
	return (
		<input
			{...rest}
			className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
		/>
	);
};

export default function CreateNews() {
	const { setTitle, setUpdating } = useDashboardContext();

	const [data, setData] = useState({
		title: null,
		status: true,
	});

	const [image, setImage] = useState(null);

	const navigate = useNavigate();
	const { pathname } = useLocation();

	const handleFileInput = (evt) => {
		const file = evt.target.files[0];

		if (file && evt.target.files.length >= 1) {
			const reader = new FileReader();

			reader.readAsDataURL(file);

			reader.onloadend = (e) => {
				setImage(reader.result);
			};
		} else {
			return;
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setUpdating(true);

		try {
			await getEditorData()
				.then(async (content) => {
					const date = new Date();
					const imgId = date.getTime();
					const path = `images/news/${imgId}`;

					const storageRef = ref(storage, path);
					await uploadString(storageRef, image, "data_url")
						.then(async (res) => {
							await getDownloadURL(res.ref)
								.then(async (url) => {
									const ref = collection(db, "news");
									await addDoc(ref, {
										...data,
										content,
										image: {
											url,
											path,
										},
										createdAt: Timestamp.now(),
										updatedAt: Timestamp.now(),
									})
										.then(async () => {
											const counterRef = doc(db, "counters", "news");
											const docsSnap = await getDoc(counterRef);
											if (docsSnap.exists()) {
												await updateDoc(counterRef, {
													count: increment(1),
												});
											} else {
												await setDoc(counterRef, {
													count: 1,
												});
											}
											setUpdating(false);

											setTimeout(() => {
												window.location.href = REDIRECT + "/" + data.id;
											}, 100);
										})
										.catch((error) => {
											throw new Error(error);
										});
								})
								.catch((error) => {
									throw new Error(error);
								});
						})
						.catch((error) => {
							throw new Error(error);
						});
				})
				.catch((error) => {
					throw new Error(error);
				});
		} catch (error) {
			console.error(error);
			setUpdating(false);
		}
	};

	const editorRef = useRef(null);

	const loadScript = (url, callback) => {
		const existingScript = document.getElementById("ckeditor-script");
		if (!existingScript) {
			const script = document.createElement("script");
			script.src = url;
			script.id = "ckeditor-script";
			document.body.appendChild(script);
			script.onload = () => {
				if (callback) callback();
			};
		} else if (existingScript && callback) {
			callback();
		}
	};

	useEffect(() => {
		setTitle("Create news");
		if (window.CKEDITOR) {
			initCKEditor();
		} else {
			loadScript("/lib/ckeditor/ckeditor.js", initCKEditor);
		}

		return () => {
			if (window.CKEDITOR) {
				for (let instance in window.CKEDITOR.instances) {
					window.CKEDITOR.instances[instance].destroy(true);
				}
				const script = document.getElementById("ckeditor-script");
				if (script) {
					document.body.removeChild(script);
				}
			}
		};
	}, []);

	const initCKEditor = () => {
		if (editorRef.current && window.CKEDITOR) {
			window.CKEDITOR.replace(editorRef.current);
		}
	};

	const getEditorData = () => {
		return new Promise((resolve) => {
			const content =
				window.CKEDITOR.instances[editorRef.current.getAttribute("id")].getData();
			resolve(content);
		});
	};

	return (
		<div className="py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 space-y-10 duration-300">
			{/* Top App Bar */}
			<div className="flex items-center justify-between gap-10">
				<div className="flex items-center gap-4">
					<div
						role="link"
						onClick={() => navigate(-1)}
						className="hover:scale-110 duration-200 text-xl cursor-pointer"
					>
						<Icon icon="solar:arrow-left-line-duotone" />
					</div>

					<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
						Create News
					</h1>
				</div>
			</div>

			{/* Bottom Form */}
			<form
				action={pathname ?? "#"}
				method="POST"
				className="grid gap-12 tracking-tighter"
				onSubmit={handleSubmit}
			>
				{/* News Title */}
				<div className="w-full grid gap-2 justify-items-start">
					<motion.label
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						htmlFor="title"
						className="font-medium text-sm"
					>
						<span>Title</span>
					</motion.label>

					<motion.div
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						className="w-full h-11"
					>
						<FormInput
							type="text"
							id="title"
							autoComplete="off"
							required
							placeholder="Add news title"
							onKeyUp={(e) => {
								const val = e.currentTarget.value;
								setData((d) => ({
									...d,
									title: val.length > 0 ? val : "",
								}));
							}}
						/>
					</motion.div>
				</div>

				{/* News Status */}
				<div className="place-self-start w-60 grid gap-2 justify-items-start">
					<motion.label
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						htmlFor="status"
						className="font-medium text-sm"
					>
						<span>Status</span>
					</motion.label>

					<motion.div
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						className="w-full h-11"
					>
						<select
							id="status"
							required
							className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
							onChange={(e) => {
								const val = parseInt(e.currentTarget.value);
								setData((d) => ({
									...d,
									status: Boolean(val),
								}));
							}}
						>
							<option value="1" key="0">
								Active
							</option>
							<option value="0" key="1">
								Hidden
							</option>
						</select>
					</motion.div>
				</div>

				{/* News Images */}
				<motion.div className="w-full grid gap-2 justify-items-start">
					<motion.label
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						htmlFor="images"
						className="font-medium text-sm"
					>
						<span>Image</span>
					</motion.label>

					<motion.div
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						className="w-full"
					>
						<input
							type="file"
							id="images"
							// multiple
							accept="image/x-png, image/jpeg, image/webp, image/gif"
							className="tracking-tighter rounded-sm text-sm font-medium outline-none border-0"
							// onChange={handleMultipleFileInput}
							onChange={handleFileInput}
						/>
					</motion.div>
				</motion.div>

				{/* News Contents */}
				<div className="w-full grid gap-2 justify-items-start">
					<motion.label
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						htmlFor="content"
						className="font-medium text-sm"
					>
						<span>News Contents</span>
					</motion.label>

					<motion.div
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.3 }}
						className="w-full h-auto"
					>
						<textarea
							rows={10}
							id="content"
							required
							ref={editorRef}
							className="w-full text-sm font-medium rounded-sm tracking-tighter outline-none py-2 px-4 border-2 focus-within:border-orange-500 focus:border-orange-500 bg-white placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
							placeholder="Write news contents..."
						></textarea>
					</motion.div>
				</div>

				<div className="place-self-end flex items-center gap-6 flex-wrap">
					<button
						type="reset"
						className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-gray-300 to-gray-400 hover:bg-gradient-to-tr text-xs text-gray-700 font-semibold hover:text-black duration-300"
					>
						<span>Reset</span>
						<Icon icon="solar:close-square-outline" fontSize={16} />
					</button>

					<button
						type="submit"
						className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-orange-400 to-orange-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
					>
						<span>Create News</span>
						<Icon icon="solar:add-square-bold" fontSize={16} />
					</button>
				</div>
			</form>
		</div>
	);
}
