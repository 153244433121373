import { Component, createContext, useContext } from "react";

export const ContainerContext = createContext();

export class ContainerContextProvider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			darkMode: false,
			width: 0,
			title: "",
			headerHeight: 0,
			headerDocked: false,
			bannerContainerHeight: 0,
		};

		this.handleResize = this.handleResize.bind(this);
		this.setBannerContainerHeight = this.setBannerContainerHeight.bind(this);
		this.setHeaderHeight = this.setHeaderHeight.bind(this);
		this.setHeaderDocked = this.setHeaderDocked.bind(this);
		this.setTitle = this.setTitle.bind(this);
	}

	componentDidMount() {
		this.setState({
			width: window.innerWidth,
		});
		window.addEventListener("resize", this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	handleResize() {
		this.setState({ width: window.innerWidth });
	}

	setBannerContainerHeight(height) {
		this.setState({
			bannerContainerHeight: height,
		});
	}

	setHeaderHeight(height) {
		this.setState({
			headerHeight: height,
		});
	}

	setHeaderDocked(docked) {
		this.setState({
			headerDocked: docked,
		});
	}

	setTitle(title) {
		this.setState({
			title: title,
		});
	}

	render() {
		return (
			<ContainerContext.Provider
				value={{
					darkMode: this.state.darkMode,
					width: this.state.width,
					title: this.state.title,
					setTitle: this.setTitle,
					bannerContainerHeight: this.state.bannerContainerHeight,
					setBannerContainerHeight: this.setBannerContainerHeight,
					headerHeight: this.state.headerHeight,
					setHeaderHeight: this.setHeaderHeight,
					headerDocked: this.state.headerDocked,
					setHeaderDocked: this.setHeaderDocked,
				}}
			>
				{this.props.children}
			</ContainerContext.Provider>
		);
	}
}

export const useContainerContext = () => {
	return useContext(ContainerContext);
};
