import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import { useContainerContext } from "../../../contexts/container";
import { addDoc, collection, doc, updateDoc, Timestamp } from "firebase/firestore";
import db from "../../../firebase-config";
import { updateCount } from "../../../admin/pages/utils";

const COLLECTION_NAME = "rental-appraisals";

export default function RentalAppraisal() {
	const [loading, setLoading] = useState(false);

	const { setTitle } = useContainerContext();
	const { pathname } = useLocation();

	const [data, setData] = useState({
		id: null,
		fullname: null,
		gender: null,
		mobile: null,
		email: null,
		contactBy: false,
		address: null,
		beds: 0,
		baths: 0,
		parkings: 0,
		desc: null,
		viewed: false,
	});

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);

		const ref = collection(db, COLLECTION_NAME);
		await addDoc(ref, { ...data, sentAt: Timestamp.now() })
			.then(async (res) => {
				await updateDoc(doc(db, COLLECTION_NAME, res.id), {
					id: res.id,
				});
				await updateCount(COLLECTION_NAME, true);
				setLoading(false);
				alert("Appraisal request sent successfully ✅.");
				window.location.reload();
			})
			.catch(() => {
				setLoading(false);
				alert("Failed to send appraisal request ❌.");
			});
	};

	useEffect(() => {
		setTitle("Rental Appraisal");
	}, []);

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.2 }}
			className="w-full h-auto grid gap-0 bg-gradient-to-br pb-0 md:pb-24 from-orange-600 to-yellow-500 duration-300"
		>
			<div className="p-24 duration-300 flex items-center justify-center">
				<div className="text-5xl text-white font-bold uppercase tracking-wide text-center duration-300">
					<span>Rental Appraisal</span>
				</div>
			</div>

			<div className="relative w-full md:w-11/12 xl:w-10/12 mx-auto bg-white rounded-sm overflow-hidden duration-300">
				{/* Diplay while sending request */}
				{loading ? (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 0.3 }}
						className="absolute z-10 inset-0 flex items-center justify-center bg-black/30 text-white"
					>
						<div className="flex flex-col items-center text-center gap-4">
							<div className="text-3xl animate-spin">
								<Icon icon="solar:black-hole-line-duotone" />
							</div>

							<span className="text-base font-medium tracking-tighter">Sending request</span>
						</div>
					</motion.div>
				) : null}

				<form
					action={pathname}
					method="POST"
					onSubmit={handleSubmit}
					className="w-full grid gap-8 justify-items-center p-6 xsm:p-10 sm:p-14 md:p-20 duration-300"
				>
					<div className="place-self-start text-xl font-semibold">
						<span>Contact Details</span>
					</div>

					<div className="w-full grid grid-cols-1 lxs:grid-cols-2 gap-8 duration-300">
						<div className="w-full grid justify-items-start">
							<label htmlFor="fullname" className="font-medium text-xsm">
								<span>Full Name</span>
							</label>

							<div className="w-full h-11">
								<input
									type="text"
									required
									id="fullname"
									autoComplete="name"
									className="w-full h-full text-sm font-medium outline-none border-0 border-b border-slate-200 focus-within:border-slate-700 focus:border-slate-700 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear"
									placeholder="Enter your full name"
									onKeyUp={(e) => {
										const val = e.currentTarget.value;
										setData((d) => ({
											...d,
											fullname: val.length > 0 ? val : "",
										}));
									}}
								/>
							</div>
						</div>

						<div className="w-full grid justify-items-start">
							<label htmlFor="gender" className="font-medium text-xsm">
								<span>Gender</span>
							</label>

							<div className="w-full h-11">
								<select
									id="gender"
									required
									autoComplete="off"
									className="w-full h-full text-sm font-medium outline-none border-0 border-b border-slate-200 focus-within:border-slate-700 focus:border-slate-700 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear"
									defaultValue={""}
									onChange={(e) => {
										const val = parseInt(e.currentTarget.value);
										setData((d) => ({
											...d,
											gender: val,
										}));
									}}
								>
									<option value="" disabled>
										Choose your gender
									</option>
									<option value="0" key={"b"}>
										Male
									</option>
									<option value="1" key={"c"}>
										Female
									</option>
									<option value="-1" key={"d"}>
										Other
									</option>
								</select>
							</div>
						</div>
					</div>

					<div className="w-full grid grid-cols-1 lxs:grid-cols-2 gap-8 duration-300">
						<div className="w-full grid justify-items-start">
							<label htmlFor="mobile" className="font-medium text-xsm">
								<span>Mobile Number</span>
							</label>

							<div className="w-full h-11">
								<input
									type="text"
									id="mobile"
									required
									autoComplete="tel"
									className="w-full h-full text-sm font-medium outline-none border-0 border-b border-slate-200 focus-within:border-slate-700 focus:border-slate-700 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear"
									placeholder="Enter your contact number"
									onKeyUp={(e) => {
										const val = e.currentTarget.value;
										setData((d) => ({
											...d,
											mobile: val.length > 0 ? val : "",
										}));
									}}
								/>
							</div>
						</div>

						<div className="w-full grid justify-items-start">
							<label htmlFor="email" className="font-medium text-xsm">
								<span>Email address</span>
							</label>

							<div className="w-full h-11">
								<input
									type="email"
									id="email"
									required
									autoComplete="email"
									className="w-full h-full text-sm font-medium outline-none border-0 border-b border-slate-200 focus-within:border-slate-700 focus:border-slate-700 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear"
									placeholder="Your email address"
									onKeyUp={(e) => {
										const val = e.currentTarget.value;
										setData((d) => ({
											...d,
											email: val.length > 0 ? val : "",
										}));
									}}
								/>
							</div>
						</div>
					</div>

					<div className="w-full grid justify-items-start">
						<label htmlFor="contactBy" className="font-medium text-xsm">
							<span>Preffered choice of contact ?</span>
						</label>

						<div className="w-full h-11">
							<select
								id="contactBy"
								required
								autoComplete="off"
								className="w-full h-full text-sm font-medium outline-none border-0 border-b border-slate-200 focus-within:border-slate-700 focus:border-slate-700 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear"
								defaultValue={""}
								onChange={(e) => {
									const val = parseInt(e.currentTarget.value);
									setData((d) => ({
										...d,
										contactBy: Boolean(val),
									}));
								}}
							>
								<option value="" disabled>
									Choose your preferred choice of contact
								</option>
								<option value="0" key={"b"}>
									Contact me by email
								</option>
								<option value="1" key={"c"}>
									Contact me by phone
								</option>
							</select>
						</div>
					</div>

					<div className="place-self-start text-xl font-semibold">
						<span>Property Details</span>
					</div>

					<div className="w-full grid justify-items-start">
						<label htmlFor="address" className="font-medium text-xsm">
							<span>Property address</span>
						</label>

						<div className="w-full h-11">
							<input
								type="text"
								id="address"
								required
								autoComplete="address-level1"
								className="w-full h-full text-sm font-medium outline-none border-0 border-b border-slate-200 focus-within:border-slate-700 focus:border-slate-700 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear"
								placeholder="Enter your property address"
								onKeyUp={(e) => {
									const val = e.currentTarget.value;
									setData((d) => ({
										...d,
										address: val.length > 0 ? val : "",
									}));
								}}
							/>
						</div>
					</div>

					<div className="w-full grid grid-cols-1 xsm:grid-cols-2 sm:grid-cols-3 gap-8">
						<div className="w-full grid justify-items-start">
							<label htmlFor="beds" className="font-medium text-xsm">
								<span>Beds</span>
							</label>

							<div className="w-full h-11">
								<input
									type="number"
									id="beds"
									required
									autoComplete="off"
									min={0}
									max={10000}
									className="w-full h-full text-sm font-medium outline-none border-0 border-b border-slate-200 focus-within:border-slate-700 focus:border-slate-700 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear"
									placeholder="Total number of beds"
									onKeyUp={(e) => {
										const val = parseInt(e.currentTarget.value);
										setData((d) => ({
											...d,
											beds: val,
										}));
									}}
								/>
							</div>
						</div>

						<div className="w-full grid justify-items-start">
							<label htmlFor="baths" className="font-medium text-xsm">
								<span>Baths</span>
							</label>

							<div className="w-full h-11">
								<input
									type="number"
									id="baths"
									required
									autoComplete="off"
									min={0}
									max={10000}
									className="w-full h-full text-sm font-medium outline-none border-0 border-b border-slate-200 focus-within:border-slate-700 focus:border-slate-700 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear"
									placeholder="Total number of beds"
									onKeyUp={(e) => {
										const val = parseInt(e.currentTarget.value);
										setData((d) => ({
											...d,
											baths: val,
										}));
									}}
								/>
							</div>
						</div>

						<div className="w-full grid justify-items-start">
							<label htmlFor="parkings" className="font-medium text-xsm">
								<span>Car Parkings</span>
							</label>

							<div className="w-full h-11">
								<input
									type="number"
									id="parkings"
									required
									autoComplete="off"
									min={0}
									max={10000}
									className="w-full h-full text-sm font-medium outline-none border-0 border-b border-slate-200 focus-within:border-slate-700 focus:border-slate-700 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear"
									placeholder="Total number of parkings available"
									onKeyUp={(e) => {
										const val = parseInt(e.currentTarget.value);
										setData((d) => ({
											...d,
											parkings: val,
										}));
									}}
								/>
							</div>
						</div>
					</div>

					<div className="w-full grid justify-items-start">
						<label htmlFor="description" className="font-medium text-xsm">
							<span>
								Please give a brief description of the property including age, number of bedrooms,
								bathrooms, style
							</span>
						</label>

						<div className="w-full h-auto">
							<textarea
								id="description"
								className="w-full text-sm py-2 font-medium outline-none border-0 border-b border-slate-200 focus-within:border-slate-700 focus:border-slate-700 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear"
								rows={6}
								required
								placeholder="Your description"
								onKeyUp={(e) => {
									const val = e.currentTarget.value;
									setData((d) => ({
										...d,
										desc: val.length > 0 ? val : "",
									}));
								}}
							></textarea>
						</div>
					</div>

					<button
						type="submit"
						className="rounded-sm w-full mxs:w-auto outline-none border-none ring-0 flex items-center justify-center gap-2 px-6 h-10 font-light bg-gradient-to-br from-orange-500 to-orange-600 hover:bg-gradient-to-tr text-sm text-white duration-300"
					>
						<span>Request appraisal</span>
						<Icon icon="solar:arrow-right-outline" fontSize={16} />
					</button>
				</form>
			</div>
		</motion.div>
	);
}
