import React, { Fragment, useState } from "react";
import { motion } from "framer-motion";

const propertyTypes = [
	"Land",
	"House",
	"Apartment",
	"Studio",
	"Terrace",
	"Townhouse",
	"Unit",
	"Villa",
	"Block of Units",
];
const propertyStatus = ["Vacant", "Tenanted", "Owner occupied"];

export default function CalculatePropertyWorth() {
	const [typeSelected, setTypeSelected] = useState(false);
	const [statusSelected, setStatusSelected] = useState(false);

	const [data, setData] = useState({
		location: null,
		type: null,
		status: null,
	});

	const handleSubmit = async (event) => {
		event.preventDefault();
		window.location.href = `/get-a-free-appraisal?location=${data.location}&type=${data.type}&status=${data.status}`;
	};

	return (
		<Fragment>
			<div className="grid gap-10 justify-items-center">
				<motion.h1
					initial={{ opacity: 0, y: -10 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3 }}
					className="relative font-semibold text-[7vw] md:text-5xl text-white tracking-wide text-center duration-300"
				>
					{/* WHAT MY PROPERTY <br /> WORTH? */}
					Free Market Appraisal
				</motion.h1>

				{/* Form */}
				<motion.form
					action="#"
					method="POST"
					onSubmit={handleSubmit}
					initial={{ opacity: 0, y: -10 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3, delay: 0.2 }}
					className="relative w-full xsm:w-auto *:w-full xsm:*:w-auto rounded-2xl xsm:rounded-full border bg-white flex flex-col xsm:flex-row items-start xsm:items-center divide-y xsm:divide-y-0 divide-x-0 xsm:divide-x overflow-hidden duration-300"
				>
					<div className="px-4 md:px-8 py-3 grid justify-items-start gap-2 duration-200">
						<label htmlFor="type" className="text-xs font-semibold text-gray-800">
							Location
						</label>

						<input
							type="text"
							id="location"
							required
							spellCheck="false"
							className="placeholder:font-medium p-0 placeholder:text-gray-500 font-semibold text-xsm outline-none border-none"
							placeholder="Enter a location"
							onKeyUp={(e) => {
								const val = e.currentTarget.value;
								setData((d) => ({
									...d,
									location: val.length > 0 ? val : "",
								}));
							}}
						/>
					</div>

					<div className="px-4 md:px-8 py-3 grid justify-items-start gap-2 duration-200">
						<label htmlFor="type" className="text-xs font-semibold text-gray-800">
							Property type
						</label>

						<select
							id="type"
							required
							className={
								"relative outline-none text-black border-none ring-0 text-xsm p-0 m-0 duration-200 " +
								(typeSelected ? "text-opacity-100 font-semibold" : "text-opacity-70 font-medium")
							}
							defaultValue={""}
							onChange={(e) => {
								const val = e.currentTarget.value;
								setData((d) => ({
									...d,
									type: val,
								}));
								setTypeSelected(true);
							}}
						>
							<option value="" key="-1" disabled>
								Select property type
							</option>

							{propertyTypes.map((t, i) => (
								<option value={t} key={i}>
									{t}
								</option>
							))}
						</select>
					</div>

					<div className="px-4 md:px-8 py-3 grid justify-items-start gap-2 duration-200">
						<label htmlFor="status" className="text-xs font-semibold text-gray-800">
							Property status
						</label>

						<select
							id="status"
							required
							className={
								"relative outline-none text-black border-none ring-0 text-xsm p-0 m-0 duration-200 " +
								(statusSelected ? "text-opacity-100 font-semibold" : "text-opacity-70 font-medium")
							}
							defaultValue={""}
							onChange={(e) => {
								const val = e.currentTarget.value;
								setData((d) => ({
									...d,
									status: val,
								}));
								setStatusSelected(true);
							}}
						>
							<option value="" key="-1" disabled>
								Select property status
							</option>

							{propertyStatus.map((s, i) => (
								<option value={s} key={i}>
									{s}
								</option>
							))}
						</select>
					</div>

					<button
						type="submit"
						className="p-4 h-full bg-yellow-600 hover:bg-yellow-700 duration-200 text-white text-sm flex items-center gap-2 font-light"
					>
						<span>Request appraisal</span>
					</button>
				</motion.form>
			</div>
		</Fragment>
	);
}
