import React, { Fragment, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import moment from "moment";
import { doc, getDoc } from "firebase/firestore";
import db from "../../../../firebase-config";
import { Icon } from "@iconify/react";

export default function ViewNews() {
	const { pathname } = useLocation();
	const { id } = useParams();
	const [news, setNews] = useState(null);

	const getNews = async () => {
		const ref = doc(db, "news", id);
		try {
			const docSnap = await getDoc(ref);
			if (docSnap.exists()) {
				setNews(docSnap.data());
			} else {
				setNews({});
			}
		} catch (error) {
			setNews({});
		}
	};

	useEffect(() => {
		getNews();
	}, [id]);

	return news ? (
		Object.keys(news).length > 0 ? (
			<Fragment>
				<div className="w-full h-[45vh] border-b bg-gray-50 overflow-hidden duration-300">
					<img
						src={news.image.url ?? "/static/images/placeholder.jpg"}
						alt={news.title ?? "Unknown"}
						className="w-full h-full object-cover duration-300"
					/>
				</div>

				<div className="sticky top-0 bg-white py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 duration-300">
					<div className="w-full flex flex-wrap items-start justify-between gap-2">
						<div className="space-y-2">
							<div className="w-full font-semibold text-lg sm:text-xl md:text-2xl tracking-tighter duration-300">
								{news?.title ?? "Unknown"}
							</div>

							<div className="text-base text-gray-600 font-medium">
								{moment(news?.createdAt.seconds * 1000).format("MMM DD, hh:mm A")}
							</div>
						</div>

						<div className="w-full lxs:w-auto flex items-center gap-4 flex-wrap duration-300">
							<div
								role="button"
								onClick={() => {}}
								className="bg-gradient-to-br from-red-600 to-rose-500 hover:bg-gradient-to-tr text-white text-sm w-1/2 sm:w-auto px-6 h-9 flex items-center justify-center rounded border duration-300"
							>
								<span>Delete</span>
							</div>

							<a
								href={pathname + "/edit"}
								className="bg-gradient-to-br from-blue-600 to-indigo-400 hover:bg-gradient-to-tr text-white text-sm w-1/2 sm:w-auto px-6 h-9 flex items-center justify-center rounded border duration-300"
							>
								<span>Edit</span>
							</a>
						</div>
					</div>
				</div>

				<div className="px-6 sm:px-10 md:px-14 lg:px-20 pb-6 duration-300">
					<div
						className="text-pretty leading-loose text-left font-medium text-sm"
						dangerouslySetInnerHTML={{ __html: news?.content ?? "" }}
					></div>
				</div>
			</Fragment>
		) : (
			<div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
				<div className="grid text-center justify-items-center gap-4">
					<div className="text-6xl text-orange-500">
						<Icon icon="solar:notebook-bold-duotone" />
					</div>

					<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
						News not found.
					</h1>
				</div>
			</div>
		)
	) : (
		<div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
			<div className="grid text-center justify-items-center gap-4">
				<div className="text-6xl text-indigo-500 animate-spin">
					<Icon icon="solar:black-hole-line-duotone" />
				</div>

				<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
					Loading news details...
				</h1>
			</div>
		</div>
	);
}
