import { Icon } from "@iconify/react";
import React from "react";

export default function Page404() {
	return (
		<div className="w-screen h-screen flex items-center justify-center bg-gradient-to-br from-rose-500 to-orange-500 text-white overflow-auto">
			<div className="grid justify-items-center gap-10">
				<div className="text-9xl">
					<Icon icon="solar:global-linear" />
				</div>

				<h1 className="font-bold text-5xl">Page not found</h1>

				<a
					href="/"
					rel="noreferrer"
					className="rounded-md bg-gradient-to-br from-blue-500 to-indigo-500 hover:from-blue-600 hover:to-indigo-600 duration-300 h-8 px-6 text-xs flex items-center justify-center"
				>
					<span>Go back to homepage</span>
				</a>
			</div>
		</div>
	);
}
