import Dashboard from "./dashboard";
import AboutUs from "./aboutUs";
import ContactUs from "./contactUs";
import PrivacyPolicy from "./privacyPolicy";
import TermsAndConditions from "./termsAndConditions";
import { collection, doc, increment, updateDoc } from "firebase/firestore";
import db from "../../firebase-config";
import { deleteApp } from "firebase/app";
import ExternalLinks from "./links";

export function updateCount(field = "", incr = false, zero = false) {
	return new Promise(async (resolve) => {
		if (typeof field == "string" && field !== "") {
			const counterRef = doc(db, "counters", field);
			await updateDoc(counterRef, {
				count: zero ? 0 : increment(incr ? 1 : -1),
			})
				.then(() => resolve(true, field))
				.catch(() => resolve(null));
		} else {
			resolve(null);
		}
	});
}

export function deleteAllData(coll = "") {
	return new Promise(async (resolve, reject) => {
		if (typeof coll == "string" && coll !== "") {
			const collectionRef = collection(db, coll);

			await deleteApp(collectionRef)
				.then(() => resolve("Collection deleted successfully."))
				.catch((error) => reject(error));
		} else {
			reject("Invalid collection name provided.");
		}
	});
}

export { Dashboard, ContactUs, AboutUs, PrivacyPolicy, TermsAndConditions, ExternalLinks };
