import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { doc, getDoc } from "firebase/firestore";
import db from "../../../firebase-config";

export default function ViewTeam() {
	const { id } = useParams();
	const [team, setTeam] = useState(null);

	const handleFormSubmit = (event) => {
		event.preventDefault();
	};

	const getTeam = async () => {
		const ref = doc(db, "teams", id);
		try {
			const docSnap = await getDoc(ref);
			if (docSnap.exists()) {
				setTeam(docSnap.data());
			} else {
				setTeam({});
			}
		} catch (error) {
			setTeam({});
		}
	};

	useEffect(() => {
		getTeam();
	}, [id]);

	return team ? (
		<Fragment>
			<div className="relative bg-gradient-to-br from-orange-600 to-yellow-600 duration-300">
				<div className="w-full h-auto md:w-11/12 xl:w-10/12 mx-auto p-6 xs:p-10 lxs:p-14 md:p-20 flex flex-col xsm:flex-row items-start xsm:items-center gap-4 xs:gap-10 lxs:gap-14 md:gap-20 duration-300">
					<div className="relative w-64 h-64 rounded border border-orange-400 overflow-hidden duration-300">
						<img
							src={team.image ?? "/static/images/default.png"}
							alt={team.name ?? "profile"}
							className="w-full h-full object-cover"
						/>
					</div>

					<div className="py-4 grid items-center justify-items-start gap-4 xsm:gap-10 tracking-tighter duration-300">
						<div className="flex flex-col items-start">
							<h1 className="font-semibold text-4xl">
								{(team.firstname ?? "") + " " + (team.middlename ?? "") + " " + (team.lastname ?? "")}
							</h1>
							<h4 className="font-medium text-base text-gray-900">{team.position ?? "..."}</h4>
						</div>

						<div className="grid xsm:flex xsm:flex-wrap gap-2 xsm:gap-6 *:min-h-max h-11 duration-300">
							{team.email && (
								<a
									href="/"
									className="h-full py-6 xsm:py-0 px-6 rounded-xl bg-gradient-to-tr from-slate-200 to-gray-100 hover:bg-gradient-to-br text-sm font-semibold flex items-center gap-2 hover:text-orange-600 duration-300"
								>
									<Icon icon="solar:letter-opened-bold-duotone" fontSize={20} />
									<span>{team.email ?? ""}</span>
								</a>
							)}
							{team.phone && (
								<a
									href="/"
									className="h-full py-6 xsm:py-0 px-6 rounded-xl bg-gradient-to-tr from-slate-200 to-gray-100 hover:bg-gradient-to-br text-sm font-semibold flex items-center gap-2 hover:text-orange-600 duration-300"
								>
									<Icon icon="solar:smartphone-2-bold-duotone" fontSize={20} />
									<span>{team.phone ?? ""}</span>
								</a>
							)}
						</div>
					</div>
				</div>
			</div>

			<div className="w-full md:w-11/12 xl:w-10/12 mx-auto px-2 py-6 xs:py-10 lxs:py-14 md:py-20 space-y-6 xs:space-y-10 lxs:space-y-14 md:space-y-20 bg-white duration-300">
				<div className="w-full flex flex-col sm:flex-row items-start gap-14 sm:gap-6 md:gap-12 lg:gap-14 duration-300">
					<div className="w-full sm:flex-grow space-y-10 duration-300">
						<h1 className="font-bold tracking-tighter text-3xl duration-300">
							About <span className="text-gray-800">{team.firstname ?? ""}</span>
						</h1>

						<div className="text-left text-pretty leading-loose text-sm font-medium duration-300">
							{team.description ?? ""}
						</div>
					</div>

					<div className="sticky top-0 w-full sm:min-w-[260px] slg:min-w-[300px] mlg:min-w-[360px] duration-300">
						<form
							action="#"
							method="POST"
							onSubmit={handleFormSubmit}
							className="w-full rounded border overflow-hidden bg-white shadow-xl p-6 grid gap-6"
						>
							<div className="py-4 grid gap-2 text-center justify-items-center">
								<div className="w-24 h-24 rounded-full text-6xl bg-orange-50 text-orange-600 flex items-center justify-center">
									<Icon icon="solar:letter-opened-bold-duotone" />
								</div>

								<div className="text-2xl font-bold tracking-tighter flex flex-col items-center">
									<span className="text-gray-800 text-xl">Send Message {team.firstname && "to"}</span>
									<span>
										{(team.firstname ?? "") +
											" " +
											(team.middlename ?? "") +
											" " +
											(team.lastname ?? "")}
									</span>
								</div>
							</div>

							<div className="w-full grid gap-4 *:w-full *:grid *:-space-y-1">
								<div className="justify-items-start">
									<div className="relative w-full h-11 flex items-center">
										<input
											type="text"
											id="username"
											autoComplete="name"
											className="w-full h-full text-sm pl-8 font-semibold tracking-tighter outline-none border-0 border-b focus-within:border-orange-600 focus:border-orange-600 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear peer"
											placeholder="Full name"
										/>

										<label
											htmlFor="username"
											className="absolute left-0 z-10 font-medium text-xl peer-focus:text-orange-600 peer-focus-within:text-orange-600 duration-300"
										>
											<Icon icon="solar:user-bold-duotone" />
										</label>
									</div>
								</div>

								<div className="justify-items-start">
									<div className="relative w-full h-11 flex items-center">
										<input
											type="email"
											id="email"
											autoComplete="email"
											className="w-full h-full text-sm pl-8 font-semibold tracking-tighter outline-none border-0 border-b focus-within:border-orange-600 focus:border-orange-600 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear peer"
											placeholder="Email address"
										/>

										<label
											htmlFor="email"
											className="absolute left-0 z-10 font-medium text-xl peer-focus:text-orange-600 peer-focus-within:text-orange-600 duration-300"
										>
											<Icon icon="solar:letter-opened-bold-duotone" />
										</label>
									</div>
								</div>

								<div className="justify-items-start">
									<div className="relative w-full h-11 flex items-center">
										<input
											type="text"
											id="phone"
											autoComplete="tel"
											className="w-full h-full text-sm pl-8 font-semibold tracking-tighter outline-none border-0 border-b focus-within:border-orange-600 focus:border-orange-600 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear peer"
											placeholder="Mobile number"
										/>

										<label
											htmlFor="phone"
											className="absolute left-0 z-10 font-medium text-xl peer-focus:text-orange-600 peer-focus-within:text-orange-600 duration-300"
										>
											<Icon icon="solar:phone-bold-duotone" />
										</label>
									</div>
								</div>

								<div className="justify-items-start">
									<div className="relative w-full h-11 flex items-center">
										<input
											type="text"
											id="subject"
											autoComplete="subject"
											className="w-full h-full text-sm pl-8 font-semibold tracking-tighter outline-none border-0 border-b focus-within:border-orange-600 focus:border-orange-600 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear peer"
											placeholder="Your subject"
										/>

										<label
											htmlFor="subject"
											className="absolute left-0 z-10 font-medium text-xl peer-focus:text-orange-600 peer-focus-within:text-orange-600 duration-300"
										>
											<Icon icon="solar:microphone-large-bold-duotone" />
										</label>
									</div>
								</div>

								<div className="justify-items-start">
									<label htmlFor="message" className="font-medium text-xsm">
										<span>Message</span>
									</label>

									<div className="w-full h-auto">
										<textarea
											id="message"
											className="w-full text-sm py-2 font-semibold tracking-tighter outline-none border-0 border-b focus-within:border-orange-600 focus:border-orange-600 bg-white placeholder:text-gray-500 placeholder:font-light duration-300 ease-linear"
											rows={6}
											placeholder="Your message"
										></textarea>
									</div>
								</div>
							</div>

							<button
								type="submit"
								className="rounded-sm w-full mxs:w-auto outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-orange-400 to-orange-600 hover:bg-gradient-to-tr text-xs text-white group/submit duration-300"
							>
								<span>Send Message</span>
								<Icon icon="solar:arrow-right-outline" fontSize={16} />
							</button>
						</form>
					</div>
				</div>
			</div>
		</Fragment>
	) : null;
}
