import db from "../firebase-config";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { readXMLFiles } from "../admin/pages/properties/actions";

async function fetchProperties(n = 0, { sale = false, rental = false, land = false, sold = false } = {}) {
	return new Promise(async (resolve, reject) => {
		let ref = collection(db, "properties");

		if (sold) {
			ref = query(ref, where("status", "==", "SOLD"));
		} else {
			ref = query(ref, where("status", "in", ["ACTIVE", "UNDER_OFFER", "OFF_MARKET"]));
		}

		if (sale) {
			ref = query(ref, where("sale", "==", sale));
		} else if (rental) {
			ref = query(ref, where("rental", "==", rental));
		} else if (land) {
			ref = query(ref, where("land", "==", land));
		}
		ref = query(ref, limit(n));
		await getDocs(ref)
			.then(async (res) => {
				let results = [];
				if (!res.empty) {
					let sorted = res.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
					results = sorted.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
				}

				await readXMLFiles()
					.then((xmlResults) => {
						let filteredResults = [];
						if (sale) {
							filteredResults = xmlResults.filter((p) => p.sale === true);
						} else if (rental) {
							filteredResults = xmlResults.filter((p) => p.rental === true);
						} else if (land) {
							filteredResults = xmlResults.filter((p) => p.land === true);
						} else {
							filteredResults = xmlResults;
						}
						resolve([...results, ...filteredResults]);
					})
					.catch((error) => {
						console.error(error);
						resolve([...results]);
					});
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export { fetchProperties };
